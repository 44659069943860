export const globalData = {
  test: '1',
  lastTextElement: null,
  eventChart: null,
  loadedChartsData: {},
  loadedEventsData: {},
  eventTop: {},
  allEventsList: {},
  dateOriginTime: 0,
  artifactsList: {},
  artifacts: [],
  artifactPressed: null,
  visibleEvents: [],
  additionalLines: {},
  zoomInterval: { start: 0, end: 300000 },
  initialRanges: {},
  eventTopCustom: null,
  chartRanges: {},
  sectorPolygons: {},
  signalIcon: {},
  drawEventFunction: {},
  snapshotsList: [],
  historyPosition: -1,
  chartOffsetTop: 170,
  rowSizeSum: 3,
  gridLines: {},
  maxValuesLines: {},
  minValuesLines: {},
  pulseTags: undefined,
  pulseTagPolygons: [],
  eventsTyped: {},
  token: undefined,
  allEventTypesList: [],
  chartColors: {},
  eventColors: {
    OximetryCycling: '#624c4e60',
    CandidateEvent: '#9e77de8c',
  },
  eventsUpdated: false,
  eventsSaved: false,
};
