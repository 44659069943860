import React, { Component } from 'react'
import $ from 'jquery'
import Edit from './edit'
import Alert from './alert'
import MaskedInput from 'react-maskedinput'
import PWStrength from './password-strength'

class User extends Component {

	constructor(props){
		super(props)
		this.id = parseInt(props.user.id)
		this.url = '/users/' + this.id
		this.state = {
			locked: true,
			data: this.props.user,
			alertType: 'alert-success',
			password: ''
		}
	}

	componentDidMount(){
		this.read()
	}

	submit(event){
		if (event) event.preventDefault()
		var data = this.state.data
		var match = data.newPassword === data.confirmPassword
		if (data.newPassword && match){
			data.password = data.newPassword
			this.setState({
				data: data
			})
		}
		if (!match){
			this.initAlertMessage('Passwords do not match.', 'alert-danger')
			return
		}
		data.id ? this.update() : this.create()
	}

	create(){
		var success = this.dataHandler.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'POST',
			url: '/users',
			data: this.state.data
		}).done(success).fail(failure)
	}

	read(){
		var success = this.dataHandler.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'GET',
			url: this.url
		}).done(success).fail(failure)
	}

	update(){
		var editedData = this.state.data
		if (this.state.data.username === ""){
			editedData.username = this.state.data.email
		}
		if (this.state.data.systemAdmin === null || typeof this.state.data.systemAdmin === 'string'){	
			editedData.systemAdmin = 0
		}
		var success = this.updateSuccess.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'PUT',
			url: this.url,
			data: editedData
		}).done(success).fail(failure)
	}

	destroy(){
		var success = this.dataHandler.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'DELETE',
			url: this.url,
		}).done(success).fail(failure)
	}

	changeHandler(event){
		var data = this.state.data
 		data[event.target.name] = event.target.value
 		
 		if (event.target.name === 'newPassword'){
 			this.setState({
 				password: event.target.value
 			})
		 }
 		this.setState({
 			data: data
 		})
	}

	updateSuccess(data){
		this.setState({ 
			data: data,
			locked: true,
			alertMessage: "Changes have been saved.",
			alertType: 'alert-success'
		})	
		
	}

	dataHandler(data){
		this.setState({ 
			data: data,
			locked: true
		})	
	}

	errorHandler(res){
		var body = res.responseJSON
		var error = new Error(body.error.message)
		this.setState({
			alertMessage: error.message,
			alertType: 'alert-danger'
		})
	}

	initAlertMessage(message, type){
		this.setState({
			alertMessage: message,
			alertType: type
		})
	}

	removeAlertHandler(){
		this.setState({
			alertMessage: null
		})
	}

	unlock(bool){
		this.setState({ locked: false })
	}

 	render(){
 		var actions = {
 			unlock: this.unlock.bind(this),
 			read: this.read.bind(this),
 			submit: this.submit.bind(this),
 			destroy: this.destroy.bind(this)
 		}
 		var removeHandler = this.removeAlertHandler.bind(this)
 		var data = this.state.data
 		var locked = this.state.locked
 		var handleChange = this.changeHandler.bind(this)
 		var password = this.state.password
 		return (
 			<div className='card'>
 				<form onSubmit={ actions.submit }>
	 				<div className='card-header'>
	 					<div className='row align-items-center justify-content-between'>
							<div className='col'>
								<span className='h4'>Profile</span>
							</div>
							<div className='col text-right'>
								<Edit actions={actions} data={data} locked={locked}/>
							</div>
						</div>
	 				</div>
	 				<div className='card-body'>
	 					<div className='form-row'>
							<div className='form-group col'>
								<label>Email</label>
								<input className='form-control'
									type='email'
									name='email'
									value={ data.email || '' }
									onChange={ handleChange }
									disabled={ locked }
								/>
							</div>
							<div className='form-group col'>
								<label>Username</label>
								<input className='form-control'
									type='text'
									name='username'
									value={ data.username || '' }
									onChange={ handleChange }
									disabled={ locked } />
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col'>
								<label>First Name</label>
								<input className='form-control'
									type='text'
									name='firstName'
									value={ data.firstName || '' }
									onChange={ handleChange }
									disabled={ locked } />
							</div>
							<div className='form-group col-3'>
								<label>Middle Name</label>
								<input className='form-control'
									type='text'
									name='middleName'
									value={ data.middleName || '' }
									onChange={ handleChange }
									disabled={ locked } />
							</div>
							<div className='form-group col'>
								<label>Last Name</label>
								<input className='form-control'
									type='text'
									name='lastName'
									value={ data.lastName || '' }
									onChange={ handleChange }
									disabled={ locked } />
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col'>
								<label>Phone</label>
								<MaskedInput className='form-control'
									type='text'
									name='phone'
									value={ data.phone || '' }
									onChange={ handleChange }
									disabled={ locked }
									mask='(111) 111-1111' />
							</div>
							<div className='form-group col'>
								<label>Fax</label>
								<MaskedInput className='form-control'
									type='text'
									name='fax'
									value={ data.fax || '' }
									onChange={ handleChange }
									disabled={ locked }
									mask='(111) 111-1111' />
							</div>
							<div className='form-group col'>
								<label>NPI</label>
								<input className='form-control'
									type='text'
									name='npi'
									value={ data.npi || '' }
									onChange={ handleChange }
									disabled={ locked } />
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col' hidden={locked}>
								<label>New Password</label>
								<input className='form-control'
									type='password'
									name='newPassword'
									value={ data.newPassword || '' }
									onChange={ handleChange }
									disabled={ locked } />
							</div>
							<div className='form-group col' hidden={locked}>
								<label>Confirm Password</label>
								<input className='form-control'
									type='password'
									name='confirmPassword'
									value={ data.confirmPassword || '' }
									onChange={ handleChange }
									disabled={ locked } />
							</div>
						</div>
						<div className='form-row'>
							<PWStrength password={password}/>
						</div>
					</div>
	 			</form>
	 			<Alert message={this.state.alertMessage} type={this.state.alertType } onExit={removeHandler}/>
 			</div>

		)
 	}

}

export default User