import React, { Component } from 'react'
import moment from 'moment'
import $ from 'jquery'
import Edit from './edit'
import MaskedInput from 'react-maskedinput'
import Alert from './alert'


class PatientDetails extends Component {

	constructor(props){
		super(props)
		var id = this.props.data.id
		this.path = '/patients'
		this.url = this.path + '/' + id	
		this.state = {
			alertMessage: null,
			alertType: 'alert-success',
			locked: id !== 0,
			data: {
				id: id
			}
		}
	}

	componentDidMount(){
		this.read()
	}

	componentDidUpdate(props,state){
		if (props.data.id !== this.props.data.id){
			this.url = this.path + '/' + this.props.data.id
			this.read()
		}
	}

	submit(event){
		if (event) event.preventDefault()
		this.state.data.id ? this.update() : this.create()
	}

	create(){
		var success = this.dataHandler.bind(this)
		var failure = this.errorHandler.bind(this)
		var data = {
			firstName: this.state.data.firstName,
			middleName: this.state.data.middleName,
			lastName: this.state.data.lastName,
			birthDate: this.state.data.birthDate,
			height: this.state.data.height,
			neck: this.state.data.neck,
			gender: this.state.data.gender,
			maritalStatus: this.state.data.maritalStatus,
			email: this.state.data.email,
			phone: this.state.data.phone,
			organizationId: this.props.organization.id
		}
		$.ajax({
			method: 'POST',
			url: this.path,
			data: data
		}).done(success).fail(failure)
	}

	read(){
		if (!this.props.data.id) return
		var success = this.dataHandler.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'GET',
			url: this.url
		}).done(success).fail(failure)
	}

	update(){
		if (!this.state.data.firstName){
			return this.setState({
				alertMessage: 'First Name is required.',
				alertType: 'alert-danger'
			})
		}
		if (!this.state.data.lastName){
			return this.setState({
				alertMessage: 'Last Name is required.',
				alertType: 'alert-danger'
			})
		}
		var success = this.saveSuccess.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'PUT',
			url: this.url,
			data: this.state.data
		}).done(success).fail(failure)
	}

	destroy(){
		return
		// var success = this.dataHandler.bind(this)
		// var failure = this.errorHandler.bind(this)
		// $.ajax({
		// 	method: 'DELETE',
		// 	url: this.url,
		// }).done(success).fail(failure)
	}

	changeHandler(event){
		var data = this.state.data
 		data[event.target.name] = event.target.value
 		data = this.formatData(data)
 		this.setState({
 			data: data
 		})
	}

	saveSuccess(data){
		this.setState({
			alertMessage: 'Saved Successfully.',
			alertType: 'alert-success'
		})
		this.dataHandler(data)
	}

	dataHandler(data){
		this.setState({ 
			data: data,
			locked: true,
		})
		if (!data.id || data.deleted){
			this.props.location.history.push(this.path)
		}
	}

	errorHandler(res){
		var body = res.responseJSON
		var error = new Error(body.error.message)
		this.setState({
			alertMessage: error.message,
			alertType: 'alert-danger'
		})
	}

	unlock(bool){
		this.setState({ locked: false })
	}

	formatData(data){
 		if (data.heightFeet || data.heightInches){
 			var feet = parseInt(data.heightFeet)
 			var inches = parseInt(data.heightInches)
 			data.height = (feet * 12) + inches
 		}
 		if (data.height){
 			var totalInches = parseInt(data.height)
 			data.heightFeet = Math.floor(totalInches / 12)
 			data.heightInches = totalInches % 12
 		}
 		if (data.birthDate) {
 			data.birthDate = moment(data.birthDate).utc().format('YYYY-MM-DD')
 		}
 		return data
	}

	removeAlert(){
		this.setState({
			alertMessage: null
		})
	}

	renderCardBody(){
		var data = this.state.data
		var locked = this.state.locked
		var handleChange = this.changeHandler.bind(this)
		data = this.formatData(data)
		var height = data.heightFeet !== undefined ? `${data.heightFeet} ft. ${data.heightInches} in.` : null
		var weight = data.weight !== 0 ? `${data.weight} lbs.` : null
		var neck = data.neck !== 0 ? `${data.neck} in` : null

		if (this.state.locked){
				return (
					<div className='card-body' style={{padding: 5}}>
						<div className='row'>
							<div className='col'>
								<span className='font-weight-bold'>Birth Date: </span>
								<span>{moment(data.birthDate).utc().format('MMMM DD, YYYY')}</span>
							</div>
							<div className='col'>
								<span className='font-weight-bold'>Gender: </span>
								<span>{data.gender}</span>
							</div>
							<div className='col'>
								<span className='font-weight-bold'>Phone: </span>
								<span>{data.phone}</span>
							</div>
						</div>
						<div className='row'>
							<div className='col'>
								<span className='font-weight-bold'>Height: </span>
								<span>{height}</span>
							</div>
							<div className='col'>
								<span className='font-weight-bold'>Weight: </span>
								<span>{weight}</span>
							</div>
							<div className='col'>
								<span className='font-weight-bold'>Neck: </span>
								<span>{neck}</span>
							</div>
							
						</div>
					</div>
				)
		} else {
			return (
				<div className='card-body'>
					<div className='form-row'>
						<div className='form-group col'>
							<label>First Name</label>
							<input className='form-control'
								type='text'
								name='firstName'
								value={ data.firstName || '' }
								onChange={ handleChange }
								disabled={ locked } />
						</div>
						<div className='form-group col'>
							<label>Middle Name</label>
							<input className='form-control'
								type='text'
								name='middleName'
								value={ data.middleName || '' }
								onChange={ handleChange }
								disabled={ locked } />
						</div>
						<div className='form-group col'>
							<label>Last Name</label>
							<input className='form-control'
								type='text'
								name='lastName'
								value={ data.lastName || '' }
								onChange={ handleChange }
								disabled={ locked } />
						</div>
					</div>
					<div className='form-row'>
						<div className='form-group col'>
							<label>Height</label>
							<div className='input-group'>
								<input className='form-control'
									type='number'
									min='0'	max='9'
									name='heightFeet'
									value={ data.heightFeet || '' }
									onChange={ handleChange }
									disabled={ locked } />
								<div className='input-group-append'>
									<div className='input-group-text'>ft.</div>
								</div>
								<input className='form-control'
									type='number'
									name='heightInches'
									max='11'
									value={ data.heightInches || '' }
									onChange={ handleChange }
									disabled={ locked } />
								<div className='input-group-append'>
									<div className='input-group-text'>in.</div>
								</div>
							</div>
						</div>
						<div className='form-group col'>
							<label>Weight</label>
							<div className='input-group'>
								<input className='form-control'
									type='number'
									name='weight'
									value={ data.weight || '' }
									onChange={ handleChange }
									disabled={ locked } />
								<div className='input-group-append'>
									<div className='input-group-text'>lbs.</div>
								</div>
							</div>
						</div>
						<div className='form-group col'>
							<label>Neck</label>
							<div className='input-group'>
								<input className='form-control'
									type='number'
									name='neck'
									value={ data.neck || '' }
									onChange={ handleChange }
									disabled={ locked } />
								<div className='input-group-append'>
									<div className='input-group-text'>in.</div>
								</div>
							</div>
						</div>
						<div className='form-group col'>
							<label>Gender</label>
							<select className='form-control'
								name='gender'
								value={ data.gender || '' }
								onChange={ handleChange }
								disabled={ locked }>
								<option>Male</option>
								<option>Female</option>
							</select>
						</div>
						<div className='form-group col'>
							<label>Marital Status</label>
							<select className='form-control'
								name='maritalStatus'
								value={ data.maritalStatus || '' }
								onChange={ handleChange }
								disabled={ locked }>
								<option>Married</option>
								<option>Single</option>
								<option>Divorced</option>
								<option>Widowed</option>
							</select>
						</div>
					</div>
					<div className='form-row'>
						<div className='form-group col'>
							<label>Birth Date</label>
							<input className='form-control'
								type='date'
								name='birthDate'
								value={ data.birthDate || '' }
								onChange={ handleChange }
								disabled={ locked }
								min='1000-01-01'
								max='3000-01-01'/>
						</div>
						<div className='form-group col'>
							<label>Email</label>
							<input className='form-control'
								type='email'
								name='email'
								value={ data.email || '' }
								onChange={ handleChange }
								disabled={ locked } />
						</div>
						<div className='form-group col'>
							<label>Phone</label>
							<MaskedInput className='form-control'
								type='text'
								name='phone'
								value={ data.phone || '' }
								onChange={ handleChange }
								mask='(111) 111-1111'
								disabled={ locked } />
						</div>
					</div>
				</div>
			)
		}
	}

 	render(){
 		var actions = {
 			unlock: this.unlock.bind(this),
 			read: this.read.bind(this),
 			submit: this.submit.bind(this)
 		}
 		var data = this.state.data
 		var locked = this.state.locked
 		var removeAlert = this.removeAlert.bind(this)
 		data = this.formatData(data)
 		return (
			<div className='card'>
				<form onSubmit={ actions.submit }>
					<div className='card-header'>
						<div className='row align-items-center'>
							<div className='col'>
		 					<span className='h5'>{data.firstName} {data.middleName} {data.lastName}</span>
							</div>
							<div className='col text-right'>
								<Edit actions={actions} data={data} locked={locked}/>
							</div>
						</div>
					</div>
					{this.renderCardBody()}
				</form>
				
				<Alert message={this.state.alertMessage} type={this.state.alertType} onExit={removeAlert}/>
				
			</div>
		)
 	}

}

export default PatientDetails
