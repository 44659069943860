import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import './chart.css';
import { Input } from 'antd';
import snore from './img/snore.png';
import pulse from './img/pulse.png';
import chest from './img/chest.png';
import airflow from './img/airflow.png';
import abdomen from './img/abdomen.png';
import pleth from './img/pleth.png';
import temperature from './img/temperature.png';
import { globalData } from './globals';
import { fal } from '@fortawesome/pro-light-svg-icons';
const lcjs = require('@arction/lcjs');
const {
  ColorPalettes,
  ColorRGBA,
  SolidFill,
  SolidLine,
  UIElementBuilders,
  translatePoint,
  ColorHEX,
  AxisScrollStrategies,
  lightningChart,
  AxisTickStrategies,
  FontSettings,
  Themes,
  emptyLine,
  emptyFill,
} = lcjs;

export const ExcludedEvents = [
  'CentralApnea',
  'Hypopnea',
  'MixedApnea',
  'ObstructiveApnea',
];

export const palette = ColorPalettes.arction(10);
export const colors = [6, 9, 0].map(palette);
export const axisYColors = [colors[0], colors[1]];
export const axisYStyles = axisYColors.map((color) => new SolidFill({ color }));
export const axisYStrokeStyles = axisYStyles.map(
  (fillStyle) => new SolidLine({ fillStyle, thickness: 2 }),
);
export const axisYStylesHighlight = axisYStyles.map((fillStyle) =>
  fillStyle.setA(100),
);
export const axisXStyleHighlight = new SolidFill({
  color: colors[2].setA(100),
});
export const fittingRectangleStrokeStyle = new SolidLine({
  fillStyle: new SolidFill({ color: ColorRGBA(255, 255, 255, 100) }),
  thickness: 2,
});
export const zoomingRectangleFillStyle = new SolidFill({
  color: colors[2].setA(100),
});
export const fillStyle = new SolidFill({ color: ColorRGBA(0, 0, 0, 150) });
export const strokeStyle = new SolidLine({
  fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0, 241) }),
  thickness: 1,
});
export const strokeStyle2 = new SolidLine({
  fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0, 0) }),
  thickness: 1,
});
export const speedMultipliers = [1.25, 1.5, 2, 4, 8, 16];

export const eventY = {
  Reciprocation: 0.5,
  Cycling: 0.2,
  Bradycardia: 0.8,
  CentralApnea: 0.45,
  Hypopnea: 0.25,
  MixedApnea: 0.45,
  ObstructiveApnea: 0.85,
  RERA: 0.4,
  Tachycardia: 0.2,
  Effort: 0.8,
  CheyneStokes: 0.85,
  Desaturation: 0.7,
  Recovery: 0.7,
  CandidateEvent: 0.9,
  MotionArtifact: 0,
  ExcludedArtifact: 0,
};

export const getRandomDarkColor = () => {
  const c1 = (Math.floor(Math.random() * 100) + 16).toString(16);
  const c2 = (Math.floor(Math.random() * 100) + 16).toString(16);
  const c3 = (Math.floor(Math.random() * 100) + 16).toString(16);
  return '#' + c1 + c2 + c3 + '60';
};
//.replace("Oximetry", "")

const signalColors1 = {};

export const eventSectors = {
  Airflow: [
    {
      type: 'ObstructiveApnea',
      min: 0.8,
      max: 1,
      color: '#ff4040',
    },
    {
      type: 'MixedApnea',
      min: 0.6,
      max: 0.8,
      color: '#f9f6d3',
    },
    {
      type: 'CentralApnea',
      min: 0.4,
      max: 0.6,
      color: '#2492ff',
    },
    {
      type: 'Hypopnea',
      min: 0.2,
      max: 0.4,
      color: '#f7ddc7',
    },
    {
      type: 'CheyneStokes',
      min: 0,
      max: 0.2,
      color: '#f4d3f9',
    },
  ],
  Pulse: [
    {
      type: 'Tachycardia',
      min: 0.5,
      max: 1,
      color: '#ff0000',
    },
    {
      type: 'Bradycardia',
      min: 0,
      max: 0.5,
      color: '#ffff99',
    },
  ],
};

export const NoYLabelSignals = ['Airflow', 'Chest', 'Abdomen', 'Snore'];

export const artifactColors = {
  ExcludedArtifact: ColorRGBA(0, 0, 0, 80),
  MotionArtifact: ColorRGBA(0, 0, 150, 80),
};

export const drawPolygonEvent = (
  chart,
  y,
  signalId,
  sector,
  min,
  max,
  height,
) => {
  const signalRange = globalData.chartRanges[signalId];
  const h = signalRange.max - signalRange.min;
  const range = {
    min: signalRange.min + h * sector.min,
    max: signalRange.min + h * sector.max,
  };
  const low = y - height < range.min ? range.min : y - height;
  const top = y + height > range.max ? range.max : y + height;
  return chart
    .addPolygonSeries()
    .setCursorEnabled(false)
    .setHighlightOnHover(false)
    .setHighlightMode(lcjs.HighlightModes.noHighlighting)
    .add([
      { x: min + 300, y: low },
      { x: max, y: low },
      { x: max, y: top },
      { x: min + 300, y: top },
      { x: min, y: y },
    ]);
};

export const defaultEventSelectValues = {
  829: ['Tachycardia', 'Bradycardia'],
};
let timer;
export const figureHeight = 9;

export const debounce = (func, timeout = 300) => {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const host =
  'https://analysis.elevare.tech/analysis-engine-research-influxdb-mysql';
//export const host = "http://localhost:8010/proxy/analysis-engine-research-influxdb-mysql";

export const timeIntervalSizes = [
  { label: '30 seconds', value: 30 },
  { label: '2 minutes', value: 120 },
  { label: '5 minutes', value: 300 },
  { label: '10 minutes', value: 600 },
  { label: '20 minutes', value: 1200 },
  { label: '30 minutes', value: 1800 },
  { label: '50 minutes', value: 3000 },
];

export const checkOverlap = (
  type,
  eventId,
  signalId,
  start,
  end,
  allEventsList,
) => {
  let samePositionSignals = [];
  const eventTop = globalData.eventTopCustom[signalId] || globalData.eventTop;
  const h = eventTop[type];
  if (!h) return false;
  for (var eventType in eventTop) {
    if (eventTop[eventType] === h) samePositionSignals.push(eventType);
  }

  for (var t of samePositionSignals) {
    if (!allEventsList[t]) continue;
    const crossedEvent = allEventsList[t]
      .filter((e) => e.signalId === signalId && e.id !== eventId)
      .find((e) => {
        if (
          Math.abs(e.start + e.end - (start + end)) <
          e.end - e.start + (end - start)
        ) {
          return true;
        }
        return false;
      });
    if (crossedEvent) return `${type} events cannot overlap ${t} events`;
  }
  return false;
};

export const setCanvasResize = (resize) => {
  document
    .querySelector('#chartContainer canvas')
    .classList.toggle('canvasResize', resize);
};

export const useEventsHistory = (
  setEventsFromHistory,
  setChartsOrder,
  setChartsSettings,
  setVisibleEvents,
  setArtifacts,
) => {
  const [currentPosition, setCurrentPosition] = useState(-1);

  const addHistoryEvent = useCallback(
    (newSnapshot) => {
      if (globalData.historyPosition < globalData.snapshotsList.length - 1) {
        globalData.snapshotsList = globalData.snapshotsList
          .slice(0, globalData.historyPosition + 1)
          .concat([newSnapshot]);
        setCurrentPosition((currentPosition) => currentPosition + 1);
      } else {
        globalData.snapshotsList = globalData.snapshotsList.concat([
          newSnapshot,
        ]);
        setCurrentPosition((currentPosition) => currentPosition + 1);
      }
    },
    [setCurrentPosition],
  );

  useEffect(() => {
    globalData.historyPosition = currentPosition;
    const scoringActive = globalData.snapshotsList
      ?.slice(0, currentPosition + 1)
      .some((s) => s.type.startsWith('event') || s.type === 'artifact');
    let button = document.getElementById('save_scoring_button');
    if (!button) return;
    button.classList.toggle('disabled', !scoringActive);
    button = document.getElementById('save_scoring_report_button');
    button.classList.toggle('disabled', !scoringActive);
  }, [currentPosition]);

  const setSnapshot = useCallback(
    (snapshot, redo = false) => {
      const data = redo ? snapshot.next : snapshot.prev;
      switch (snapshot.type) {
        case 'event':
          setEventsFromHistory(data, snapshot.signalId);
          break;
        case 'chartOrder':
          setChartsOrder(data);
          break;
        case 'chartSettings':
          setChartsSettings(data);
          break;
        case 'eventTypeVisibility':
          setVisibleEvents(data);
          break;
        case 'artifact':
          setArtifacts(data);
          break;
        default:
          break;
      }
    },
    [
      setChartsOrder,
      setChartsSettings,
      setEventsFromHistory,
      setVisibleEvents,
      setArtifacts,
    ],
  );

  const undoEvent = useCallback(() => {
    if (globalData.historyPosition >= 0) {
      setSnapshot(globalData.snapshotsList[globalData.historyPosition]);
      setCurrentPosition((currentPosition) => currentPosition - 1);
    }
  }, [setSnapshot]);

  const redoEvent = useCallback(() => {
    if (globalData.historyPosition < globalData.snapshotsList.length - 1) {
      setSnapshot(
        globalData.snapshotsList[globalData.historyPosition + 1],
        true,
      );
      setCurrentPosition((currentPosition) => currentPosition + 1);
    }
  }, [setSnapshot]);

  const titles = useMemo(() => {
    return {
      undo:
        'Undo ' + globalData.snapshotsList[currentPosition]?.title || 'Undo',
      redo:
        'Redo ' + globalData.snapshotsList[currentPosition + 1]?.title ||
        'Redo',
    };
  }, [currentPosition]);

  const removeHistory = useCallback(() => {
    globalData.snapshotsList = [];
    setCurrentPosition(-1);
  }, []);

  const undoActive = currentPosition >= 0;
  const redoActive = currentPosition < globalData.snapshotsList.length - 1;
  globalData.addHistoryEvent = (s) => {
    addHistoryEvent(s);
  };

  return [
    undoEvent,
    redoEvent,
    undoActive,
    redoActive,
    titles,
    removeHistory,
    addHistoryEvent,
  ];
};
export const checkArtifactOverlaping = (start, end, signalId, id = null) => {
  const artifacts =
    id !== null
      ? globalData.artifacts.filter(
          (a) => a.signalId === signalId && a.id !== id,
        )
      : globalData.artifacts.filter((a) => a.signalId === signalId);
  return artifacts.find((e) => {
    if (
      !e.removed &&
      e.Type === 'ExcludedArtifact' &&
      Math.abs(e.start + e.end - (start + end)) <
        e.end - e.start + (end - start)
    ) {
      return true;
    }
    return false;
  });
};

export const checkEventArtifactOverlaping = (artifact) => {
  const { start, end } = artifact;
  return globalData.loadedEventsData[artifact.signalId].find((e) => {
    if (
      !e.removed &&
      e.Type !== 'CandidateEvent' &&
      Math.abs(e.startTimeMicro + e.endTimeMicro - (start + end)) <
        e.endTimeMicro - e.startTimeMicro + (end - start)
    ) {
      return true;
    }
    return false;
  });
};

export const hideEventHoverChart = () => {
  const element = document.getElementById('eventHoverChartContainer');
  element.style.display = 'none';
};

export const useHoveredElement = () => {
  const [dateOriginTime, setDateOriginTime] = useState(100000);
  const [hoverElement, setHoveredElement] = useState(null);
  const [hoverWindow, setHoveredWindow] = useState(null);

  const onHoverWindow = useCallback((data, hoveredElement) => {
    let code = null;
    if (data.length === 1) {
      code = `<span id="eventAddingHover">${data[0].label}</span>`;
    } else {
      code = data
        .map((row) => {
          const value = row.value;
          const label = row.label;
          return `<div>
              <span class="eventHoverTextLabel">${label}</span>
              <span class="eventHoverTextValue">${value}</span>
            </div>`;
        })
        .reduce((prev, curr) => {
          return prev + curr;
        }, '');
    }
    document.getElementById('eventHoverText').innerHTML = code;
    const element = document.getElementById('eventHoverChartContainer');
    element.classList.toggle(
      'artifactHover',
      hoveredElement.isArtifact === true,
    );
    element.style.display = hoveredElement ? 'block' : 'none';
    element.style.left = hoveredElement?.position.x + 10 + 'px';
    element.style.top = hoveredElement?.position.y + 10 + 'px';
    element.classList.toggle(
      'eventHoverDrawing',
      Boolean(hoveredElement?.isEventDrawing),
    );
    const rect = element.getBoundingClientRect();
    const dist = rect.left + rect.width + 10 - window.innerWidth;
    if (dist > 0)
      element.style.left = hoveredElement?.position.x - dist + 10 + 'px';
  }, []);

  const setHoverElement = useCallback(
    (hoveredElement) => {
      if (!hoveredElement) {
        hideEventHoverChart();
        // setHoveredWindow(null);
        return null;
      }
      setHoveredElement(hoveredElement);
      let data = [];
      let status = '';
      if (hoveredElement.isEventDrawing) {
        if (hoveredElement.customText) {
          data = [
            {
              label: hoveredElement.customText,
            },
          ];
        } else {
          data = [
            {
              label:
                moment(
                  new Date(
                    (hoveredElement.onRight
                      ? hoveredElement.size.end
                      : hoveredElement.size.start) + dateOriginTime,
                  ),
                )
                  .utcOffset(0, true)
                  .format('HH:mm:ss') +
                '<' +
                moment
                  .utc(hoveredElement.size.end - hoveredElement.size.start)
                  .format('HH:mm:ss') +
                '>',
            },
          ];
        }
      } else {
        status = hoveredElement.status || 'Mutual added';
        data = [
          hoveredElement.isArtifact
            ? {
                label: 'Artifact Type',
                value: hoveredElement.type || 'Excluded Artifact',
              }
            : { label: 'Event Type', value: hoveredElement.text },
          {
            label: 'Duration',
            value: moment
              .utc(hoveredElement.size.end - hoveredElement.size.start)
              .format('HH:mm:ss'),
          },
          {
            label: 'Start Time',
            value: moment(new Date(hoveredElement.size.start + dateOriginTime))
              .utcOffset(0, true)
              .format('HH:mm:ss'),
          },
          {
            label: 'End Time',
            value: moment(new Date(hoveredElement.size.end + dateOriginTime))
              .utcOffset(0, true)
              .format('HH:mm:ss'),
          },
        ];
        if (hoveredElement.customCharacteristics) {
          data = data.concat(
            Object.keys(hoveredElement.customCharacteristics)
              .filter(
                (l) =>
                  !l.startsWith('Related') &&
                  !l.startsWith('DPAR') &&
                  hoveredElement.customCharacteristics[l],
              )
              .map((label) => ({
                label,
                value: hoveredElement.customCharacteristics[label],
              })),
          );
        }
        data.push({ label: 'Scoring Status', value: status });
      }
      setHoveredWindow(onHoverWindow(data, hoveredElement));
    },
    [dateOriginTime, setHoveredWindow, onHoverWindow],
  );
  globalData.setHoverElement = setHoverElement;

  return [setDateOriginTime, setHoverElement, hoverWindow, hoverElement];
};

export const onHoverWindow = (hoverElement, dateOriginTime) => {
  if (hoverElement.isEventDrawing) {
    return (
      <span>
        {moment(
          new Date(
            (hoverElement.onRight
              ? hoverElement.size.end
              : hoverElement.size.start) + dateOriginTime,
          ),
        )
          .utcOffset(0, true)
          .format('HH:mm:ss')}
        &#60;
        {moment
          .utc(hoverElement.size.end - hoverElement.size.start)
          .format('HH:mm:ss')}
        &#62;
      </span>
    );
  } else {
    return (
      <>
        {hoverElement.isArtifact ? (
          <>
            <span className="eventHoverTextLabel">Artifact Type</span>{' '}
            <span className="eventHoverTextValue">Excluded Artifact</span>
          </>
        ) : (
          <>
            <span className="eventHoverTextLabel">Event Type</span>{' '}
            <span className="eventHoverTextValue">{hoverElement.text}</span>
          </>
        )}
        <span className="eventHoverTextLabel">Duration</span>{' '}
        <span className="eventHoverTextValue">
          {moment
            .utc(hoverElement.size.end - hoverElement.size.start)
            .format('HH:mm:ss')}
        </span>
        <span className="eventHoverTextLabel">Start Time</span>{' '}
        <span className="eventHoverTextValue">
          {moment(new Date(hoverElement.size.start + dateOriginTime))
            .utcOffset(0, true)
            .format('HH:mm:ss')}
        </span>
        <span className="eventHoverTextLabel">End Time</span>
        <span className="eventHoverTextValue">
          {moment(new Date(hoverElement.size.end + dateOriginTime))
            .utcOffset(0, true)
            .format('HH:mm:ss')}
        </span>
        <span className="eventHoverTextLabel">Scoring Status</span>
        <span className="eventHoverTextValue">
          {hoverElement.status || 'Mutual added'}
        </span>
      </>
    );
  }
};

export const customEventFields = (eventType, values, setValue) => {
  if (eventType === 'Desaturation') {
    return ['Top Value', 'Nadir'].map((field) => (
      <div>
        <span className="eventDatePickerLabel">{field}</span>
        <span>
          <Input
            style={{ width: '100px' }}
            type="number"
            key={'customEventValue' + field}
            defaultValue={values ? values[field] : ''}
            onChange={(e) => {
              setValue(field, e.target.value);
            }}
          />
        </span>
      </div>
    ));
  }
  return null;
};

const signalIcon = {
  SPO2: (
    <svg
      className="chartIcon bi bi-lungs"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M8.5 1.5a.5.5 0 1 0-1 0v5.243L7 7.1V4.72C7 3.77 6.23 3 5.28 3c-.524 0-1.023.27-1.443.592-.431.332-.847.773-1.216 1.229-.736.908-1.347 1.946-1.58 2.48-.176.405-.393 1.16-.556 2.011-.165.857-.283 1.857-.241 2.759.04.867.233 1.79.838 2.33.67.6 1.622.556 2.741-.004l1.795-.897A2.5 2.5 0 0 0 7 11.264V10.5a.5.5 0 0 0-1 0v.764a1.5 1.5 0 0 1-.83 1.342l-1.794.897c-.978.489-1.415.343-1.628.152-.28-.25-.467-.801-.505-1.63-.037-.795.068-1.71.224-2.525.157-.82.357-1.491.491-1.8.19-.438.75-1.4 1.44-2.25.342-.422.703-.799 1.049-1.065.358-.276.639-.385.833-.385a.72.72 0 0 1 .72.72v3.094l-1.79 1.28a.5.5 0 0 0 .58.813L8 7.614l3.21 2.293a.5.5 0 1 0 .58-.814L10 7.814V4.72a.72.72 0 0 1 .72-.72c.194 0 .475.11.833.385.346.266.706.643 1.05 1.066.688.85 1.248 1.811 1.439 2.249.134.309.334.98.491 1.8.156.814.26 1.73.224 2.525-.038.829-.224 1.38-.505 1.63-.213.19-.65.337-1.628-.152l-1.795-.897A1.5 1.5 0 0 1 10 11.264V10.5a.5.5 0 0 0-1 0v.764a2.5 2.5 0 0 0 1.382 2.236l1.795.897c1.12.56 2.07.603 2.741.004.605-.54.798-1.463.838-2.33.042-.902-.076-1.902-.24-2.759-.164-.852-.38-1.606-.558-2.012-.232-.533-.843-1.571-1.579-2.479-.37-.456-.785-.897-1.216-1.229C11.743 3.27 11.244 3 10.72 3 9.77 3 9 3.77 9 4.72V7.1l-.5-.357V1.5Z" />
    </svg>
  ),
  Pulse: <img alt="pulse" src={pulse} className="chartIcon" />,
  Airflow: <img alt="airflow" src={airflow} className="chartIcon" />,
  Chest: <img alt="chest" src={chest} className="chartIcon" />,
  Snore: <img alt="snore" src={snore} className="chartIcon" />,
  Pleth: <img alt="pleth" src={pleth} className="chartIcon" />,
  Temperature: <img alt="pleth" src={temperature} className="chartIcon" />,
  Abdomen: <img alt="abdomen" src={abdomen} className="chartIcon" />,
  Artifact: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="chartIcon bi bi-virus2"
      viewBox="0 0 16 16"
    >
      <path d="M8 0a1 1 0 0 0-1 1v1.143c0 .557-.407 1.025-.921 1.24-.514.214-1.12.162-1.513-.231l-.809-.809a1 1 0 1 0-1.414 1.414l.809.809c.394.394.445.999.23 1.513C3.169 6.593 2.7 7 2.144 7H1a1 1 0 0 0 0 2h1.143c.557 0 1.025.407 1.24.921.214.514.163 1.12-.231 1.513l-.809.809a1 1 0 0 0 1.414 1.414l.809-.809c.394-.394.999-.445 1.513-.23.514.214.921.682.921 1.24V15a1 1 0 1 0 2 0v-1.143c0-.557.407-1.025.921-1.24.514-.214 1.12-.162 1.513.231l.809.809a1 1 0 0 0 1.414-1.414l-.809-.809c-.393-.394-.445-.999-.23-1.513.214-.514.682-.921 1.24-.921H15a1 1 0 1 0 0-2h-1.143c-.557 0-1.025-.407-1.24-.921-.214-.514-.162-1.12.231-1.513l.809-.809a1 1 0 0 0-1.414-1.414l-.809.809c-.394.393-.999.445-1.513.23-.514-.214-.92-.682-.92-1.24V1a1 1 0 0 0-1-1Zm2 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm1 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm4-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    </svg>
  ),
};

globalData.signalIcon = signalIcon;

export const getBodyRotation = (position) => {
  const r = {
    0: 0,
    1: 135,
    2: 45,
    3: 90,
    6: 0,
    4: 315,
    8: 225,
    9: 180,
    12: 270,
  };
  return r[position] || 0;
};

export const getBodyRotationDirection = (position) => {
  const r = {
    1: 'R',
    2: 'N',
    3: 'R',
    4: 'L',
    6: 'S',
    8: 'L',
    9: 'P',
    12: 'L',
  };
  return r[position] || 'S';
};

export const bodyRotationColor = {
  R: '#2e8b57a0',
  S: '#ee82eea0',
  L: '#00ffffa0',
  N: '#969696a0',
};

function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const rgbaToHex = (color) => {
  let r = (color.r | (1 << 8)).toString(16).slice(1);
  let g = (color.g | (1 << 8)).toString(16).slice(1);
  let b = (color.b | (1 << 8)).toString(16).slice(1);
  let a = (Math.floor(color.a * 255) | (1 << 8)).toString(16).slice(1);
  return '#' + r + g + b + a;
};

export const saveEvents = (
  events,
  artifacts,
  studyId,
  patientId,
  studyData,
  setShowReportRunModal,
) => {
  const url = `${host}/sleepstudy/api/updateandretrievesleepevents?key=${studyId}`;
  let data = [];
  Object.keys(events).forEach((signalId) => {
    events[signalId].forEach((event) => {
      if (event.added || event.updated || event.removed) {
        data.push({
          SleepEventId: event.SleepEventId || null,
          GUID: event.GUID || undefined,
          VerticalOverridePosition: null,
          IsAutoScored: false,
          MarkedForRemoval: event.removed || false,
          MarkedForUpdate: event.updated || false,
          UpdatedBy: globalData.username || 'testuser1',
          SignalId: parseInt(signalId),
          PreviousSleepEventId: null,
          UpdateDate: new Date().toISOString().replace('Z', ''),
          StartTime: event.StartTime,
          EndTime: event.EndTime,
          Type: event.Type,
          Status: 'Unaltered',
          CustomCharacteristics: event.CustomCharacteristics || {
            Nadir: null,
            'Top Value': null,
            Slope: null,
            Magnitude: null,
            'DPAR Event Type': null,
            'Termination Reason': null,
          },
        });
      }
    });
  });

  artifacts.forEach((artifact) => {
    if (artifact.added || artifact.updated || artifact.removed) {
      data.push({
        SleepEventId: artifact.SleepEventId || null,
        GUID: artifact.GUID || undefined,
        VerticalOverridePosition: null,
        IsAutoScored: false,
        MarkedForRemoval: artifact.removed || false,
        MarkedForUpdate: artifact.updated || false,
        UpdatedBy: globalData.username || 'testuser1',
        SignalId: artifact.signalId,
        PreviousSleepEventId: null,
        UpdateDate: new Date().toISOString().replace('Z', ''),
        StartTime: moment(artifact.start + globalData.dateOriginTime)
          .utcOffset(0, true)
          .format()
          .replace('Z', ''),
        EndTime: moment(artifact.end + globalData.dateOriginTime)
          .utcOffset(0, true)
          .format()
          .replace('Z', ''),
        Type: artifact.Type,
        Status: 'Unaltered',
      });
    }
  });

  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + globalData.token);
  myHeaders.append('Content-Type', 'application/text');

  globalData.eventsSaved = true;

  fetch(url, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  }).then((data) => {
    if (studyData) {
      return;
      const reportData = {
        patient: {
          idPatient: parseInt(patientId),
          nameFirst: studyData.firstName,
          middleName: null,
          nameLast: studyData.lastName,
          birthDate: studyData.birthDate,
          gender: null,
          phone: null,
          email: null,
          weightLb: 0,
          neckInches: 0,
          heightInches: 0,
        },
        referring: {},
        interpreting: {
          nameFirst: studyData.firstName,
          nameLast: studyData.lastName,
        },
        report: { comments: null, interpretation: 'testing completing' },
      };
      const url = `${host}/sleepstudy/api/sleepstudypdfreport?key=${studyId}`;
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + globalData.token);
      myHeaders.append('Content-Type', 'application/pdf');
      fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(reportData),
      })
        .then(async (res) => ({
          filename: studyId + '.pdf',
          blob: await res.blob(),
        }))
        .then((resObj) => {
          const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
          setShowReportRunModal(false);
          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement('a');
            link.href = objUrl;
            link.download = resObj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        });
    }
    if (!studyData) alert('Scoring is saved!');
    let button = document.getElementById('save_scoring_button');
    button?.classList.toggle('disabled', true);
    button = document.getElementById('save_scoring_report_button');
    button?.classList.toggle('disabled', true);
  });
};

export const saveSignals = (
  events,
  studyId,
  viewConfigurationData,
  studySignals,
  chartRanges,
  chartSizes,
  chartColors,
  zoomSignalId,
  additionalLines,
  unusedSignalsIds,
  visibleEvents,
) => {
  const url = `${host}/sleepstudy/api/saveviewasdefault?key=${studyId}`;
  Object.keys(chartColors).forEach((signalId) => {
    const i = viewConfigurationData.SeriesConfigurations.findIndex(
      (s) =>
        s.SignalId === parseInt(signalId) &&
        s.ChartReference !== viewConfigurationData.HistogramId,
    );
    if (i > -1)
      viewConfigurationData.SeriesConfigurations[i].StrokeColor =
        chartColors[signalId].toLocaleUpperCase();
  });
  Object.keys(chartRanges).forEach((signalId) => {
    const i = viewConfigurationData.SeriesConfigurations.findIndex(
      (s) =>
        s.SignalId === parseInt(signalId) &&
        s.ChartReference !== viewConfigurationData.HistogramId,
    );
    if (i > -1) {
      viewConfigurationData.SeriesConfigurations[i].DefaultValueTop =
        chartRanges[signalId].max;
      viewConfigurationData.SeriesConfigurations[i].DefaultValueBottom =
        chartRanges[signalId].min;

      const j = viewConfigurationData.ChartConfigurations.findIndex(
        (c) =>
          c.ChartConfigurationId ===
          viewConfigurationData.SeriesConfigurations[i].ChartReference,
      );

      if (j > -1) {
        viewConfigurationData.ChartConfigurations[j].ValueTop =
          chartRanges[signalId].max;
        viewConfigurationData.ChartConfigurations[j].ValueBottom =
          chartRanges[signalId].min;
      }
    }
  });
  const slots = [];
  studySignals.forEach((signal, index) => {
    const slotId = generateUUID();
    slots.push({
      HeightPercentage: chartSizes[signal.SignalId] || 1,
      AreGridlinesVisible: globalData.gridLines[signal.SignalId] || false,
      Row: index,
      SlotConfigurationId: slotId,
    });
    const seriesIndex = viewConfigurationData.SeriesConfigurations.findIndex(
      (s) =>
        s.SignalId === signal.SignalId &&
        s.ChartReference !== viewConfigurationData.HistogramId,
    );
    if (seriesIndex > -1) {
      const series = viewConfigurationData.SeriesConfigurations[seriesIndex];
      if (!series.ChartReference) series.ChartReference = generateUUID();
      const i = viewConfigurationData.ChartConfigurations.findIndex(
        (c) => c.ChartConfigurationId === series.ChartReference,
      );

      if (i > -1) {
        viewConfigurationData.ChartConfigurations[i].SlotReference = slotId;
      } else {
        viewConfigurationData.ChartConfigurations.push({
          ChartConfigurationId: series.ChartReference,
          Caption: signal.Type,
          SlotReference: slotId,
          ValueTop: 200,
          ValueTopLimit: 50000,
          ValueBottom: 50,
          ValueBottomLimit: -50000,
        });
      }
      if (additionalLines[signal.SignalId]) {
        const i1 = viewConfigurationData.SeriesConfigurations.findIndex(
          (s) =>
            s.SignalId === parseInt(additionalLines[signal.SignalId]) &&
            s.ChartReference !== viewConfigurationData.HistogramId,
        );
        if (i1 >= 0) {
          viewConfigurationData.SeriesConfigurations[i1].ChartReference =
            series.ChartReference;
        }
      } else {
        const i1 = viewConfigurationData.SeriesConfigurations.findIndex(
          (s) =>
            s.SignalId !== parseInt(signal.SignalId) &&
            s.ChartReference === series.ChartReference,
        );
        if (i1 > -1) {
          viewConfigurationData.SeriesConfigurations[i1].ChartReference = null;
        }
      }
    }
  });
  const addedSignals = Object.values(additionalLines)
    .filter((s) => s)
    .map((s) => parseInt(s));
  unusedSignalsIds
    .filter((id) => !addedSignals.includes(id))
    .forEach((signalId) => {
      const seriesIndex = viewConfigurationData.SeriesConfigurations.findIndex(
        (s) =>
          s.SignalId === parseInt(signalId) &&
          s.ChartReference !== viewConfigurationData.HistogramId,
      );
      if (seriesIndex > -1) {
        viewConfigurationData.SeriesConfigurations[seriesIndex].ChartReference =
          null;
      }
    });
  const zoomSeries = viewConfigurationData.SeriesConfigurations.findIndex(
    (s) => s.ChartReference === viewConfigurationData.HistogramId,
  );
  if (zoomSeries > -1)
    viewConfigurationData.SeriesConfigurations[zoomSeries].SignalId =
      zoomSignalId;
  viewConfigurationData.SlotConfigurations = slots;

  viewConfigurationData.ChartConfigurations =
    viewConfigurationData.ChartConfigurations.filter((c) => {
      if (
        c.SlotReference &&
        !viewConfigurationData.SlotConfigurations.find(
          (s) => s.SlotConfigurationId === c.SlotReference,
        )
      )
        return false;
      return true;
    });

  viewConfigurationData.EventConfigurations.forEach((e, i) => {
    viewConfigurationData.EventConfigurations[i].Visible =
      visibleEvents.includes(e.Type);
  });

  viewConfigurationData.SeriesConfigurations.forEach((s, i) => {
    if (
      s.ChartReference &&
      !viewConfigurationData.ChartConfigurations.find(
        (c) => c.ChartConfigurationId === s.ChartReference,
      )
    )
      viewConfigurationData.SeriesConfigurations[i].ChartReference = null;
  });

  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + globalData.token);
  myHeaders.append('Content-Type', 'application/text');

  fetch(url, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(viewConfigurationData),
  }).then((result) => {
    if (result.status === 200) {
      alert('Current View Saved as Default View');
    }
  });
};

export const downloadEmployeeData = (url, name) => {
  fetch(url, {
    credentials: 'include',
  }).then((response) => {
    if (response.status !== 200) return;
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    });
    //window.location.href = response.url;
  });
};

export const getReportId = async (patientId, guid) => {
  fetch('/reports?patientId=' + patientId)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      const t = result.rows?.find((t) => t.guid === guid);
      if (t) globalData.analysisId = t.id;
    });
};

export const getResizerPoints = (isArtifact, front, x, y, h, interval) => {
  const distance = interval / 600;
  if (isArtifact) {
    return [
      { x: x - distance, y: y + h },
      { x: x + distance, y: y + h },
      { x: x + distance, y: y },
      { x: x - distance, y: y },
    ];
  }
  return front
    ? [
        { x: x - distance, y: y + h / 3 },
        { x: x + distance, y: y + h / 3 },
        { x: x + distance, y: y - h / 3 },
        { x: x - distance, y: y - h / 3 },
      ]
    : [
        { x: x - distance, y: y + h },
        { x: x + distance, y: y + h },
        { x: x + distance, y: y - h },
        { x: x - distance, y: y - h },
      ];
};

export const drawEventFigure = (
  chart,
  yAxis,
  xAxis,
  scale,
  y,
  text,
  min,
  max,
  height = figureHeight,
  dynamic = false,
) => {
  text = text.replace('Oximetry', '');
  if (!dynamic) {
    globalData.lastTextElement = chart
      .addUIElement(UIElementBuilders.TextBox, scale)
      .setText(text)
      .setMouseInteractions(false)
      .setDraggingMode(lcjs.UIDraggingModes.onlyHorizontal)
      .setPosition({ x: (min + max) / 2, y: y, z: 0 })
      .setTextFillStyle(chart.getSeriesBackgroundFillStyle())
      .setBackground((background) =>
        background.setFillStyle(emptyFill).setStrokeStyle(emptyLine),
      );
  }
  if (text) {
    const t = globalData.lastTextElement;
    // setTimeout(() => {
    const pixelLocation = translatePoint(
      // axis coordinate.
      { x: min, y: y },
      {
        x: xAxis,
        y: yAxis,
      },
      chart.pixelScale,
    );
    const pixelLocation2 = translatePoint(
      // axis coordinate.
      { x: max, y: y },
      {
        x: xAxis,
        y: yAxis,
      },
      chart.pixelScale,
    );
    const l =
      chart.engine.engineLocation2Client(pixelLocation2.x, pixelLocation2.y).x -
      chart.engine.engineLocation2Client(pixelLocation.x, pixelLocation.y).x;
    if (l / text.length < 7.5 && text) {
      t.setText(text.slice(0, Math.floor(l / 7.5)));
    }
    // }, 1000);
  }

  return chart
    .addPolygonSeries()
    .setCursorEnabled(false)
    .setHighlightOnHover(false)
    .setHighlightMode(lcjs.HighlightModes.noHighlighting)
    .add([
      { x: min + 300, y: y - height },
      { x: max, y: y - height },
      { x: max, y: y + height },
      { x: min + 300, y: y + height },
      { x: min, y: y },
    ]);
};

const resizeMouseMove = () => {
  globalData.setHoverElement(null);
  //splineSeries1.setCursorEnabled(true);
  setCanvasResize(true);
};
const resizeMouseLeave = () => {
  setCanvasResize(false);
};

const getEventChartData = (signalId, eventId, start, end) => {
  const data = globalData.loadedChartsData[signalId].data.filter(
    (p) => p.x >= start && p.x <= end,
  );
  // signalChartsList = {};
  const series = globalData.eventChart.getSeries();
  if (series.length) series[0].dispose();
  const splineSeries1 = globalData.eventChart.addLineSeries({
    xAxis: globalData.eventChart.getDefaultAxisX(),
    dataPattern: {
      pattern: 'ProgressiveX',
      regularProgressiveStep: true,
    },
  });
  splineSeries1.setStrokeStyle(
    new SolidLine({
      thickness: 2,
      fillStyle: new SolidFill({ color: ColorHEX('#050505') }),
    }),
  );
  splineSeries1.add(data);
  globalData.eventChart
    .getDefaultAxisY()
    .setInterval(splineSeries1.getYMin() - 5, splineSeries1.getYMax() + 5);
  return { eventId };
};

export const moveEvent = (
  eventId,
  signalId,
  events,
  setEvents,
  type,
  diff,
  textDiff,
  originPoints,
  figure,
  moveType,
  chart,
  addHistoryEvent,
) => {
  const newEvents = globalData.loadedEventsData[signalId].slice(0);
  const i = newEvents.findIndex((e) => e.id === eventId);
  let start = newEvents[i].startTimeMicro + (moveType !== 'back' ? diff : 0);
  let end = newEvents[i].endTimeMicro + (moveType !== 'front' ? diff : 0);
  if (start > end) {
    const t = start;
    start = end;
    end = t;
    globalData.redrawCharts = true;
  }
  if (start < 0 || end > globalData.dateEndTime - globalData.dateOriginTime) {
    alert('Events must be within the study start and end times.');
    figure.setDimensions(originPoints);
    return;
  }
  const crossedEvent = checkOverlap(
    type,
    eventId,
    signalId,
    start,
    end,
    globalData.allEventsList,
  );
  if (crossedEvent) {
    alert(crossedEvent);
    figure.setDimensions(originPoints);
    return;
  }
  const crossedEvent2 = checkArtifactOverlaping(start, end, signalId);
  if (crossedEvent2) {
    alert(newEvents[i].type + 'events cannot overlap Artifact');
    figure.setDimensions(originPoints);
    return;
  }
  //checkArtifactOverlaping(start, end, signalId);
  if (i >= 0) {
    newEvents[i] = {
      ...newEvents[i],
      StartTime: moment(start + globalData.dateOriginTime)
        .utcOffset(0, true)
        .format()
        .replace('Z', ''),
      EndTime: moment(end + globalData.dateOriginTime)
        .utcOffset(0, true)
        .format()
        .replace('Z', ''),
      startTimeMicro: start,
      endTimeMicro: end,
      updated: !newEvents[i].added,
    };
    const j = globalData.allEventsList[type].findIndex((e) => e.id === eventId);
    if (j >= 0) {
      const textPos =
        globalData.allEventsList[type][j].textFigure.getPosition();
      textPos.x = textPos.x + textDiff;
      globalData.allEventsList[type][j].textFigure.setPosition(textPos);
      if (moveType === 'front' || !moveType)
        globalData.allEventsList[type][j].resizeElementFront.setDimensions(
          globalData.allEventsList[type][j].resizeElementFront
            .getDimensions()
            .map((p) => ({ x: p.x + diff, y: p.y })),
        );
      if (moveType === 'back' || !moveType)
        globalData.allEventsList[type][j].resizeElementBack.setDimensions(
          globalData.allEventsList[type][j].resizeElementBack
            .getDimensions()
            .map((p) => ({ x: p.x + diff, y: p.y })),
        );
      if (!moveType)
        figure.setDimensions(
          originPoints.map((p) => ({ x: p.x + diff, y: p.y })),
        );

      globalData.allEventsList[type][j] = {
        ...globalData.allEventsList[type][j],
        start,
        end,
      };
    }
    addHistoryEvent({
      signalId: signalId,
      prev: globalData.loadedEventsData[signalId],
      next: newEvents,
      type: 'event',
      title: 'update event',
    });

    const pixelLocation = translatePoint(
      // axis coordinate.
      { x: start, y: 1 },
      {
        x: chart.getDefaultAxisX(),
        y: chart.getDefaultAxisY(),
      },
      chart.pixelScale,
    );
    const pixelLocation2 = translatePoint(
      // axis coordinate.
      { x: end, y: 1 },
      {
        x: chart.getDefaultAxisX(),
        y: chart.getDefaultAxisY(),
      },
      chart.pixelScale,
    );
    const l =
      chart.engine.engineLocation2Client(pixelLocation2.x, pixelLocation2.y).x -
      chart.engine.engineLocation2Client(pixelLocation.x, pixelLocation.y).x;
    const text = type.replace('Oximetry', '');
    if (l / text.length < 7.5) {
      globalData.allEventsList[type][j].textFigure.setText(
        text.slice(0, Math.floor(l / 7.5)),
      );
    } else {
      globalData.allEventsList[type][j].textFigure.setText(text);
    }
    //redrawCharts = true;
    globalData.loadedEventsData[signalId] = newEvents;
    globalData.eventsUpdated = true;
    setEvents({ ...events, [signalId]: newEvents });
  }
};

export const moveArtifact = (
  artifactId,
  setArtifacts,
  diff,
  originPoints,
  figure,
  moveType,
  chart,
  addHistoryEvent,
) => {
  const newArtifacts = globalData.artifacts.slice(0);
  const i = newArtifacts.findIndex((e) => e.id === artifactId);
  let start = newArtifacts[i].start + (moveType !== 'back' ? diff : 0);
  let end = newArtifacts[i].end + (moveType !== 'front' ? diff : 0);
  if (start > end) {
    const t = start;
    start = end;
    end = t;
    globalData.redrawCharts = true;
  }
  if (start < 0 || end > globalData.dateEndTime - globalData.dateOriginTime) {
    alert('Events must be within the study start and end times.');
    figure.setDimensions(originPoints);
    return;
  }

  if (i >= 0) {
    newArtifacts[i] = {
      ...newArtifacts[i],
      start,
      end,
      updated: !newArtifacts[i].added,
    };
    const crossedEvent = checkEventArtifactOverlaping(newArtifacts[i]);
    if (crossedEvent) {
      alert(crossedEvent.type + ' events cannot overlap Artifact');
      figure.setDimensions(originPoints);
      return;
    }
    const crossedArtifact = checkArtifactOverlaping(
      start,
      end,
      newArtifacts[i].signalId,
      newArtifacts[i].id,
    );
    if (crossedArtifact) {
      alert('Artifact cannot overlap another Artifact');
      figure.setDimensions(originPoints);
      return;
    }

    const a = globalData.artifactsList[artifactId];
    if (a) {
      if (moveType === 'front' || !moveType)
        globalData.artifactsList[artifactId].resizeElementFront.setDimensions(
          a.resizeElementFront
            .getDimensions()
            .map((p) => ({ x: p.x + diff, y: p.y })),
        );
      if (moveType === 'back' || !moveType)
        globalData.artifactsList[artifactId].resizeElementBack.setDimensions(
          a.resizeElementBack
            .getDimensions()
            .map((p) => ({ x: p.x + diff, y: p.y })),
        );
      if (!moveType)
        figure.setDimensions(
          originPoints.map((p) => ({ x: p.x + diff, y: p.y })),
        );
    }
    addHistoryEvent({
      prev: globalData.artifacts,
      next: newArtifacts,
      type: 'artifact',
      title: 'update artifact',
    });
    //redrawCharts = true;
    //globalData.loadedEventsData[signalId] = newEvents;
    globalData.eventsUpdated = true;
    setArtifacts(newArtifacts);
  }
};

export const createEventChart = () => {
  const license =
    window.location.hostname === 'localhost'
      ? {}
      : {
          license:
            '0001-0b1e8-a309c-d41d0-be04d-55814-5818d-6d839-faac0-f6c57-532b3-eaf49-00020-10aa8-d0100-0048b-9f21f',
        };
  const dashboard = lightningChart(license)
    .Dashboard({
      theme: Themes.lightNew,
      numberOfColumns: 1,
      container: 'eventHoverChart',
      numberOfRows: 1,
      height: 200,
      margin: { top: 0 },
    })
    .setSplitterStyle(new SolidLine({ thickness: 0 }));
  globalData.eventChart = dashboard
    .createChartXY({
      columnIndex: 0,
      columnSpan: 1,
      rowIndex: 0,
      rowSpan: 1,
      theme: Themes.lightNew,
      defaultAxisX: {
        opposite: true,
      },
    })
    .setTitleMarginBottom(0)
    .setTitle('')
    .setTitleFillStyle(emptyFill)
    .setPadding({
      right: 0,
      top: 0,
      bottom: 10,
      left: 0,
      //bottom: i === 0 ? 0 : -30,
    })
    .setTitleMarginTop(0);
  globalData.eventChart
    .getDefaultAxisX()
    .setOverlayStyle(axisXStyleHighlight)
    .setMouseInteractions(false)
    .setNibOverlayStyle(axisXStyleHighlight)

    .setThickness(0)
    .setNibLength(0)
    .setNibStyle(emptyLine)
    .setAnimationScroll(false);
  globalData.eventChart
    .getDefaultAxisY()
    .setStrokeStyle(axisYStrokeStyles[0])
    .setTitle('')
    .setMouseInteractions(false)
    .setOverlayStyle(axisYStylesHighlight[0])
    .setNibOverlayStyle(axisYStylesHighlight[0])
    .setInterval(0, 100)
    .setNibLength(10)
    .setTitleFont(
      new FontSettings({
        size: 11,
        family: 'Arial, Helvetica, sans-serif',
        weight: 'bold',
        style: 'italic',
      }),
    )
    .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
      tickStrategy
        .setMajorFormattingFunction((tickPosition) => {
          return Math.round(tickPosition).toString();
        })
        .setMinorFormattingFunction((tickPosition) => {
          return Math.round(tickPosition).toString();
        })
        .setMinorTickStyle((tickStyle) =>
          tickStyle.setGridStrokeStyle(emptyLine),
        ),
    )
    .setScrollStrategy(AxisScrollStrategies.regressive);
  globalData.eventChart
    .getDefaultAxisY()
    .addCustomTick()
    .setTickLength(30)
    .setTextFormatter((position, customTick) => '');
};

export const geteventChartData = (signalId, eventId, start, end) => {
  const data = globalData.loadedChartsData[signalId].data.filter(
    (p) => p.x >= start && p.x <= end,
  );
  // signalChartsList = {};
  const series = globalData.eventChart.getSeries();
  if (series.length) series[0].dispose();
  const splineSeries1 = globalData.eventChart.addLineSeries({
    xAxis: globalData.eventChart.getDefaultAxisX(),
    dataPattern: {
      pattern: 'ProgressiveX',
      regularProgressiveStep: true,
    },
  });
  splineSeries1.setStrokeStyle(
    new SolidLine({
      thickness: 2,
      fillStyle: new SolidFill({ color: ColorHEX('#050505') }),
    }),
  );
  splineSeries1.add(data);
  const min = splineSeries1.getYMin();
  const max =
    globalData.loadedChartsData[signalId].Type === 'SPO2'
      ? min < 30
        ? 105
        : 101
      : splineSeries1.getYMax() + 5;
  globalData.eventChart.getDefaultAxisY().setInterval(min - 5, max);
  return { eventId };
};

export const loadStudyData = (setStudyData, studyId, patientId) => {
  window.eventDrawing = false;
  // fetch(`${host}/sleepstudy/api/sleepstudyheaderinfo?key=${studyId}`)
  //   .then((response) => response.json())
  //   .then((result) => {
  //     //setStudyData(result);
  //   });
  if (patientId)
    fetch(`/patients/${patientId}`)
      .then((response) => response.json())
      .then((result) => {
        //setStudyData(result);
        // setPatientName(result.firstName + " " + result.lastName);
        setStudyData({
          SleepStudyId: patientId,
          PatientName: result.firstName + ' ' + result.lastName,
          firstName: result.firstName,
          lastName: result.lastName,
          birthDate: result.birthDate,
          InterpretingPhysicianName: '<unknown>',
          ReferringPhysicianName: '<unknown>',
          SleepStudyDate: new Date().toISOString(),
        });
      });
  else {
    setStudyData({
      SleepStudyId: 142,
      PatientName: 'Test Test',
      InterpretingPhysicianName: '<unknown>',
      ReferringPhysicianName: '<unknown>',
      SleepStudyDate: new Date().toISOString(),
    });
  }
};

export const addEventActivate = () => {
  if (window.artifactDrawing) addArtifactActivate();
  const button = document.getElementById('add_event_button');
  button.classList.toggle('ant-btn-primary');
  document
    .getElementById('chartContainer')
    .classList.toggle('add_event_cursor');
  window.eventDrawing = !window.eventDrawing;
};

export const addArtifactActivate = () => {
  if (window.eventDrawing) addEventActivate();
  const button = document.getElementById('add_artifact_button');
  button.classList.toggle('ant-btn-primary');
  document
    .getElementById('chartContainer')
    .classList.toggle('add_event_cursor');
  window.artifactDrawing = !window.artifactDrawing;
};

export const addResizers = (
  chart,
  isArtifact,
  events,
  setEvents,
  start,
  end,
  y,
  h,
  scale,
  signalId,
  type,
  id,
  polygonFigure,
  zoomIntervalGlobal,
  addHistoryEvent,
) => {
  const resizeElementFront = chart
    .addPolygonSeries()
    .setCursorEnabled(false)
    .setHighlightOnHover(false)
    .setHighlightMode(lcjs.HighlightModes.noHighlighting)
    .add(
      getResizerPoints(
        isArtifact,
        false,
        start,
        y,
        h,
        zoomIntervalGlobal.end - zoomIntervalGlobal.start,
      ),
    )
    .setFillStyle(new SolidFill({ color: ColorHEX('#ffffff00') }))
    .setStrokeStyle(strokeStyle2);

  const resizeElementBack = chart
    .addPolygonSeries()
    .setCursorEnabled(false)
    .setHighlightOnHover(false)
    .setHighlightMode(lcjs.HighlightModes.noHighlighting)
    .add(
      getResizerPoints(
        isArtifact,
        false,
        end,
        y,
        h,
        zoomIntervalGlobal.end - zoomIntervalGlobal.start,
      ),
    )
    .setFillStyle(new SolidFill({ color: ColorHEX('#ffffff00') }))
    .setStrokeStyle(strokeStyle2);

  resizeElementFront.onMouseMove(resizeMouseMove);
  resizeElementBack.onMouseMove(resizeMouseMove);

  resizeElementFront.onMouseLeave(resizeMouseLeave);
  resizeElementBack.onMouseLeave(resizeMouseLeave);

  const resizeDown = (t, e) => {
    globalData.resizerPressed = {
      position: translatePoint(
        chart.engine.clientLocation2Engine(e.clientX, e.clientY),
        chart.engine.scale,
        scale,
      ),
      points: polygonFigure.getDimensions(),
    };
  };
  resizeElementFront.onMouseDown(resizeDown);
  resizeElementBack.onMouseDown(resizeDown);

  const resizeDrag = (e, front) => {
    const newPosition = translatePoint(
      chart.engine.clientLocation2Engine(e.clientX, e.clientY),
      chart.engine.scale,
      scale,
    );
    const points = globalData.resizerPressed.points.slice(0);
    const diff = newPosition.x - globalData.resizerPressed.position.x;
    const ar = front ? [1, 2] : [0, 3, 4];
    polygonFigure.setDimensions(
      points.map((p, i) => {
        return ar.includes(i) ? p : { x: p.x + diff, y: p.y };
      }),
    );
    globalData.setHoverElement({
      position: { x: e.pageX, y: e.pageY },
      size: {
        start: front ? newPosition.x : points[4].x,
        end: front ? points[1].x : newPosition.x,
      },
      isEventDrawing: true,
      onRight: false,
    });
  };

  resizeElementFront.onMouseDrag((t, e) => {
    resizeDrag(e, true);
  });
  resizeElementBack.onMouseDrag((t, e) => {
    resizeDrag(e, false);
  });

  const resizeUp = (e, front) => {
    const newPosition = translatePoint(
      chart.engine.clientLocation2Engine(e.clientX, e.clientY),
      chart.engine.scale,
      scale,
    );
    const diff = newPosition.x - globalData.resizerPressed.position.x;
    if (isArtifact) {
      moveArtifact(
        id,
        setEvents,
        diff,
        globalData.resizerPressed.points,
        polygonFigure,
        front ? 'front' : 'back',
        chart,
        addHistoryEvent,
      );
    } else
      moveEvent(
        id,
        signalId,
        events,
        setEvents,
        type,
        diff,
        diff / 2,
        globalData.resizerPressed.points,
        polygonFigure,
        front ? 'front' : 'back',
        chart,
        addHistoryEvent,
      );
    globalData.resizerPressed = null;
  };
  resizeElementFront.onMouseUp((t, e) => {
    resizeUp(e, true);
  });
  resizeElementBack.onMouseUp((t, e) => {
    resizeUp(e, false);
  });
  resizeElementFront.onMouseDragStop((t, e) => {
    setTimeout(() => {
      if (globalData.resizerPressed) {
        polygonFigure.setDimensions(globalData.resizerPressed.points);
        globalData.resizerPressed = null;
      }
    }, 500);
  });

  return [resizeElementFront, resizeElementBack];
};

export const addArtifactMoving = (
  artifact,
  setArtifacts,
  figure,
  chart,
  splineSeries1,
  setSelectedArtifact,
  addHistoryEvent,
) => {
  figure.onMouseClick((t, e) => {
    if (
      globalData.artifactPressed &&
      Math.abs(e.clientX - globalData.artifactPressed.clientX) > 1
    ) {
      const newPosition = translatePoint(
        chart.engine.clientLocation2Engine(e.clientX, e.clientY),
        chart.engine.scale,
        splineSeries1.scale,
      );
      const diff = newPosition.x - globalData.artifactPressed.position.x;
      moveArtifact(
        artifact.id,
        setArtifacts,
        diff,
        globalData.artifactPressed.points,
        figure,
        '',
        chart,
        addHistoryEvent,
      );
      return;
    }

    setSelectedArtifact({
      id: artifact.id,
    });
  });
  figure.onMouseDragStart((t, e) => {
    globalData.artifactPressed = {
      clientX: e.clientX,
      position: translatePoint(
        chart.engine.clientLocation2Engine(e.clientX, e.clientY),
        chart.engine.scale,
        splineSeries1.scale,
      ),
      points: figure.getDimensions(),
    };
    hideEventHoverChart();
    globalData.setHoverElement(null);
  });
  figure.onMouseDrag((t, e) => {
    if (globalData.artifactPressed) {
      const newPosition = translatePoint(
        chart.engine.clientLocation2Engine(e.clientX, e.clientY),
        chart.engine.scale,
        splineSeries1.scale,
      );
      const diff = newPosition.x - globalData.artifactPressed.position.x;
      figure.setDimensions(
        globalData.artifactPressed.points.map((p) => ({
          x: p.x + diff,
          y: p.y,
        })),
      );
      globalData.setHoverElement({
        position: { x: e.pageX, y: e.pageY },
        size: { start: artifact.start + diff, end: artifact.end + diff },
        isEventDrawing: true,
        onRight: false,
      });
    }
  });

  figure.onMouseLeave((t, e) => {
    globalData.setHoverElement(null);
    splineSeries1.setCursorEnabled(true);
  });
};

export const drawPulseTags = (chart, start = 0, end = 0) => {
  const pol = chart.addPolygonSeries();
  const y =
    (start || chart.getDefaultAxisY().getInterval().start) +
    (end ? Math.floor(end - start) / 10 : 5);
  if (globalData.pulseTagPolygons) {
    globalData.pulseTagPolygons.forEach((p) => {
      p.dispose();
    });
    globalData.pulseTagPolygons = [];
  }

  pol.setHighlightOnHover(true);
  globalData.pulseTags.forEach((tag) => {
    const f = pol
      .add([
        { x: tag.start, y: y },
        { x: tag.end, y: y },
        { x: tag.end, y: y + 1 },
        { x: tag.start, y: y + 1 },
      ])
      .setFillStyle(new SolidFill({ color: ColorHEX('#000000ff') }));
    f.onMouseMove((t, e) => {
      globalData.setHoverElement({
        position: { x: e.pageX, y: e.pageY },
        size: { start: '1', end: '2' },
        isEventDrawing: true,
        customText: tag.ClusterType,
        onRight: false,
      });
    });
    f.onMouseLeave((t) => {
      globalData.setHoverElement(null);
    });

    globalData.pulseTagPolygons.push(f);
  });
};

export const getAutoScale = (signalId, int) => {
  const signalData = globalData.loadedChartsData[signalId];
  if (!signalData) return;
  const secondSignal =
    globalData.additionalLines[signalId] &&
    globalData.loadedChartsData[globalData.additionalLines[signalId]];
  let min = 10000000;
  let max = -10000000;
  let start = int ? int.start : globalData.zoomInterval.start;
  let end = int ? int.end : globalData.zoomInterval.end;
  start = Math.floor((start * signalData.rate) / 1000);
  end = Math.floor((end * signalData.rate) / 1000);
  if (secondSignal) {
    const rate2 = signalData.rate / secondSignal.rate;
    for (var i = start; i < end; i++) {
      if (!signalData.data[i]) continue;
      if (signalData.data[i].y < min) min = signalData.data[i].y;
      if (signalData.data[i].y > max) max = signalData.data[i].y;
    }
    for (var i = Math.floor(start / rate2); i < Math.floor(end / rate2); i++) {
      if (!secondSignal.data[i]) continue;
      if (secondSignal.data[i].y < min) min = secondSignal.data[i].y;
      if (secondSignal.data[i].y > max) max = secondSignal.data[i].y;
    }
  } else {
    for (let i = start; i < end; i++) {
      if (!signalData.data[i]) continue;
      if (signalData.data[i].y < min) min = signalData.data[i].y;
      if (signalData.data[i].y > max) max = signalData.data[i].y;
    }
  }
  return { min, max };
  //const data = signalData.data.map((point, i) => ({ x: (i * 1000) / signalData.rate, y: point }))
};

export const checkChartRanges = (setChartRanges) => {
  const ranges = globalData.initialRanges;
  let update = false;
  Object.keys(globalData.loadedChartsData).forEach((signalId) => {
    if (parseInt(signalId) === globalData.pulseSignalId) {
      ranges[signalId] = { min: 50, max: 90 };
      globalData.chartRanges[signalId] = { min: 50, max: 90 };
      update = true;
      return;
    }
    if (globalData.loadedChartsData[signalId].Type === 'SPO2') {
      let min = 90;
      globalData.loadedChartsData[signalId].data.forEach((p) => {
        if (p.x > 5000 && p.y < min && p.y > 60) min = p.y;
      });
      const minSpo2Value = Math.floor(min / 10) * 10;
      ranges[signalId] = { min: minSpo2Value - 1, max: 100 };
      globalData.chartRanges[signalId] = { min: minSpo2Value - 1, max: 100 };
      return;
    }
    const autoRange = getAutoScale(signalId, null);
    const range = ranges[signalId];
    if (!range || range.max < autoRange.max || range.min > autoRange.min) {
      update = true;
      ranges[signalId] = autoRange;
    }
  });
  if (update) setChartRanges(ranges);
};

export const interpolatePoints = (points, smaPeriodSize = 5) => {
  const lastNSamples = [];
  let newData = [];
  points.forEach((point) => {
    lastNSamples.push(point.y);
    if (lastNSamples.length > smaPeriodSize) {
      lastNSamples.shift();
    }
    const sma =
      lastNSamples.reduce((prev, cur) => prev + cur, 0) / lastNSamples.length;
    newData.push({ x: point.x, y: sma });
  });
  return newData;
};

export const getToken = function () {
  var url = host + '/Token';
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  var urlencoded = new URLSearchParams();
  urlencoded.append('username', 'report-manager@patientsafetyinc.com');
  urlencoded.append('password', 'Passw0rd!');
  urlencoded.append('grant_type', 'password');

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };
  return fetch(url, requestOptions);
};

export const getMaxValuesLine = async (signalId) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + globalData.token);

  const url = `${host}/sleepstudy/api/signaltimewindowmaxpointvalues?signalId=${signalId}&timeWindowSizeInMilliseconds=3000`;

  const values = await fetch(url, {
    method: 'GET',
    headers: myHeaders,
  });
  return values.json();
};
export const getMinValuesLine = async (signalId) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + globalData.token);
  const url = `${host}/sleepstudy/api/signaltimewindowminpointvalues?signalId=${signalId}&timeWindowSizeInMilliseconds=3000`;

  const values = await fetch(url, {
    method: 'GET',
    headers: myHeaders,
  });
  return values.json();
};

export const getPulseTags = async (signalId) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + globalData.token);
  const url = `${host}/sleepstudy/api/signalclusterlines?signalId=${signalId}`;

  const values = await fetch(url, {
    method: 'GET',
    headers: myHeaders,
  });
  return values.json();
};

export const getLineSteps = (eventsData, onlyColors = false) => {
  const chartColors = globalData.chartColors;
  const chartsData = globalData.loadedChartsData;
  const lineSteps = {};
  const chartAreasData = {};
  let c = 0;
  Object.keys(eventsData).forEach((signalId) => {
    if (!chartsData[signalId]) return;
    lineSteps[signalId] = [
      { value: 0, color: ColorHEX(chartColors[signalId] || '#FF0000') },
    ];
    eventsData[signalId].forEach((event) => {
      if (event.Type === 'Recovery') {
        lineSteps[signalId].push({
          value: event.startTimeMicro + 1,
          color: ColorHEX('#fc880340'),
        });
        lineSteps[signalId].push({
          value: event.endTimeMicro - 1,
          color: ColorHEX(chartColors[signalId] || '#FF0000'),
        });
        return;
      }
      if (event.Type === 'Desaturation') {
        lineSteps[signalId].push({
          value: event.startTimeMicro + 1,
          color: ColorHEX('#fc2003a0'),
        });
        lineSteps[signalId].push({
          value: event.endTimeMicro - 1,
          color: ColorHEX(chartColors[signalId] || '#FF0000'),
        });
      }
      if (onlyColors) return;
      if (event.Type === 'OximetryReciprocation') {
        if (!chartAreasData[signalId]) chartAreasData[signalId] = [];
        const i1 = chartsData[signalId].data.findIndex(
          (t) => t.x >= event.startTimeMicro,
        );
        let i2 = chartsData[signalId].data.findIndex(
          (t) => t.x >= event.endTimeMicro,
        );
        if (i2 === chartsData[signalId].data.length - 1) i2--;
        const topValue = chartsData[signalId].data[i1].y;
        const topValue2 = chartsData[signalId].data[i2].y;
        const k = (topValue2 - topValue) / (i2 - i1);

        for (let i = i1; i < i2; i++) {
          chartAreasData[signalId].push({
            position: chartsData[signalId].data[i].x,
            low: chartsData[signalId].data[i].y,
            high: topValue + k * (i - i1),
          });
        }
        chartAreasData[signalId].push({
          position: chartsData[signalId].data[i2].x,
          high: topValue2,
          low: topValue2 - 0.1,
        });
        chartAreasData[signalId].push({
          position: chartsData[signalId].data[i2 + 1].x,
          low: Number.NaN,
          high: Number.NaN,
        });
      }

      if (event.Type === 'PulseReciprocation') {
        if (!chartAreasData[signalId])
          chartAreasData[signalId] = [
            {
              position: 10000,
              low: Number.NaN,
              high: Number.NaN,
            },
          ];
        //if (c % 30 === 0) return;
        const i1 = chartsData[signalId].data.findIndex(
          (t) => t.x >= event.startTimeMicro,
        );
        let i2 = chartsData[signalId].data.findIndex(
          (t) => t.x >= event.endTimeMicro,
        );

        //  if (c === 35) return;
        //if (c < 5) return;
        const topValue = chartsData[signalId].data[i1].y;
        let topValue2 = chartsData[signalId].data[i2].y;
        if (topValue > 0 && topValue2 <= 0) {
          i2--;
          topValue2 = chartsData[signalId].data[i2].y;
        }

        const k = (topValue2 - topValue) / (i2 - i1);

        for (let i = i1; i < i2; i += 2) {
          let h1 = chartsData[signalId].data[i].y;
          const l1 = topValue + k * (i - i1);
          if (l1 < 10) return;
          if (h1 === l1) h1 = l1 + 0.01;
          chartAreasData[signalId].push({
            position: chartsData[signalId].data[i].x,
            high: h1 > l1 ? h1 : l1,
            low: h1 > l1 ? l1 : h1,
          });
        }
        chartAreasData[signalId] = chartAreasData[signalId].slice(0, 5000);
        if (!chartsData[signalId].data[i2 + 1]) {
          chartAreasData[signalId].push({
            position: chartsData[signalId].data[i2].x - 100,
            high: topValue2,
            low: topValue2 - 1,
          });
          chartAreasData[signalId].push({
            position: chartsData[signalId].data[i2].x - 500,
            low: Number.NaN,
            high: Number.NaN,
          });
        } else {
          chartAreasData[signalId].push({
            position: chartsData[signalId].data[i2].x,
            high: topValue2,
            low: topValue2 - 1,
          });
          chartAreasData[signalId].push({
            position: chartsData[signalId].data[i2].x + 100,
            low: Number.NaN,
            high: Number.NaN,
          });
        }
      }
      // if (event.Type === "PulseReciprocation") {
      //   if (!chartAreasData[signalId]) chartAreasData[signalId] = [];
      //   const i1 = chartsData[signalId].data.findIndex((t) => t.x >= event.startTimeMicro);
      //   const i2 = chartsData[signalId].data.findIndex((t) => t.x >= event.endTimeMicro);
      //   const topValue = chartsData[signalId].data[i1].y;
      //   const topValue2 = chartsData[signalId].data[i2].y;
      //   for (let i = i1; i < i2; i++) {
      //     chartAreasData[signalId].push({
      //       position: chartsData[signalId].data[i].x,
      //       low: chartsData[signalId].data[i].y,
      //       high: topValue + ((topValue2 - topValue) * (i - i1)) / (i2 - i1),
      //     });
      //   }
      //   console.log(event, i1, i2, chartsData[signalId].data[i1].x, chartsData[signalId].data[i2].x);
      // }
    });
  });
  if (onlyColors) return lineSteps;
  return [lineSteps, chartAreasData];
};

export const generateSearchOptions = (eventsTyped) => {
  return Object.keys(eventsTyped)
    .sort()
    .map((t) => ({
      label: t.replace(/([a-z])([A-Z])/g, '$1 $2'),
      value: t,
    }));
};
