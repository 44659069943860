import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import $ from 'jquery'

import Search from './patient-search'
import Paginator from './paginator'


class Patients extends Component {

	constructor(props) {
		super(props)
		this.url = '/patients'
		this.state = { 
			patients: [],
			searched: [],
			limit: 10,
			offset: 0,
			limitedPatients: [],
			fetchingPatients: false
		}

		this.setFilteredPatients = this.setFilteredPatients.bind(this)
		this.dataHandler = this.dataHandler.bind(this)
	}

	componentDidMount() {
		this.read()
	}

	componentDidUpdate(props, state) {
		var userUpdated = this.props.user.id !== props.user.id
		var organizationUpdated = this.props.organization.id !== props.organization.id
		if (userUpdated || organizationUpdated) { 
			this.setState({
				patients: []
			})
			this.read() 
		}
	}

	searchHandler(event) {
		event.preventDefault()
		var elements = event.target.elements
		var filtered = this.state.patients.filter((e) => e.firstName.toLowerCase() === elements.firstName.value.toLowerCase() || e.lastName.toLowerCase() === elements.lastName.value.toLowerCase())
		
		var filteredOut = []
		var count = 0
		var same = false

		for (var i = 0; i < filtered.length; i++){
			for(var j = 0; j<filteredOut.length; j++) {
				if ( filtered[i].id === filteredOut[j].id ) {
					same = true
				}
			}
			count++
			if (count === 1 && same === false) {
				filteredOut.push(filtered[i])
			}
			same = false;
			count = 0
		}
		if(filteredOut < 1){
			alert("No Results found")
		}else {
			this.setState({
				searched: filteredOut
			})
		}


	}

	read(params) {
		var success = this.dataHandler.bind(this)
		var options = {
			organizationId: this.props.organization.id,
			limit: 100,
			offset:this.state.offset
		}
		for (var key in params) {
			var value = params[key]
			if (value) options[key] = params[key]
		}

		$.ajax({
			method: 'GET',
			url: this.url,
			data: options,
		}).done(success)
	}

	dataHandler(data) {
		let newPatients = this.state.patients.slice()
		data.rows.forEach(newPatient => {
			newPatients.push(newPatient)
		});
		let hasMore = data.count > newPatients.length ? true : false

		this.setState({
			patients: newPatients,
			offset: this.state.offset + 100,
			fetchingPatients: hasMore
		}, () => {
			if (hasMore) {
				this.read()
			} else {
				this.setState({
					offset: 0
				})
			}
		})
	}

	setFilteredPatients(data){
		this.setState({
			limitedPatients: data
		})
	}

	clearOffSet(){
		this.setState({
			offset: 0
		})
	}

	renderListItem(data) {
		var id = data.id
		var name = data.firstName + ' ' + data.lastName
		var path = '/patients/' + id
		var birthDate = moment(data.birthDate).utc().format('MMMM D, YYYY')
		return (
			<Link className='list-group-item border border-muted rounded list-group-item-action'
				style={{ padding: 2 }}
				to={path} key={id}>
				<div className='ml-4 row'>
					<span>{name}</span>
				</div>
				<div className='ml-4 row'>
					<small>{birthDate}</small>
				</div>
			</Link>
		)
	}

	// submit(event) {
	// 	if (event) event.preventDefault()
	// }



	render() {
		var patients = this.state.limitedPatients.map(this.renderListItem)
		var conditionalData
		if (this.state.searched.length > 0) {
			conditionalData = this.state.searched
		}else{
			conditionalData = this.state.patients
		}

		return (
			<div className='card'>
				<div className='card-header'>
					<div className='row align-items-center'>
						<div className='col'>
							<span className='h4'>Patients</span>
						</div>
						{/* New Patient */}
						<div className='col text-right'>
							<Link to='/patients/0'
								role='button'
								className='btn btn-sm btn-outline-primary'>
								<FontAwesomeIcon className='fa fas' icon={faPlus} />
							</Link>
						</div>
					</div>
				</div>
				<div className='card-body'>
					<div>
						<div className='row'>
							<div className='col'>
								<Search onSubmit={this.searchHandler.bind(this)} />
							</div>
						</div>
						<div className='row'>
							<div className='col'>
								<ul className='list-group'>
									{patients}
								</ul>
							</div>
						</div>
					</div>
					<div className='col mt-2'>
						<nav>
							<Paginator data={conditionalData}
								limit={this.state.limit}
								render={this.setFilteredPatients} />
						</nav>
					</div>
				</div>
			</div>
		)
	}

}

export default Patients
