import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  FastBackwardOutlined,
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  VerticalRightOutlined,
  VerticalLeftOutlined,
  FastForwardFilled,
  PlayCircleFilled,
  PauseOutlined,
  ForwardOutlined,
  BackwardOutlined,
  UndoOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import './chart.css';
import {
  Tooltip,
  Modal,
  Select,
  Button,
  DatePicker,
  Dropdown,
  Radio,
  Input,
  Space,
  Checkbox,
} from 'antd';
import body from './img/body.png';
import ChartSettingsModal from './ChartSettingsModal';
import ChartIcons from './ChartIcons';
import { CustomDropdown } from './Dropdown';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import { globalData } from './globals';
import {
  axisYStrokeStyles,
  timeIntervalSizes,
  axisYStylesHighlight,
  axisXStyleHighlight,
  fittingRectangleStrokeStyle,
  zoomingRectangleFillStyle,
  fillStyle,
  debounce,
  eventY,
  strokeStyle,
  figureHeight,
  speedMultipliers,
  useEventsHistory,
  checkOverlap,
  checkArtifactOverlaping,
  defaultEventSelectValues,
  customEventFields,
  useHoveredElement,
  host,
  getRandomDarkColor,
  getBodyRotation,
  saveSignals,
  saveEvents,
  strokeStyle2,
  eventSectors,
  drawPolygonEvent,
  hideEventHoverChart,
  NoYLabelSignals,
  drawEventFigure,
  createEventChart,
  geteventChartData,
  loadStudyData,
  addEventActivate,
  addArtifactActivate,
  addResizers,
  checkEventArtifactOverlaping,
  moveEvent,
  addArtifactMoving,
  artifactColors,
  getAutoScale,
  checkChartRanges,
  interpolatePoints,
  getToken,
  getBodyRotationDirection,
  bodyRotationColor,
  getMaxValuesLine,
  getMinValuesLine,
  getPulseTags,
  drawPulseTags,
  getLineSteps,
  ExcludedEvents,
  getResizerPoints,
  getReportId,
  downloadEmployeeData,
  generateSearchOptions,
} from './utils';
import { faSpinner, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

dayjs.extend(customParseFormat);
const lcjs = require('@arction/lcjs');

const {
  lightningChart,
  ColorRGBA,
  SolidFill,
  SolidLine,
  emptyLine,
  emptyFill,
  AxisTickStrategies,
  UIElementBuilders,
  AxisScrollStrategies,
  FontSettings,
  synchronizeAxisIntervals,
  translatePoint,
  ColorHEX,
  Themes,
  emptyTick,
  customTheme,
  PointShape,
  PalettedFill,
  LUT,
  transparentLine,
  UIVisibilityModes,
  UIDraggingModes,
  MarkerBuilders,
  UIBackgrounds,
  transparentFill,
} = lcjs;

const myTheme = customTheme(Themes.lightNew, {
  axisStyle: new SolidLine({ thickness: 0 }),
  dashboardSplitterStyle: new SolidLine({ thickness: 0 }),
});

let manualEvents = {};
let lastPolygonFigure = null;
let lastDrawnElement = null;
let rowHeight = 0;
let studySignals = [];
let unusedSignals = [];
let allEventTypesList = [];
let timeIntervalString = null;
let tempEvent;
let newEventSize = {};
let autoChartRanges = {};
let eventId = 1;
let minutesNumber = 5;
let bandSize = 300000;
let zoomIntervalGlobal = { start: 0, end: 300000 };
let dateOriginTime = 0;
let dateEndTime = 300000;
let dateOrigin = null;
let artifactId = 0;
let imageRotation = 0;
let sampleRates = {};
let signalsData = [];
let selectedChartIds = [];
let firstLoad = true;
let signalIdsData = null;
let positionSignal = null;
let viewConfigurationData = {};
let chartsDataUpdated = 0;
let additionalLinesList = [];
let signalPairs = {};
let chartDrawing = true;
let redrawCharts = true;
let xAxisList = [];
let yAxisList = {};
let chartsList = {};
let signalChartsList = {};
let spoLabels = [];
let spoLabelPoints = [];
let minSpo2Value = null;
let zoomBandChart = null;
let zoomId = null;
let zoomIdActual = null;
let manualEventTypes = {};
let eventPressed = null;
let chartSignalDrawData = {};
let resizeElements = [];
let firstAxis = null;
let signalLines = {};
let signalsToLoad = [];
let eventSignalIds = {};
let configurationLoading = false;
let lineSteps,
  chartAreasData = {};
let pulseLabels = [];
let pulseLabelsPoints = [];
let timer = null;
let visibleEventPolygons = [];
let visibleEventTextElements = [];
let searchedEventId = 0;
let lsWaterMarks = [];
const maxRate = 10;

function Charts({ location }) {
  // const history = createBrowserHistory();
  const studyId = location.match?.params?.id || 142;
  const patientId = location.match?.params?.patientId;
  const guid = location.match?.params?.id;
  globalData.guid = guid;
  const [signalIds, setSignalIds] = useState(null);
  const [studyData, setStudyData] = useState(null);
  const [interval, setTimeInterval] = useState({});
  const [zoomInterval, setZoomInterval] = useState({ start: 0, end: 300000 });
  const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [speed, setSpeed] = useState(0);
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [firstChart, setFirstChart] = useState(null);
  const [eventTypes, setEventTypes] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [eventEditMode, setEventEditMode] = useState(false);
  const [allChartData, setAllChartData] = useState({});
  const [chartRanges, setChartRanges] = useState({});
  const [chartSizes, setChartSizes] = useState({});
  const [chartSettingsModal, setChartSettingsModal] = useState(false);
  const [searchEvent, setSearchEvent] = useState(null);
  const [selectedSearhEvent, setSelectedSearchEvent] = useState(null);
  const [eventSize, setEventSize] = useState({});
  const [playMode, setPlayMode] = useState('page');
  const [selectedTimeInterval, setSelectedTimeTinterval] = useState(
    timeIntervalSizes[2],
  );
  const [eventTop, setEventTop] = useState(eventY);
  const [visibleEvents, setVisibleEvents] = useState(allEventTypesList);
  const [visibleEventTypesSelectorOpen, setVisibleEventTypesSelectorOpen] =
    useState(false);
  const [artifacts, setArtifacts] = useState([]);
  const [selectedArtifact, setSelectedArtifact] = useState(null);
  const [zoomSignalId, setZoomSignalId] = useState(834);
  const [showReportRunModal, setShowReportRunModal] = useState(false);
  const [histogramColor, setHistogramColor] = useState('#FF0000');
  const [setDateOriginTime, setHoverElement] = useHoveredElement();
  const [generatingPdf, setGeneratingPdf] = useState(false);

  useEffect(() => {
    globalData.artifacts = artifacts;
  }, [artifacts]);

  useEffect(() => {
    globalData.zoomInterval = zoomInterval;
  }, [zoomInterval]);

  useEffect(() => {
    const username =
      localStorage.getItem('username') || localStorage.getItem('email');
    if (!username) {
      localStorage.setItem('redirectChart', studyId);
      location.history.push('/login');
      window.location.reload();
    } else globalData.username = username;
  }, [studyId, location]);

  useEffect(() => {
    if (!globalData.eventTopCustom && eventTop && signalIds) {
      globalData.eventTopCustom = {};
      signalIds.forEach((s) => {
        if (eventSectors[s.FullType]) {
          const top = eventTop;
          eventSectors[s.FullType].forEach((sector) => {
            top[sector.type] = (sector.min + sector.max) / 2;
          });
          globalData.eventTopCustom[s.SignalId] = top;
        }
      });
    }
  }, [eventTop, signalIds]);

  const loadPositionSignal = useCallback(() => {
    const signal = Object.values(globalData.loadedChartsData).find(
      (s) => s.Type === 'Position',
    );
    if (signal) {
      positionSignal = {
        data: signal.data,
        rate: signal.rate,
      };
    }
  }, []);

  const loadViewConfiguration = useCallback(
    async (studyId) => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + globalData.token);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(
        `${host}/sleepstudy/api/sleepstudyviewconfiguration?key=${studyId}`,
        requestOptions,
      )
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          viewConfigurationData = result;
          const selected = [];
          const h = 1 / result.SlotConfigurations.length;
          const sizes = {};
          let seriesNames = {};
          result.SlotConfigurations.forEach((conf) => {
            const chart = result.ChartConfigurations.find(
              (c) => c.SlotReference === conf.SlotConfigurationId,
            );
            if (chart) {
              const series = result.SeriesConfigurations.filter(
                (s) =>
                  s.ChartReference === chart.ChartConfigurationId &&
                  s.ChartReference !== viewConfigurationData.HistogramId,
              );
              selected.push(series[0].SignalId);
              seriesNames[series[0].SignalId] = chart.Caption;
              globalData.gridLines[series[0].SignalId] =
                conf.AreGridlinesVisible;
              if (series.length > 1) {
                // globalData.additionalLines[series[0].SignalId] = series[1].SignalId;
                // additionalLinesList = additionalLinesList.concat(series.slice(1).map((s) => s.SignalId));
              }
              sizes[series[0].SignalId] = conf.HeightPercentage;
            }
          });
          if (result.EventConfigurations) {
            if (
              result.EventConfigurations.find(
                (e) => e.Type === 'OximetryReciprocation',
              ) &&
              !result.EventConfigurations.find(
                (e) => e.Type === 'PulseReciprocation',
              )
            ) {
              const pulseEvent = result.EventConfigurations.find(
                (e) => e.Type === 'OximetryReciprocation',
              );
              result.EventConfigurations.push({
                ...pulseEvent,
                Type: 'PulseReciprocation',
              });
            }
            const eventPos = { ...eventTop };
            result.EventConfigurations.forEach((evConf) => {
              eventPos[evConf.Type] = 1 - evConf.VerticalPosition;
            });
            globalData.eventTop = eventPos;
            setEventTop(eventPos);
            allEventTypesList = result.EventConfigurations.map((e) => e.Type);
            globalData.allEventTypesList = allEventTypesList;
            allEventTypesList.forEach((key) => {
              if (!globalData.eventColors[key])
                globalData.eventColors[key] = getRandomDarkColor();
            });

            setVisibleEvents(
              result.EventConfigurations.filter((e) => e.Visible).map(
                (e) => e.Type,
              ),
            );
          }
          const colors = { ...globalData.chartColors };
          const allSignals = [];
          if (result.SeriesConfigurations) {
            const ranges = { ...chartRanges };
            result.SeriesConfigurations.forEach((series) => {
              if (result.HistogramId === series.ChartReference) {
                setHistogramColor(series.StrokeColor);
                return;
              }
              allSignals.push(series.SignalId);
              colors[series.SignalId] = series.StrokeColor;
              if (
                colors[series.SignalId].length === 9 &&
                colors[series.SignalId].endsWith('00')
              ) {
                colors[series.SignalId] = colors[series.SignalId].slice(0, 7);
              }
              ranges[series.SignalId] = {
                min: series.DefaultValueBottom,
                max: series.DefaultValueTop,
              };
              if (seriesNames[series.SignalId] === 'Pulse')
                globalData.pulseSignalId = series.SignalId;
              if (seriesNames[series.SignalId] === 'SPO2')
                globalData.spoSignalId = series.SignalId;
              if (!sizes[series.SignalId]) sizes[series.SignalId] = 1;
            });
            setChartRanges(ranges);
            globalData.initialRanges = ranges;
            globalData.chartRanges = ranges;
            globalData.chartColors = colors;
          }
          // sizes[1206] = 2;
          // sizes[1209] = 2;
          setChartSizes(sizes);

          selectedChartIds = selected;
          const zoomSignal = result.SeriesConfigurations.find(
            (s) => s.ChartReference === result.HistogramId,
          );
          if (zoomSignal && selectedChartIds.includes(zoomSignal.SignalId)) {
            setZoomSignalId(zoomSignal.SignalId);
            setHistogramColor(colors[zoomSignal.SignalId]);
            zoomId = zoomSignal.SignalId;
          } else {
            setZoomSignalId(selectedChartIds[0]);
            setHistogramColor(colors[selectedChartIds[0]]);
            zoomId = selectedChartIds[0];
          }
          loadStudySignals(allSignals);
        });
    },
    [loadStudySignals, chartRanges, eventTop],
  );

  const loadEvents = useCallback(
    (interval) => {
      if (!studySignals.length || dataLoading) return;
      let l = studySignals.length;
      const data = {};
      const artifacts = [];
      const additionalEventTypes = [];
      let preciseEventsList = [];
      const eventsTyped = {};
      studySignals.forEach((signal, i) => {
        if (globalData.loadedEventsData[signal.SignalId]) {
          data[signal.SignalId] = globalData.loadedEventsData[signal.SignalId];
          l--;
          if (l < 1) setEvents(data);
          return;
        }
        if (!['Airflow', 'SPO2', 'Pulse'].includes(signal.Type)) {
          globalData.loadedEventsData[signal.SignalId] = [];
          data[signal.SignalId] = [];
          l--;
          return;
        }
        try {
          var myHeaders = new Headers();
          myHeaders.append('Authorization', 'Bearer ' + globalData.token);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };
          fetch(
            `${host}/sleepstudy/api/signalsleepevents?signalId=${signal.SignalId}&startTime=${interval.StartTime}&endTime=${interval.EndTime}`,
            requestOptions,
          )
            .catch(function (error) {
              // catch
              globalData.loadedEventsData[signal.SignalId] = [];
              data[signal.SignalId] = [];
              l--;
            })
            .then((response) => (response.ok ? response.json() : []))
            .then((result) => {
              l--;
              const eventData = [];
              // try {
              //   window.localStorage.setItem("events" + studyId + "|" + signal.SignalId, JSON.stringify(result));
              // } catch {}
              if (result.length) eventSignalIds[signal.SignalId] = [];
              result.forEach((currentValue) => {
                if (currentValue.Type.includes('Artifact')) {
                  artifacts.push({
                    start:
                      new Date(currentValue.StartTime).getTime() -
                      dateOriginTime,
                    end:
                      new Date(currentValue.EndTime).getTime() - dateOriginTime,
                    signalId: currentValue.SignalId,
                    status: currentValue.IsAutoScored ? 'AutoScored' : 'Added',
                    id: artifactId,
                    ...currentValue,
                  });
                  artifactId++;
                  return;
                }
                const id = eventId;
                eventId++;
                if (!preciseEventsList.includes(currentValue.Type)) {
                  preciseEventsList.push(currentValue.Type);
                }
                if (!allEventTypesList.includes(currentValue.Type)) {
                  additionalEventTypes.push(currentValue.Type);
                  allEventTypesList.push(currentValue.Type);
                  globalData.eventColors[currentValue.Type] =
                    getRandomDarkColor();
                }
                if (
                  !eventSignalIds[signal.SignalId].includes(currentValue.Type)
                )
                  eventSignalIds[signal.SignalId].push(currentValue.Type);

                const startTimeMicro =
                  new Date(currentValue.StartTime).getTime() - dateOriginTime;
                const endTimeMicro =
                  new Date(currentValue.EndTime).getTime() - dateOriginTime;
                const searchName =
                  currentValue.Type === 'Recovery' ||
                  currentValue.Type === 'Desaturation'
                    ? (signal.Type === 'Pulse' ? 'Pulse ' : 'Oximetry ') +
                      currentValue.Type
                    : currentValue.Type;
                eventData.push({
                  status: currentValue.IsAutoScored ? 'AutoScored' : 'Added',
                  type: currentValue.Type.startsWith('Oximetry')
                    ? currentValue.Type
                    : currentValue.Type,
                  customCharacteristics: currentValue.CustomCharacteristics,
                  startTimeMicro,
                  endTimeMicro,
                  searchName,
                  customName:
                    signal.SignalId === globalData.pulseSignalId &&
                    (currentValue.Type === 'Recovery' ||
                      currentValue.Type === 'Desaturation') &&
                    currentValue.CustomCharacteristics['DPAR Event Type']
                      ? currentValue.CustomCharacteristics['DPAR Event Type']
                      : undefined,
                  ...currentValue,
                  id,
                });
                if (!eventsTyped[searchName]) eventsTyped[searchName] = [];
                eventsTyped[searchName].push({
                  startTimeMicro,
                  endTimeMicro,
                  id,
                });
                if (
                  signal.SignalId === globalData.spoSignalId &&
                  ['Desaturation', 'Recovery', 'CandidateEvent'].includes(
                    currentValue.Type,
                  )
                ) {
                  spoLabels.push(startTimeMicro);
                  spoLabels.push(endTimeMicro);
                }
                if (signal.SignalId === globalData.pulseSignalId) {
                  pulseLabels.push(startTimeMicro);
                  pulseLabels.push(endTimeMicro);
                }
              });

              // console.log(signal);
              // if (signal.Type === 'Airflow' || signal.Type === 'Snore') {
              //   getMaxValuesLine(signal.SignalId).then((data) => {
              //     if (data) {
              //       globalData.maxValuesLines[signal.SignalId] = Object.keys(
              //         data,
              //       ).map((d) => ({
              //         x: new Date(d).getTime() - globalData.dateOriginTime,
              //         y: data[d],
              //       }));
              //       redrawCharts = true;
              //     }
              //   });
              //   getMinValuesLine(signal.SignalId).then((data) => {
              //     if (data) {
              //       globalData.minValuesLines[signal.SignalId] = Object.keys(
              //         data,
              //       ).map((d) => ({
              //         x: new Date(d).getTime() - globalData.dateOriginTime,
              //         y: data[d],
              //       }));
              //       redrawCharts = true;
              //     }
              //   });
              // }
              globalData.loadedEventsData[signal.SignalId] = eventData;
              data[signal.SignalId] = eventData;

              if (l < 1) {
                allEventTypesList = preciseEventsList;
                globalData.allEventTypesList = allEventTypesList;
                if (additionalEventTypes.length) {
                  setVisibleEvents((e) => e.concat(additionalEventTypes));
                }
                [lineSteps, chartAreasData] = getLineSteps(
                  globalData.loadedEventsData,
                );
                setArtifacts(artifacts);
                setEvents(data);
                Object.keys(eventsTyped).forEach((key) => {
                  eventsTyped[key].sort(
                    (a, b) => a.startTimeMicro - b.startTimeMicro,
                  );
                });
                globalData.eventsTyped = eventsTyped;
                globalData.searchOptions = generateSearchOptions(eventsTyped);
              }
            });
        } catch {
          l--;
          globalData.loadedEventsData[signal.SignalId] = [];
          data[signal.SignalId] = [];
        }
      });
    },
    [dataLoading],
  );

  const getStudyInterval = useCallback(
    (studySignals) => {
      if (studySignals.length) {
        if (timeIntervalString) {
          return;
        }
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + globalData.token);
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };
        fetch(
          `${host}/sleepstudy/api/sleepstudy?key=${studyId}`,
          requestOptions,
        )
          .then((response) => response.json())
          .then((result) => {
            timeIntervalString = result;
            const newInterval = {
              start: new Date(result.StartTime),
              end: new Date(result.EndTime),
            };
            dateOriginTime = newInterval.start.getTime();
            dateEndTime = newInterval.end.getTime();
            dateOrigin = newInterval.start;
            globalData.dateOriginTime = dateOriginTime;
            globalData.dateEndTime = dateEndTime;

            setTimeInterval(newInterval);
            setDateOriginTime(newInterval.start.getTime());
            loadEvents(result);
            loadSignalsData(result);
            createEventChart();
          });
      }
    },
    [loadSignalsData, loadEvents, studyId, setDateOriginTime],
  );

  const updateChartsOrder = useCallback(
    (charts) => {
      studySignals = charts.map((i) => signalIds[i] || null).filter((s) => s);
      unusedSignals = [...Array(signalIds.length).keys()].filter(
        (i) => !charts.includes(i),
      );
      studySignals = charts.map((i) => signalIds[i] || null).filter((s) => s);
      selectedChartIds = studySignals.map((s) => s.SignalId);
      redrawCharts = true;
      setSelectedCharts(charts.slice(0));
      chartsDataUpdated = chartsDataUpdated + 1;
    },
    [setSelectedCharts, signalIds],
  );

  const setChartsSettings = useCallback(
    (chartsSettings) => {
      const newChartColors = { ...globalData.chartColors };
      const newChartRanges = { ...chartRanges };
      const signalId = chartsSettings.signalId;
      if (signalId) {
        if (chartsSettings.color) {
          newChartColors[signalId] = chartsSettings.color;
          globalData.chartColors = newChartColors;
        }
        if (chartsSettings.range) {
          newChartColors[signalId] = chartsSettings.range;
          setChartRanges(newChartRanges);
          globalData.chartRanges = newChartRanges;
        }
      }
      if (chartsSettings.charts) updateChartsOrder(chartsSettings.charts);
    },
    [chartRanges, updateChartsOrder],
  );

  const setEventsFromHistory = useCallback(
    (data, signalId) => {
      redrawCharts = true;
      globalData.loadedEventsData[signalId] = data;
      globalData.eventsUpdated = true;
      setEvents((events) => {
        return { ...events, [signalId]: data };
      });
    },
    [setEvents],
  );

  const [
    undoEvent,
    redoEvent,
    undoActive,
    redoActive,
    titles,
    removeHistory,
    addHistoryEvent,
  ] = useEventsHistory(
    setEventsFromHistory,
    updateChartsOrder,
    setChartsSettings,
    setVisibleEvents,
    setArtifacts,
  );
  //const addHistoryEvent = globalData.addHistoryEvent;

  let dashboardObject = useRef(null);
  let moveInterval = useRef(null);

  const loadStudySignals = useCallback(
    (allSignals) => {
      if (signalIds) {
        unusedSignals = [...Array(signalIds.length).keys()].filter(
          (i) => !selectedCharts.includes(i),
        );
        studySignals = selectedCharts
          .map((i) => signalIds[i] || null)
          .filter((s) => s);
        return;
      }
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + globalData.token);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(
        `${host}/sleepstudy/api/sleepstudysignals?key=${studyId}`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((result) => {
          result = result.filter(
            (r) => allSignals.includes(r.SignalId) && !r.Specification,
          );
          const ids = [];
          result.forEach((r, i) => {
            ids.push(r.SignalId);
            sampleRates[r.SignalId] = Math.min(
              r.OriginalSampleRate || 10,
              maxRate,
            );
            if (!signalPairs[r.SignalId]) {
              const s1 = r.Specification ? r.Specification + ' ' : '';
              const pair = result.find(
                (s) =>
                  s.SignalId !== r.SignalId &&
                  s.Type === r.Type &&
                  s.Specification === s1 + 'Transformed (Type 2)',
              );
              if (pair) {
                signalPairs[r.SignalId] = pair.SignalId;
                signalPairs[pair.SignalId] = r.SignalId;
              }
            }
            if (r.Type === 'Pulse' || r.Type === 'SPO2')
              globalData.gridLines[r.SignalId] = true;
            // if (r.Type === "Pulse") {
            //   getPulseTags(r.SignalId).then((data) => {
            //     if (data && data.length) {
            //       globalData.pulseTags = data.map((t) => ({
            //         start: new Date(t.StartTime).getTime() - globalData.dateOriginTime,
            //         end: new Date(t.EndTime).getTime() - globalData.dateOriginTime,
            //         ClusterType: t.ClusterType,
            //       }));
            //       //redrawCharts = true;
            //     }
            //   });
            // }
            result[i].FullType = r.Type + r.Specification;
            globalData.signalIcon[r.SignalId] = globalData.signalIcon[r.Type];
            if (!globalData.signalIcon[r.Type] && r.Type.includes('Artifact')) {
              globalData.signalIcon[r.SignalId] =
                globalData.signalIcon['Artifact'];
            }
          });

          selectedChartIds = selectedChartIds.filter((id) => ids.includes(id));

          signalIdsData = result;
          const selected = selectedCharts.length
            ? selectedCharts
            : selectedChartIds.map((i) =>
                result.findIndex((r) => r.SignalId === i),
              );
          setSelectedCharts(selected);
          setSignalIds(result);
          studySignals = selectedChartIds.map((id) =>
            result.find((s) => s.SignalId === id),
          );
          unusedSignals = [...Array(result.length).keys()].filter(
            (i) => !selected.includes(i),
          );
          getStudyInterval(studySignals);
        });
    },
    [selectedCharts, signalIds, studyId, getStudyInterval],
  );

  const addArtifact = useCallback(
    (start, end, signalId) => {
      const date = moment().format('DD.MM.Y hh:mm:ss');
      const newArtifact = {
        start,
        end,
        signalId,
        id: artifactId,
        added: true,
        Type: 'ExcludedArtifact',
        status: `Added by ${globalData.username} on ${date}`,
      };
      const crossedEvent = checkEventArtifactOverlaping(newArtifact);
      if (crossedEvent) {
        alert(crossedEvent.type + ' events cannot overlap Excluded Artifact');
        lastPolygonFigure.dispose();
        return;
      }
      const arts = globalData.artifacts.slice(0);
      arts.push(newArtifact);
      artifactId++;
      addHistoryEvent({
        signalId: signalId,
        prev: artifacts,
        next: arts,
        type: 'artifact',
        title: 'add artifact',
      });
      //redrawCharts = true;
      globalData.eventsUpdated = true;
      setArtifacts(arts);
      addArtifactActivate();
      if (lastPolygonFigure) lastPolygonFigure.dispose();
      globalData.addArtifactFunction(newArtifact, signalId);
    },
    [setArtifacts, artifacts, addHistoryEvent],
  );

  useEffect(() => {
    if (firstLoad) {
      if (configurationLoading) return;
      configurationLoading = true;
      setDataLoading(true);
      getToken()
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          globalData.token = data.access_token;
          loadViewConfiguration(studyId);
          firstLoad = false;
        });
    }
  }, [selectedCharts, loadStudySignals, studyId, loadViewConfiguration]);

  const drawPulseValues = useCallback(() => {
    if (
      globalData.pulseSignalId &&
      selectedChartIds.includes(globalData.pulseSignalId)
    ) {
      const signalId = globalData.pulseSignalId;
      const chart = chartsList[signalId];
      if (!chart) return;
      const signalData = signalsData.find((s) => s.SignalId === signalId);
      if (!signalData) return;

      pulseLabelsPoints.forEach((e) => {
        e.dispose();
      });
      pulseLabelsPoints = [];

      var SeriesMarkerBuilder = MarkerBuilders.XY.setPointMarker(
        UIBackgrounds.Circle,
      ).addStyler((marker) =>
        marker.setPointMarker((point) =>
          point
            .setSize({ x: 4, y: 4 })
            .setFillStyle(new SolidFill({ color: ColorHEX('#ab00f5') })),
        ),
      );
      pulseLabels.forEach((x) => {
        if (x < zoomIntervalGlobal.start || x > zoomIntervalGlobal.end) return;
        const p = signalData.data.find((t) => t.x >= x);
        if (p) {
          const chartMarker = chart
            .addChartMarkerXY(SeriesMarkerBuilder)
            .setPosition({ x, y: p.y });

          // Style ChartMarker.
          chartMarker
            .setResultTableVisibility(UIVisibilityModes.always)
            .setGridStrokeXVisibility(UIVisibilityModes.never)
            .setGridStrokeYVisibility(UIVisibilityModes.never)
            .setTickMarkerXVisibility(UIVisibilityModes.never)
            .setTickMarkerYVisibility(UIVisibilityModes.never)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setResultTable((table) =>
              table.setContent([[p.y.toString().slice(0, 4)]]),
            );

          pulseLabelsPoints.push(chartMarker);
        }
      });
    }

    if (
      !globalData.spoSignalId ||
      !selectedChartIds.includes(globalData.spoSignalId)
    )
      return;
    const signalId = globalData.spoSignalId;
    const chart = chartsList[signalId];
    if (!chart) return;
    const signalData = signalsData.find((s) => s.SignalId === signalId);
    if (!signalData) return;

    spoLabelPoints.forEach((e) => {
      e.dispose();
    });
    spoLabelPoints = [];

    SeriesMarkerBuilder = MarkerBuilders.XY.setPointMarker(
      UIBackgrounds.Circle,
    ).addStyler((marker) =>
      marker.setPointMarker((point) =>
        point
          .setSize({ x: 4, y: 4 })
          .setFillStyle(new SolidFill({ color: ColorHEX('#ab00f5') })),
      ),
    );
    let n = 0;
    spoLabels.forEach((x) => {
      if (x < zoomIntervalGlobal.start || x > zoomIntervalGlobal.end) return;
      const p = signalData.data.find((t) => t.x === x);
      if (p) {
        n++;
        const chartMarker = chart
          .addChartMarkerXY(SeriesMarkerBuilder)
          .setPosition({ x, y: p.y });

        // Style ChartMarker.
        chartMarker
          .setResultTableVisibility(UIVisibilityModes.always)
          .setGridStrokeXVisibility(UIVisibilityModes.never)
          .setGridStrokeYVisibility(UIVisibilityModes.never)
          .setTickMarkerXVisibility(UIVisibilityModes.never)
          .setTickMarkerYVisibility(UIVisibilityModes.never)
          .setDraggingMode(UIDraggingModes.notDraggable)
          .setResultTable((table) =>
            table.setContent([[p.y.toString().slice(0, 4)]]),
          );

        spoLabelPoints.push(chartMarker);
      }
    });
    if (n < 4) {
      const dist = zoomIntervalGlobal.end - zoomIntervalGlobal.start;
      const step = Math.floor(dist / 5);
      for (let i = 1; i < 5; i++) {
        const x = zoomIntervalGlobal.start + i * step;
        const p = signalData.data.find((t) => t.x === x);
        if (p) {
          n++;
          const chartMarker = chart
            .addChartMarkerXY(SeriesMarkerBuilder)
            .setPosition({ x, y: p.y });

          // Style ChartMarker.
          chartMarker
            .setResultTableVisibility(UIVisibilityModes.always)
            .setGridStrokeXVisibility(UIVisibilityModes.never)
            .setGridStrokeYVisibility(UIVisibilityModes.never)
            .setTickMarkerXVisibility(UIVisibilityModes.never)
            .setTickMarkerYVisibility(UIVisibilityModes.never)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setResultTable((table) =>
              table.setContent([[p.y.toString().slice(0, 4)]]),
            );

          spoLabelPoints.push(chartMarker);
        }
      }
    }
  }, []);

  const redrawEvents = useCallback(
    (signalId) => {
      if (!events) return;
      console.log('redraw');
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        updateEventsHeight(
          parseInt(signalId),
          globalData.chartRanges[signalId],
        );
      }, 300);
    },
    [updateEventsHeight, events],
  );

  const redrawEventstext = useCallback(() => {
    Object.values(globalData.allEventsList).forEach((figures) => {
      if (!figures.length || !figures[0].signalId) return;
      const chart = yAxisList[figures[0].signalId].chart;
      figures.forEach((f) => {
        const pixelLocation = translatePoint(
          // axis coordinate.
          { x: f.start, y: 1 },
          {
            x: chart.getDefaultAxisX(),
            y: chart.getDefaultAxisY(),
          },
          chart.pixelScale,
        );
        const pixelLocation2 = translatePoint(
          // axis coordinate.
          { x: f.end, y: 1 },
          {
            x: chart.getDefaultAxisX(),
            y: chart.getDefaultAxisY(),
          },
          chart.pixelScale,
        );
        const l =
          chart.engine.engineLocation2Client(pixelLocation2.x, pixelLocation2.y)
            .x -
          chart.engine.engineLocation2Client(pixelLocation.x, pixelLocation.y)
            .x;
        const text = f.text || f.type.replace('Oximetry', '');
        if (l / text.length < 7.5) {
          f.textFigure.setText(text.slice(0, Math.floor(l / 7.5)));
        } else {
          f.textFigure.setText(text);
        }
      });
    });
  }, []);

  const updateEventsHeight = useCallback(
    (signalId, range) => {
      const height = range.max - range.min;
      const h = (figureHeight * height) / 100;
      Object.values(globalData.allEventsList).forEach((figures) => {
        if (!figures.length) return;
        let diff = null;
        const top = globalData.eventTopCustom[signalId]
          ? globalData.eventTopCustom[signalId][figures[0].type]
          : eventTop[figures[0].type];
        figures.forEach((f) => {
          if (f.signalId !== signalId) return;
          const points = f.figure.getDimensions();
          if (points.length < 5) {
            return;
          }
          const y = (f.yPos || top) * height + range.min;
          f.figure.setDimensions([
            { x: points[0].x, y: y - h },
            { x: points[1].x, y: y - h },
            { x: points[2].x, y: y + h },
            { x: points[3].x, y: y + h },
            { x: points[4].x, y: y },
          ]);

          f.textFigure.setPosition({
            x: (points[4].x + points[1].x) / 2,
            y: y,
            z: 0,
          });

          if (f.resizeElementBack) {
            const points = f.resizeElementBack.getDimensions();
            f.resizeElementBack.setDimensions([
              { x: points[0].x, y: y + h },
              { x: points[1].x, y: y + h },
              { x: points[2].x, y: y - h },
              { x: points[3].x, y: y - h },
            ]);
          }
          if (f.resizeElementFront) {
            const points = f.resizeElementFront.getDimensions();
            f.resizeElementFront.setDimensions([
              { x: points[0].x, y: y + h / 3 },
              { x: points[1].x, y: y + h / 3 },
              { x: points[2].x, y: y - h / 3 },
              { x: points[3].x, y: y - h / 3 },
            ]);
          }
        });
      });
      if (globalData.sectorPolygons[signalId]) {
        globalData.sectorPolygons[signalId].forEach(({ pol, sector }) => {
          pol.setDimensions([
            { x: 0, y: range.min + height * sector.min },
            { x: dateEndTime, y: range.min + height * sector.min },
            { x: dateEndTime, y: range.min + height * sector.max },
            { x: 0, y: range.min + height * sector.max },
          ]);
        });
      }
    },
    [eventTop],
  );

  const setEventSizeUpdated = useCallback(
    (eventId, signalId, y, h) => {
      const event = globalData.loadedEventsData[signalId].find(
        (ev) => ev.id === eventId,
      );
      if (event)
        setEventSize({
          start: event.startTimeMicro,
          end: event.endTimeMicro,
          h,
          y,
        });
      else console.log('error', eventId, signalId);
    },
    [setEventSize],
  );

  const drawSlider = useCallback(
    (dashboard, signalId, signalData, color, rowSizeSum) => {
      if (zoomBandChart) {
        zoomBandChart.dispose();
      }
      const chartNumber = selectedChartIds.length;
      const topAxisSize = Math.floor((window.innerHeight - 170) / 50);
      const chart = chartsList[signalId];
      zoomBandChart = dashboard
        .createZoomBandChart({
          columnIndex: 0,
          columnSpan: 1,
          rowIndex: Math.floor(4 * (topAxisSize * rowSizeSum + chartNumber)),
          rowSpan: 8 * chartNumber,
          axis: chart
            .getDefaultAxisX()
            .setTickStrategy(AxisTickStrategies.DateTime),
        })
        .setTitle('');
      zoomBandChart.setPadding({
        left: 0,
      });
      zoomBandChart.band.setStrokeStyle(
        new SolidLine({
          thickness: 2,
          fillStyle: new SolidFill({ color: ColorRGBA(0, 255, 0) }),
        }),
      );
      zoomBandChart.band.setHighlighted(true);
      zoomBandChart.band.setValueStart(zoomIntervalGlobal.start);
      zoomBandChart.band.setValueEnd(zoomIntervalGlobal.end);
      const t2 = signalData.Type;
      zoomBandChart.setSeriesStyle((series) => {
        if (series.getName() === 'Area Range Series') return;
        series.chart
          .getDefaultAxisY()
          .setNibLength(30)
          .setTickStrategy(
            AxisTickStrategies.Numeric,
            (tickStrategy) =>
              tickStrategy
                .setMajorFormattingFunction((tickPosition) => {
                  return Math.round(tickPosition).toString();
                })
                .setMinorFormattingFunction((tickPosition) => {
                  return Math.round(tickPosition).toString();
                }),
            //.setMinorTickStyle((tickStyle) => tickStyle.setGridStrokeStyle(emptyLine))
          );
        series.setStrokeStyle(
          new SolidLine({
            thickness: 1.1,
            fillStyle: new SolidFill({ color: ColorHEX(color || '#FF0000') }),
          }),
        );
        if (t2 === 'SPO2') {
          zoomBandChart
            .getDefaultAxisY()
            .setMouseInteractions(true)
            .setInterval(minSpo2Value, 100, true, true);
          zoomBandChart
            .getDefaultAxisY()
            .addCustomTick(UIElementBuilders.AxisTick)
            .setValue(minSpo2Value)
            // Label text is specified with a callback function.
            // This example formats Axis positions with one fraction, like this: "5.0"
            .setTextFormatter((value) => minSpo2Value.toString());

          series.axisY
            .addConstantLine()
            .setValue(90)
            .setStrokeStyle(
              new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorHEX('#50505010') }),
              }),
            );
          series.axisY
            .addConstantLine()
            .setValue(70)
            .setStrokeStyle(
              new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorHEX('#50505010') }),
              }),
            );
          series.axisY
            .addConstantLine()
            .setValue(85)
            .setStrokeStyle(
              new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorHEX('#50505010') }),
              }),
            );
          series.axisY
            .addConstantLine()
            .setValue(75)
            .setStrokeStyle(
              new SolidLine({
                thickness: 1,
                fillStyle: new SolidFill({ color: ColorHEX('#50505010') }),
              }),
            );
        }
      });
    },
    [],
  );

  const drawSignal = useCallback(
    (
      redraw,
      index,
      singalIndex,
      signalData,
      rowIndex,
      topAxisSize,
      rowSizeSum,
    ) => {
      const signalId = signalData.SignalId;
      manualEvents[signalId] = [];
      const ranges = globalData.chartRanges;
      manualEventTypes[signalId] = defaultEventSelectValues[signalId] || [];

      const dashboard = dashboardObject.current;
      if (redraw) {
        const chart = dashboard
          .createChartXY({
            columnIndex: 0,
            columnSpan: 1,
            rowIndex: rowIndex,
            rowSpan: Math.floor(4 * topAxisSize * chartSizes[signalId]),
            theme: myTheme,
          })
          .setTitleFont((font) => font.setSize(10))
          .setPadding({
            right: 10,
            left: 0,
            top: 1,
            bottom: 1,
            //bottom: i === 0 ? 0 : -30,
          })
          .setMouseInteractionRectangleZoom(false)
          .setMouseInteractionRectangleFit(false)
          .setTitle('')
          .setTitleFillStyle(emptyFill)
          .setTitleMarginTop(0)
          .setTitleMarginBottom(-20)
          .setBackgroundStrokeStyle(emptyLine)
          .setFittingRectangleStrokeStyle(fittingRectangleStrokeStyle)
          .setZoomingRectangleFillStyle(zoomingRectangleFillStyle)
          .setMouseInteractionWheelZoom(false);
        chartsList[signalId] = chart;
        rowIndex += Math.floor(4 * topAxisSize * chartSizes[signalId]);
        chart.setAutoCursor((cursor) => {
          cursor
            .setResultTableAutoTextStyle(true)
            .disposeTickMarkerX()
            .setTickMarkerXAutoTextStyle(false)
            .setTickMarkerYAutoTextStyle(false);
        });
        chart.onBackgroundMouseLeave((t, e) => {
          setHoverElement(null);
        });
        //chart.setBackgroundFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 100) }));
        xAxisList[index] = chart
          .getDefaultAxisX()
          .setOverlayStyle(axisXStyleHighlight)
          .setNibOverlayStyle(axisXStyleHighlight)
          .setScrollStrategy(undefined)
          .setThickness(0)
          .setNibLength(0)
          .setNibStyle(emptyLine)
          .setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
            dateTimeTicks
              .setDateOrigin(dateOrigin)
              .setGreatTickStyle(emptyTick)
              .setMajorTickStyle((tickStyle) =>
                globalData.gridLines[signalId]
                  ? tickStyle
                      .setLabelFont((font) => font.setSize(0))
                      .setTickLength(0)
                  : tickStyle
                      .setGridStrokeStyle(emptyLine)
                      .setLabelFont((font) => font.setSize(0))
                      .setTickLength(0),
              )
              .setMinorTickStyle((tickStyle) =>
                globalData.gridLines[signalId]
                  ? tickStyle
                      .setLabelFont((font) => font.setSize(0))
                      .setTickLength(0)
                  : tickStyle
                      .setGridStrokeStyle(emptyLine)
                      .setLabelFont((font) => font.setSize(0))
                      .setTickLength(0),
              ),
          )
          .setAnimationScroll(false);
        const axisY = chart
          .getDefaultAxisY()
          .setStrokeStyle(axisYStrokeStyles[0])
          .setTitle('')
          .setOverlayStyle(axisYStylesHighlight[0])
          .setNibOverlayStyle(axisYStylesHighlight[0])
          .setInterval(0, 100)
          .setNibLength(10)
          .setTitleFont(
            new FontSettings({
              size: 11,
              family: 'Arial, Helvetica, sans-serif',
              weight: 'bold',
              style: 'italic',
            }),
          )
          .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
            tickStrategy
              .setMajorFormattingFunction((tickPosition) => {
                return NoYLabelSignals.includes(signalData.Type)
                  ? ''
                  : Math.round(tickPosition).toString();
              })
              .setMinorFormattingFunction((tickPosition) => {
                return NoYLabelSignals.includes(signalData.Type)
                  ? ''
                  : Math.round(tickPosition).toString();
              })
              .setMajorTickStyle((tickStyle) =>
                globalData.gridLines[signalId]
                  ? tickStyle
                  : tickStyle.setGridStrokeStyle(emptyLine),
              )
              .setMinorTickStyle((tickStyle) =>
                globalData.gridLines[signalId]
                  ? tickStyle
                  : tickStyle.setGridStrokeStyle(emptyLine),
              ),
          )
          .setScrollStrategy(AxisScrollStrategies.regressive);
        yAxisList[signalId] = axisY;
      }
      const chart = chartsList[signalId];
      const axisY = yAxisList[signalId];
      chart.addPolygonSeries().clear();
      axisY
        .addCustomTick()
        .setTickLength(60)
        .setTextFormatter((position, customTick) => '');
      const splineSeries = chart.addLineSeries({
        xAxis: xAxisList[index],
        yAxis: axisY,
        pointShape: PointShape.Circle,
        dataPattern: {
          pattern: 'ProgressiveX',
          regularProgressiveStep: true,
        },
      });
      //splineSeries.setMaxPointCount(100);

      // .setDrawOrder({ seriesDrawOrderIndex: 11 });
      //splineSeries1.setPointSize(2);
      splineSeries.setCursorResultTableFormatter(
        (tableBuilder, series, x, y) => {
          const d = moment(new Date(x + dateOrigin.getTime()))
            .utcOffset(0, true)
            .format()
            .replace('T', ' ')
            .slice(0, 19);
          return (
            tableBuilder
              .addRow(signalData.name)
              //.addRow("Time1: " + x)
              .addRow('Time: ' + d)
              .addRow(
                ['SPO2', 'Pulse', 'Snore'].includes(signalData.name)
                  ? 'Value: ' + y.toFixed(2)
                  : undefined,
              )
          );
        },
      );
      splineSeries.setStrokeStyle(
        new SolidLine({
          thickness: 2,
          fillStyle: new SolidFill({
            color: ColorHEX(globalData.chartColors[signalId] || '#FF0000'),
          }),
        }),
      );

      if (globalData.maxValuesLines[signalId]) {
        const maxValuesLine = chart.addLineSeries({
          xAxis: xAxisList[index],
          yAxis: axisY,
          pointShape: PointShape.Circle,
          dataPattern: {
            pattern: 'ProgressiveX',
            regularProgressiveStep: true,
          },
        });
        maxValuesLine.setMaxPointCount(1000);
        maxValuesLine.add(globalData.maxValuesLines[signalId]);
        maxValuesLine.setCursorEnabled(false);
      }
      if (globalData.minValuesLines[signalId]) {
        const minValuesLine = chart.addLineSeries({
          xAxis: xAxisList[index],
          yAxis: axisY,
          pointShape: PointShape.Circle,
          dataPattern: {
            pattern: 'ProgressiveX',
            regularProgressiveStep: true,
          },
        });
        minValuesLine.setMaxPointCount(1000);
        minValuesLine.add(globalData.minValuesLines[signalId]);
        minValuesLine.setCursorEnabled(false);
      }
      signalChartsList[signalId] = splineSeries;
      if (globalData.additionalLines[signalId]) {
        const id = globalData.additionalLines[signalId];
        const splineSeries = chart.addLineSeries({
          xAxis: xAxisList[index],
          yAxis: axisY,
          pointShape: PointShape.Circle,
          dataPattern: {
            pattern: 'ProgressiveX',
            regularProgressiveStep: true,
          },
        });
        splineSeries
          .setCursorResultTableFormatter((tableBuilder, series, x, y) => {
            const d = moment(new Date(x + dateOrigin.getTime()))
              .utcOffset(0, true)
              .format()
              .replace('T', ' ')
              .slice(0, 19);
            return tableBuilder
              .addRow(globalData.loadedChartsData[id].name)
              .addRow('Time: ' + d)
              .addRow('Value: ' + y.toFixed(2));
          })
          .setStrokeStyle(
            new SolidLine({
              thickness: 2,
              fillStyle: new SolidFill({
                color: ColorHEX(globalData.chartColors[id] || '#FF0000'),
              }),
            }),
          );
        signalChartsList[id] = splineSeries;
        splineSeries.add(globalData.loadedChartsData[id].data);
      }

      if (zoomIdActual === signalId) {
        drawSlider(
          dashboard,
          zoomIdActual,
          signalData,
          globalData.chartColors[signalId],
          rowSizeSum,
        );
      }
      splineSeries.add(signalData.data);
      signalLines[signalId] = splineSeries;

      if (!ranges[signalId]) {
        const min = splineSeries.getYMin() - 30;
        const max = splineSeries.getYMax() + 50;
        axisY.setInterval(min, max, true, true);
        // ranges[signalId] = { min: min, max: max };
        autoChartRanges[signalId] = { min: min, max: max };
      } else {
        axisY.setInterval(
          ranges[signalId].min,
          ranges[signalId].max,
          true,
          true,
        );
      }

      if (signalId === globalData.pulseSignalId && globalData.pulseTags) {
        drawPulseTags(chart);
        axisY.onScaleChange((start, end) => {
          globalData.chartRanges[signalId] = { min: start, max: end };
          redrawEvents(signalId);
          drawPulseTags(chart, start, end);
        });
      } else {
        axisY.onScaleChange((start, end) => {
          globalData.chartRanges[signalId] = { min: start, max: end };
          redrawEvents(signalId);
        });
      }

      const height = ranges[signalId].max - ranges[signalId].min;
      if (eventSectors[signalData.name] && redraw) {
        globalData.sectorPolygons[signalId] = [];
        eventSectors[signalData.name].forEach((sector, i) => {
          const pol = chart
            .addPolygonSeries()
            .add([
              { x: 0, y: ranges[signalId].min + height * sector.min },
              { x: dateEndTime, y: ranges[signalId].min + height * sector.min },
              { x: dateEndTime, y: ranges[signalId].min + height * sector.max },
              { x: 0, y: ranges[signalId].min + height * sector.max },
            ])
            .setMouseInteractions(false);
          pol
            .setFillStyle(
              new SolidFill({ color: ColorHEX(sector.color + '50') }),
            )
            .setStrokeStyle(strokeStyle2);
          let startPoint = null;
          pol.onMouseDragStart((_, event, button) => {
            lastPolygonFigure = null;
            if (
              button !== 0 ||
              (!window.eventDrawing && !window.artifactDrawing)
            )
              return;
            globalData.lastTextElement = null;
            const curLocationAxis = translatePoint(
              chart.engine.clientLocation2Engine(event.clientX, event.clientY),
              chart.engine.scale,
              splineSeries.scale,
            );
            startPoint = curLocationAxis;
          });
          pol.onMouseDrag((_, event, button) => {
            if (
              button !== 0 ||
              (!window.eventDrawing && !window.artifactDrawing)
            )
              return;
            const curLocationAxis = translatePoint(
              chart.engine.clientLocation2Engine(event.clientX, event.clientY),
              chart.engine.scale,
              splineSeries.scale,
            );
            if (globalData.lastTextElement)
              globalData.lastTextElement.dispose();
            if (lastPolygonFigure) lastPolygonFigure.dispose();
            const mouseOnRightSide = curLocationAxis.x > startPoint.x;
            setHoverElement({
              position: { x: event.pageX, y: event.pageY },
              text: 'Artifact',
              size: {
                start: Math.min(curLocationAxis.x, startPoint.x),
                end: Math.max(curLocationAxis.x, startPoint.x),
              },
              isEventDrawing: true,
              onRight: mouseOnRightSide,
            });
            if (window.artifactDrawing) {
              const y = (startPoint.y + curLocationAxis.y) / 2;
              const h = height * 0.95;
              if (mouseOnRightSide)
                lastPolygonFigure = drawEventFigure(
                  chart,
                  axisY,
                  xAxisList[index],
                  splineSeries.scale,
                  y,
                  '',
                  startPoint.x,
                  curLocationAxis.x,
                  h,
                  true,
                )
                  .setFillStyle(
                    new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }),
                  )
                  .setStrokeStyle(strokeStyle);
              else
                lastPolygonFigure = drawEventFigure(
                  chart,
                  axisY,
                  xAxisList[index],
                  splineSeries.scale,
                  y,
                  '',
                  curLocationAxis.x,
                  startPoint.x,
                  h,
                  true,
                )
                  .setFillStyle(
                    new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }),
                  )
                  .setStrokeStyle(strokeStyle);
              return;
            }

            const y = (startPoint.y + curLocationAxis.y) / 2;
            const h = (figureHeight * height) / 100;
            if (mouseOnRightSide)
              lastPolygonFigure = drawPolygonEvent(
                chart,
                y,
                signalId,
                sector,
                startPoint.x,
                curLocationAxis.x,
                h,
              )
                .setFillStyle(fillStyle)
                .setStrokeStyle(strokeStyle);
            else
              lastPolygonFigure = drawPolygonEvent(
                chart,
                y,
                signalId,
                sector,
                curLocationAxis.x,
                startPoint.x,
                h,
              )
                .setFillStyle(fillStyle)
                .setStrokeStyle(strokeStyle);
          });

          pol.onMouseDragStop((_, event, button) => {
            if (
              button.button !== 0 ||
              (!window.eventDrawing && !window.artifactDrawing)
            )
              return;
            const curLocationAxis = translatePoint(
              chart.engine.clientLocation2Engine(event.clientX, event.clientY),
              chart.engine.scale,
              splineSeries.scale,
            );
            let start = startPoint.x;
            let end = curLocationAxis.x;
            if (curLocationAxis.x < startPoint.x) {
              end = startPoint.x;
              start = curLocationAxis.x;
            }
            setHoverElement(null);
            if (lastPolygonFigure) lastPolygonFigure.dispose();
            if (window.artifactDrawing) {
              addArtifact(start, end, signalId);
              return;
            }
            addPolygonEvent(
              signalId,
              (sector.min + sector.max) / 2,
              start,
              end,
              sector.type,
            );
          });
          globalData.sectorPolygons[signalId].push({ pol, sector });
        });
      }

      //  const categories = events[signalId].map((t) => addCategory(t.y));
      //const y = 0.5 * height + ranges[signalId].min;
      // const waterMark = chart
      //   .addUIElement(UIElementBuilders.TextBox, { x: xAxisList[index], y: axisY })
      //   .setText("WK")
      //   .setDraggingMode(lcjs.UIDraggingModes.notDraggable)
      //   .setPosition({ x: 100, y })
      //   .setBackground((bg) => bg.setFillStyle(emptyFill).setStrokeStyle(emptyLine))
      //   .setTextFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 100) }));

      // xAxisList[index].onScaleChange((start, end) => {
      //   waterMark.setPosition({ x: (start + end) / 2, y });
      // });

      globalData.addArtifactFunction = (artifact, signalId) => {
        const chart = chartsList[signalId];
        const height = ranges[signalId].max - ranges[signalId].min;
        const figure = drawEventFigure(
          chart,
          yAxisList[signalId],
          chartsList[signalId].getDefaultAxisX(),
          signalLines[signalId].scale,
          ranges[signalId].min,
          '',
          artifact.start,
          artifact.end,
          height,
          true,
        )
          .setFillStyle(
            new SolidFill({
              color: artifactColors[artifact.Type] || ColorRGBA(0, 0, 0, 80),
            }),
          )
          .setStrokeStyle(strokeStyle);
        const mouseMoveEvent = (t, e) => {
          setHoverElement({
            position: { x: e.pageX, y: e.pageY },
            text: 'Artifact',
            size: { start: artifact.start, end: artifact.end },
            isArtifact: true,
            type: artifact.Type,
            status: artifact.status,
          });
          splineSeries.setCursorEnabled(false);
        };
        figure.onMouseMove(mouseMoveEvent);

        if (artifact.Type === 'ExcludedArtifact') {
          const [resizeElementFront, resizeElementBack] = addResizers(
            chart,
            true,
            artifacts,
            (a) => {
              redrawCharts = true;
              globalData.eventsUpdated = true;
              setArtifacts(a);
            },
            artifact.start,
            artifact.end,
            ranges[signalId].min,
            height,
            signalLines[signalId].scale,
            signalId,
            '',
            artifact.id,
            figure,
            zoomIntervalGlobal,
            addHistoryEvent,
          );
          globalData.artifactsList[artifact.id] = {
            resizeElementFront,
            resizeElementBack,
            figure,
          };
          addArtifactMoving(
            artifact,
            setArtifacts,
            figure,
            chart,
            splineSeries,
            setSelectedArtifact,
            addHistoryEvent,
          );
        }
      };

      artifacts
        .filter((a) => a.signalId === signalId)
        .forEach((artifact) => {
          globalData.addArtifactFunction(artifact, signalId);
        });

      const eventTopCustom = {
        ...eventTop,
        ...globalData.eventTopCustom[signalId],
      };

      globalData.drawEventFunction[signalId] = (event, signalId) => {
        if (event.removed) return;
        const start = event.startTimeMicro;
        const end = event.endTimeMicro;
        const type = event.Type;
        if (
          type !== 'CandidateEvent' &&
          checkArtifactOverlaping(start, end, signalId, artifacts)
        )
          return;
        const range = globalData.chartRanges[signalId];
        const height = range.max - range.min;
        const y =
          (event.yPos || eventTopCustom[event.type]) * height + range.min;
        const h = (figureHeight * height) / 100;

        const text = event.customName || type;
        lastPolygonFigure = drawEventFigure(
          chartsList[signalId],
          yAxisList[signalId],
          chartsList[signalId].getDefaultAxisX(),
          signalLines[signalId].scale,
          y,
          text,
          start,
          end,
          h,
        )
          .setFillStyle(
            new SolidFill({
              color: ColorHEX(globalData.eventColors[type] || '#000000a0'),
            }),
          )
          .setStrokeStyle(strokeStyle);
        const t = globalData.lastTextElement;
        visibleEventTextElements.push(t);
        const t2 = lastPolygonFigure;
        visibleEventPolygons.push(lastPolygonFigure);
        const size = { start, end, h, y };
        const id = event.id;
        let [resizeElementFront, resizeElementBack] = [null, null];

        if (!event.customName) {
          [resizeElementFront, resizeElementBack] = addResizers(
            chart,
            false,
            events,
            setEvents,
            start,
            end,
            y,
            h,
            splineSeries.scale,
            signalId,
            type,
            id,
            t2,
            zoomIntervalGlobal,
            addHistoryEvent,
          );
          resizeElements.push(resizeElementFront);
          resizeElements.push(resizeElementBack);
        }

        if (event.id === searchedEventId) {
          t2.setStrokeStyle(
            new SolidLine({
              fillStyle: new SolidFill({ color: ColorHEX('#ffc107') }),
              thickness: 3,
            }),
          );
          setTimeout(() => {
            t2.setStrokeStyle(
              new SolidLine({
                fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0, 241) }),
                thickness: 1,
              }),
            );
          }, [2000]);
        }

        const click = (_, e) => {
          if (eventPressed && Math.abs(e.clientX - eventPressed.clientX) > 2) {
            const newPosition = translatePoint(
              chart.engine.clientLocation2Engine(e.clientX, e.clientY),
              chart.engine.scale,
              splineSeries.scale,
            );
            const diff = newPosition.x - eventPressed.position.x;
            //moveEvent(id, signalId, type, diff, diff, eventPressed.points, t2, "", chart);
            moveEvent(
              id,
              signalId,
              events,
              setEvents,
              type,
              diff,
              diff,
              eventPressed.points,
              t2,
              '',
              chart,
              addHistoryEvent,
            );

            eventPressed = null;
            return;
          }
          globalData.lastTextElement = t;
          lastPolygonFigure = t2;

          setEventSizeUpdated(id, signalId, y, h);
          setSelectedEvent({
            text: type,
            newText: type,
            id,
            signalId,
            added: event.added,
            customCharacteristics: event.customCharacteristics,
          });
          setEventEditMode(true);
          setModalOpen(signalId);
          setHoverElement(null);
        };
        if (!event.customName) lastPolygonFigure.onMouseClick(click);
        const mouseMoveEvent = (t, e) => {
          if (eventPressed) {
            hideEventHoverChart();
            setHoverElement(null);

            return;
          }
          if (
            !globalData.eventChartData ||
            globalData.eventChartData.eventId !== id
          ) {
            globalData.eventChartData = geteventChartData(
              signalId,
              id,
              start,
              end,
            );

            splineSeries.setCursorEnabled(false);
          }
          setHoverElement({
            position: { x: e.pageX, y: e.pageY },
            text,
            size,
            status: event.status,
            customCharacteristics: event.customCharacteristics,
          });
        };
        lastPolygonFigure.onMouseMove(mouseMoveEvent);
        t2.onMouseLeave((t, e) => {
          if (e) {
            const curLocationAxis = translatePoint(
              chart.engine.clientLocation2Engine(e.clientX, e.clientY),
              chart.engine.scale,
              splineSeries.scale,
            );
            if (
              curLocationAxis.x >= start &&
              curLocationAxis.x <= end &&
              Math.abs(curLocationAxis.y - y) <= size.h
            )
              return;
          }
          globalData.eventChartData = null;
          setHoverElement(null);
          splineSeries.setCursorEnabled(true);
        });
        if (!event.customName)
          lastPolygonFigure.onMouseDragStart((t, e) => {
            eventPressed = {
              clientX: e.clientX,
              position: translatePoint(
                chart.engine.clientLocation2Engine(e.clientX, e.clientY),
                chart.engine.scale,
                splineSeries.scale,
              ),
              points: t2.getDimensions(),
            };
            hideEventHoverChart();
            setHoverElement(null);
          });
        if (!event.customName)
          lastPolygonFigure.onMouseDrag((t, e) => {
            if (eventPressed) {
              const newPosition = translatePoint(
                chart.engine.clientLocation2Engine(e.clientX, e.clientY),
                chart.engine.scale,
                splineSeries.scale,
              );
              const diff = newPosition.x - eventPressed.position.x;
              t2.setDimensions(
                eventPressed.points.map((p) => ({ x: p.x + diff, y: p.y })),
              );
              globalData.setHoverElement({
                position: { x: e.pageX, y: e.pageY },
                size: {
                  start: event.startTimeMicro + diff,
                  end: event.endTimeMicro + diff,
                },
                isEventDrawing: true,
                onRight: false,
              });
            }
          });

        if (!manualEventTypes[signalId].includes(type))
          manualEventTypes[signalId].push(type);
        if (!globalData.allEventsList[type])
          globalData.allEventsList[type] = [];
        globalData.allEventsList[type].push({
          type,
          text,
          singalIndex,
          start,
          end,
          figure: lastPolygonFigure,
          resizeElementFront,
          resizeElementBack,
          textFigure: t,
          id,
          signalId,
        });
      };
      if (events[signalId] && events[signalId].length) {
        events[signalId].forEach((event) => {
          if (event.startTimeMicro > zoomIntervalGlobal.end) return;

          globalData.drawEventFunction[signalId](event, signalId);
        });
        let startPoint = null;
        chart.onSeriesBackgroundMouseDragStart((_, event, button) => {
          lastPolygonFigure = null;
          if (button !== 0 || (!window.eventDrawing && !window.artifactDrawing))
            return;
          globalData.lastTextElement = null;
          const curLocationAxis = translatePoint(
            chart.engine.clientLocation2Engine(event.clientX, event.clientY),
            chart.engine.scale,
            splineSeries.scale,
          );
          startPoint = curLocationAxis;
        });
        chart.onSeriesBackgroundMouseDrag((_, event, button) => {
          if (button !== 0 || (!window.eventDrawing && !window.artifactDrawing))
            return;
          const curLocationAxis = translatePoint(
            chart.engine.clientLocation2Engine(event.clientX, event.clientY),
            chart.engine.scale,
            splineSeries.scale,
          );
          if (globalData.lastTextElement) globalData.lastTextElement.dispose();
          if (lastPolygonFigure) lastPolygonFigure.dispose();
          const mouseOnRightSide = curLocationAxis.x > startPoint.x;
          setHoverElement({
            position: { x: event.pageX, y: event.pageY },
            text: 'Artifact',
            size: {
              start: Math.min(curLocationAxis.x, startPoint.x),
              end: Math.max(curLocationAxis.x, startPoint.x),
            },
            isEventDrawing: true,
            onRight: mouseOnRightSide,
          });
          if (window.artifactDrawing) {
            const y = (startPoint.y + curLocationAxis.y) / 2;
            const h = height * 0.95;
            if (mouseOnRightSide)
              lastPolygonFigure = drawEventFigure(
                chart,
                axisY,
                xAxisList[index],
                splineSeries.scale,
                y,
                '',
                startPoint.x,
                curLocationAxis.x,
                h,
                true,
              )
                .setFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }))
                .setStrokeStyle(strokeStyle);
            else
              lastPolygonFigure = drawEventFigure(
                chart,
                axisY,
                xAxisList[index],
                splineSeries.scale,
                y,
                '',
                curLocationAxis.x,
                startPoint.x,
                h,
                true,
              )
                .setFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 80) }))
                .setStrokeStyle(strokeStyle);
            return;
          }

          const y = (startPoint.y + curLocationAxis.y) / 2;
          const h = (figureHeight * height) / 100;
          if (mouseOnRightSide)
            lastPolygonFigure = drawEventFigure(
              chart,
              axisY,
              xAxisList[index],
              splineSeries.scale,
              y,
              '',
              startPoint.x,
              curLocationAxis.x,
              h,
              true,
            )
              .setFillStyle(fillStyle)
              .setStrokeStyle(strokeStyle);
          else
            lastPolygonFigure = drawEventFigure(
              chart,
              axisY,
              xAxisList[index],
              splineSeries.scale,
              y,
              '',
              curLocationAxis.x,
              startPoint.x,
              h,
              true,
            )
              .setFillStyle(fillStyle)
              .setStrokeStyle(strokeStyle);
        });

        chart.onSeriesBackgroundMouseDragStop((_, event, button) => {
          if (button !== 0 || (!window.eventDrawing && !window.artifactDrawing))
            return;
          const curLocationAxis = translatePoint(
            chart.engine.clientLocation2Engine(event.clientX, event.clientY),
            chart.engine.scale,
            splineSeries.scale,
          );
          const y = (startPoint.y + curLocationAxis.y) / 2;
          let start = startPoint.x;
          let end = curLocationAxis.x;

          if (curLocationAxis.x < startPoint.x) {
            end = startPoint.x;
            start = curLocationAxis.x;
          }
          setHoverElement(null);
          if (lastPolygonFigure) lastPolygonFigure.dispose();
          lastPolygonFigure = drawEventFigure(
            chart,
            axisY,
            xAxisList[index],
            splineSeries.scale,
            y,
            '',
            start,
            end,
            window.artifactDrawing ? height : (figureHeight * height) / 100,
          )
            .setFillStyle(fillStyle)
            .setStrokeStyle(strokeStyle);
          if (window.artifactDrawing) {
            addArtifact(start, end, signalId);
            return;
          }
          tempEvent = {
            singalIndex,
            start,
            end,
            figure: lastPolygonFigure,
          };
          setEventSize({ start, end, figureHeight, y });
          setModalOpen(signalId);
          setEventEditMode(false);
        });
      }
      if (lineSteps[signalId] && lineSteps[signalId].length > 1) {
        // lineSteps[signalId].sort((a, b) => a.value - b.value);
        splineSeries.setStrokeStyle(
          new SolidLine({
            thickness: 2,
            fillStyle: new PalettedFill({
              lookUpProperty: 'x',
              lut: new LUT({
                interpolate: false,
                steps: lineSteps[signalId],
              }),
            }),
          }),
        );
      }

      if (chartAreasData[signalId]) {
        const areaSeries = chart.addAreaRangeSeries({
          xAxis: xAxisList[signalId],
        });
        if (signalId === globalData.pulseSignalId) {
          areaSeries.setHighFillStyle(
            new SolidFill({ color: ColorHEX('#07a007aa') }),
          );
          areaSeries.setLowFillStyle(
            new SolidFill({ color: ColorHEX('#57a057aa') }),
          );
          areaSeries.setHighStrokeStyle(
            new SolidFill({ color: ColorHEX('#07a007aa') }),
          );
        } else {
          areaSeries.setHighFillStyle(
            new SolidFill({ color: ColorHEX('#bad6f2e0') }),
          );
          areaSeries.setLowFillStyle(
            new SolidFill({ color: ColorHEX('#bad6f2e0') }),
          );
          areaSeries.setHighStrokeStyle(
            new SolidFill({ color: ColorHEX('#bad6f2e0') }),
          );
        }
        //areaSeries.setMaxPointCount(100);
        areaSeries.setCursorEnabled(false);
        areaSeries.setLowStrokeStyle(transparentLine);
        areaSeries.add(chartAreasData[signalId]);
      }
      //synchronizeAxisIntervals(...xAxisList, firstAxis);

      return rowIndex;
    },
    [
      addArtifact,
      addPolygonEvent,
      artifacts,
      chartSizes,
      drawSlider,
      eventTop,
      addHistoryEvent,
      events,
      setEventSizeUpdated,
      setHoverElement,
    ],
  );

  const redrawSignal = useCallback(
    (signalId, redraw = false) => {
      chartDrawing = true;
      const data = chartSignalDrawData[signalId];
      // chartSignalDrawData[signalData.signalId] = {
      //   index,
      //   singalIndex,
      //   rowIndex,
      //   topAxisSize,
      //   rowSizeSum,
      // };
      const signalData = signalsData.find((s) => s.SignalId === signalId);
      const mainData = chartSignalDrawData.main;
      drawSignal(
        redraw,
        data.index,
        data.singalIndex,
        signalData,
        data.rowIndex,
        mainData.topAxisSize,
        mainData.rowSizeSum,
      );
      chartDrawing = false;
    },
    [drawSignal],
  );

  const loadBigSignals = useCallback(
    (signalsToLoad) => {
      let l = signalsToLoad.length;
      const newRanges = { ...chartRanges };
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + globalData.token);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      signalsToLoad.forEach((s) => {
        fetch(
          `${host}/sleepstudy/api/signalassinglesegment?SampleRate=${s.rate}&signalId=${s.signalId}&startTime=${timeIntervalString.StartTime}&endTime=${timeIntervalString.EndTime}`,
          requestOptions,
        )
          .then((response) => {
            return response.json();
          })
          .then((signal) => {
            const i = s.i;
            const signalId = s.signalId;
            const signalObject = signalIdsData[i];
            const rate = s.rate;
            const chartData = {
              i,
              SignalId: signalObject.SignalId,
              data: signal.Points.map((point, i) => ({
                x: (i * 1000) / rate,
                y: point,
              })),
              Type: signalObject.Type,
              name:
                signalObject.Type +
                (signalObject.Specification
                  ? ' ' + signalObject.Specification
                  : ''),
              Specification: signalObject.Specification,
              rate: signal.SampleRate,
            };
            // if (chartData.Type === "Pulse") chartData.data = interpolatePoints(chartData.data);
            signalsData[i] = chartData; //.sort((a, b) => a.i - b.i);
            globalData.loadedChartsData[signalId] = chartData;
            if (signalChartsList[signalId]) {
              signalChartsList[signalId].clear();
              signalChartsList[signalId].add(chartData.data);
            } else {
              console.log('error', signalId);
            }
            if (signalObject.Type === 'SPO2') {
              if (!minSpo2Value) {
                let min = 80;
                chartData.data.forEach((p) => {
                  if (p.y < min && p.y > 75) min = p.y;
                });
                minSpo2Value = Math.floor(min / 10) * 10;
                minSpo2Value = minSpo2Value < 75 ? 75 : minSpo2Value;
                if (zoomBandChart)
                  zoomBandChart
                    .getDefaultAxisY()
                    .setMouseInteractions(true)
                    .setInterval(minSpo2Value, 100, true, true);
              }

              const autoRange = { min: minSpo2Value, max: 100 }; // getAutoScale(signalId, null);
              newRanges[signalId] = autoRange;
            }
            l--;
            if (l === 0) {
              setChartRanges(newRanges);
              globalData.chartRanges = newRanges;
              //drawPulseValues(newRanges);
            }
          });
      });
    },
    [chartRanges, setChartRanges],
  );

  const drawCharts = useCallback(() => {
    if (!signalsData || !events) return;
    chartDrawing = true;
    console.log('redraw');
    Object.values(globalData.allEventsList).forEach((evList) => {
      evList.forEach((ev) => {
        if (!ev.figure) return;
        ev.figure.offMouseLeave();
        ev.figure.offMouseMove();
        ev.figure.dispose();
      });
    });
    visibleEventTextElements.forEach((figure) => {
      figure?.dispose();
    });
    visibleEventPolygons = [];
    visibleEventTextElements = [];
    signalLines = {};
    globalData.allEventsList = {};
    const chartNumber = selectedChartIds.length;
    const topAxisSize = Math.floor((window.innerHeight - 170) / 50);
    if (dashboardObject.current) {
      dashboardObject.current.forEachChart((chart) => {
        const series = chart.getSeries();
        series.forEach((s) => {
          s.dispose();
        });
        chart.forEachAxis((axis) => {
          axis.dispose();
        });
        chart.dispose();
      });
      dashboardObject.current.dispose();
    }
    const license =
      window.location.hostname === 'localhost'
        ? {}
        : {
            license:
              '0001-0b1e8-a309c-d41d0-be04d-55814-5818d-6d839-faac0-f6c57-532b3-eaf49-00020-10aa8-d0100-0048b-9f21f',
          };
    const rowSizeSum = selectedChartIds
      .map((id) => chartSizes[id])
      .reduce((prev, curr) => {
        return prev + curr;
      }, 0);
    globalData.rowSizeSum = rowSizeSum;
    dashboardObject.current = lightningChart(license)
      .Dashboard({
        theme: Themes.lightNew,
        numberOfColumns: 1,
        container: 'chartContainer',
        numberOfRows:
          Math.floor(4 * (topAxisSize * rowSizeSum + 3 * chartNumber)) +
          4 +
          15 * chartNumber,
        height: window.innerHeight - 170,
        margin: { top: 50 },
      })
      .setSplitterStyle(new SolidLine({ thickness: 0 }));
    const dashboard = dashboardObject.current;
    resizeElements = [];
    globalData.eventChartData = null;
    xAxisList = [];
    yAxisList = {};
    signalChartsList = {};
    let index = 0;
    manualEventTypes = {};
    const chart0 = dashboard
      .createChartXY({
        columnIndex: 0,
        columnSpan: 1,
        rowIndex: 0,
        rowSpan: Math.floor(rowSizeSum * 4),
        theme: Themes.lightNew,
        defaultAxisX: {
          opposite: true,
        },
      })
      .setTitleMarginBottom(0)
      .setTitle('')
      .setTitleFillStyle(emptyFill)
      .setPadding({
        right: 10,
        top: 16,
        bottom: 0,
        left: 54,
        //bottom: i === 0 ? 0 : -30,
      })
      .setTitleMarginTop(0);
    firstAxis = chart0
      .getDefaultAxisX()
      .setOverlayStyle(axisXStyleHighlight)
      .setMouseInteractions(false)
      .setNibOverlayStyle(axisXStyleHighlight)
      .setScrollStrategy(undefined)
      .setThickness(20)
      .setNibLength(0)
      .setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
        dateTimeTicks.setDateOrigin(dateOrigin).setGreatTickStyle(emptyTick),
      );
    chart0.setAutoCursor((cursor) => {
      cursor
        .setResultTableAutoTextStyle(false)
        .disposeTickMarkerX()
        .disposeTickMarkerY()
        .setTickMarkerXAutoTextStyle(false)
        .setTickMarkerYAutoTextStyle(false)
        .setGridStrokeYStyle(emptyLine);
    });
    firstAxis
      .addCustomTick()
      .setTickLength(60)
      .setTextFormatter((position, customTick) => '');
    chart0
      .getDefaultAxisY()
      .setNibLength(0)
      .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
        tickStrategy
          .setMajorFormattingFunction((tickPosition) => {
            return '';
          })
          .setMinorFormattingFunction((tickPosition) => {
            return '';
          })
          .setMinorTickStyle((tickStyle) =>
            tickStyle.setGridStrokeStyle(emptyLine),
          ),
      );

    const splineSeries1 = chart0.addLineSeries({
      xAxis: firstAxis,
      dataPattern: {
        pattern: 'ProgressiveX',
        regularProgressiveStep: true,
      },
    });
    splineSeries1.setMaxPointCount(1000);
    splineSeries1.add(signalsData[0].data.map((t) => ({ x: t.x, y: 0 })));
    splineSeries1.setStrokeStyle(
      new SolidLine({
        thickness: 0,
      }),
    );
    let rowIndex = Math.floor(4 * rowSizeSum);
    chartSignalDrawData = {
      main: {
        rowSizeSum,
        topAxisSize,
      },
    };
    zoomIdActual = selectedChartIds.includes(zoomId)
      ? zoomId
      : selectedChartIds[0];
    selectedCharts
      .map((i) => signalsData[i])
      .forEach((signalData, singalIndex) => {
        chartSignalDrawData[signalData.SignalId] = {
          index,
          singalIndex,
          rowIndex,
          topAxisSize,
          rowSizeSum,
        };
        rowIndex = drawSignal(
          true,
          index,
          singalIndex,
          signalData,
          rowIndex,
          topAxisSize,
          rowSizeSum,
        );

        index++;
      });

    drawPulseValues();

    Object.keys(globalData.allEventsList).forEach((eventType) => {
      if (!visibleEvents.includes(eventType)) {
        globalData.allEventsList[eventType].forEach((e) => {
          e.figure.dispose();
          e.textFigure.dispose();
          e.resizeElementFront?.dispose();
          e.resizeElementBack?.dispose();
        });
      }
    });
    const chartLast = dashboard
      .createChartXY({
        columnIndex: 0,
        columnSpan: 1,
        rowIndex: 4 * Math.floor(topAxisSize * rowSizeSum + 3 * chartNumber),
        rowSpan: 4,
        theme: Themes.lightNew,
      })
      .setTitleMarginBottom(0)
      .setTitle('')
      .setTitleFillStyle(emptyFill)
      .setPadding({
        right: 15,
        left: 15,
        top: 0,
        bottom: 10,
        //bottom: i === 0 ? 0 : -30,
      })
      .setTitleMarginTop(0);
    chartLast.getDefaultAxisY().setMouseInteractions(false);
    const lastAxis = chartLast
      .getDefaultAxisX()
      .setOverlayStyle(axisXStyleHighlight)
      .setMouseInteractions(false)
      .setNibOverlayStyle(axisXStyleHighlight)
      .setScrollStrategy(undefined)
      .setThickness(20)
      .setNibLength(0)
      .setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
        dateTimeTicks.setDateOrigin(dateOrigin).setGreatTickStyle(emptyTick),
      );
    chartLast.setAutoCursor((cursor) => {
      cursor
        .setResultTableAutoTextStyle(false)
        .disposeTickMarkerX()
        .disposeTickMarkerY()
        .setTickMarkerXAutoTextStyle(false)
        .setTickMarkerYAutoTextStyle(false)
        .setGridStrokeYStyle(emptyLine);
    });
    const splineSeriesLast = chartLast.addLineSeries({
      xAxis: lastAxis,
      dataPattern: {
        pattern: 'ProgressiveX',
        regularProgressiveStep: true,
      },
    });
    splineSeries1.setMaxPointCount(1000);
    splineSeriesLast.add(signalsData[0].data);
    splineSeriesLast.setStrokeStyle(
      new SolidLine({
        thickness: 0,
      }),
    );
    const allEventsChartAxis = drawAllEventsChart();
    setFirstChart(xAxisList[0]);
    //setChartRanges(ranges);
    synchronizeAxisIntervals(...xAxisList, firstAxis);
    setEventTypes(manualEventTypes);
    xAxisList[0].setInterval(zoomIntervalGlobal.start, zoomIntervalGlobal.end);
    lastAxis.setInterval(
      0,
      (signalsData[0].data.length * 1000) / signalsData[0].rate,
    );
    if (loading) {
      chartsDataUpdated++;
      setLoading(false);
    }
    drawLSLabels();
    if (signalsToLoad.length) {
      loadBigSignals(signalsToLoad);
      signalsToLoad = [];
    } else {
      // drawPulseValues();
    }

    chartDrawing = false;

    //setZoomInterval(zo);
    //setSignalsData(null);
  }, [chartSizes, loadBigSignals, events, selectedCharts, drawSignal]);

  useEffect(() => {
    if (zoomId !== zoomSignalId && !dataLoading) {
      zoomId = zoomSignalId;
      drawCharts();
    }
    //if (dashboardObject.current && globalData.loadedChartsData[zoomSignalId]) drawSlider(dashboardObject.current, zoomSignalId, globalData.loadedChartsData[zoomSignalId], chartColors[zoomSignalId]);
  }, [zoomSignalId, drawCharts, dataLoading]);

  var timeoutId = useRef(null);
  const handleResize = useCallback(() => {
    if (
      redrawCharts &&
      signalsData.length &&
      events &&
      Object.keys(chartRanges).length &&
      !dataLoading
    ) {
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(function () {
        redrawCharts = false;
        drawCharts();
        setDataLoading(false);

        rowHeight = document.querySelector(
          '.chartNames .chartName',
        ).clientHeight;
      }, 800);
    }
  }, [
    events,
    drawCharts,
    setDataLoading,
    dataLoading,
    drawLSLabels,
    chartRanges,
  ]);

  useEffect(() => {
    if (patientId) {
      loadStudyData(setStudyData, studyId, patientId);
      getReportId(patientId, guid);
    }
  }, [setStudyData, studyId, patientId]);

  const drawAllEventsChart = useCallback(() => {
    const chartNumber = selectedChartIds.length;
    const topAxisSize = Math.floor((window.innerHeight - 170) / 50);
    const rowIndex =
      Math.floor(4 * (topAxisSize * globalData.rowSizeSum + 3 * chartNumber)) +
      4;
    const yCooridantes = {};
    let y = 1;
    const visibleEvents = Object.keys(globalData.allEventsList);

    visibleEvents.forEach((eventType) => {
      yCooridantes[y] = eventType; //.replace("Oximetry", "");
      y++;
    });
    const eventsNumber = y;
    const dashboard = dashboardObject.current;
    const allEventsChart = dashboard
      .createChartXY({
        columnIndex: 0,
        columnSpan: 1,
        rowIndex: rowIndex,
        rowSpan: 15 * chartNumber,
        theme: Themes.lightNew,
      })
      .setTitleMarginBottom(0)
      .setTitle('')
      .setTitleFillStyle(emptyFill)
      .setPadding({
        right: 15,
        left: 50,
        top: 0,
        bottom: 10,
        //bottom: i === 0 ? 0 : -30,
      })
      .setTitleMarginTop(0);
    console.log('redraw evens chart');
    const allEventsChartAxis = allEventsChart
      .getDefaultAxisX()
      .setOverlayStyle(axisXStyleHighlight)
      //.setMouseInteractions(false)
      .setNibOverlayStyle(axisXStyleHighlight)
      .setScrollStrategy(undefined)
      .setThickness(20)
      .setInterval(0, globalData.dateEndTime - globalData.dateOriginTime)
      .setNibLength(0)
      .setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
        dateTimeTicks.setDateOrigin(dateOrigin).setGreatTickStyle(emptyTick),
      );
    const axisY = allEventsChart
      .getDefaultAxisY()
      .setStrokeStyle(axisYStrokeStyles[0])
      .setTitle('')
      .setInterval(0, 10)
      .setOverlayStyle(axisYStylesHighlight[0])
      .setNibOverlayStyle(axisYStylesHighlight[0])
      // .setInterval(2, y * 2 - 2)
      .setNibLength(10)
      .setTitleFont(
        new FontSettings({
          size: 11,
          family: 'Arial, Helvetica, sans-serif',
          weight: 'bold',
          style: 'italic',
        }),
      )
      .setScrollStrategy(AxisScrollStrategies.regressive)
      .setTickStrategy(AxisTickStrategies.Numeric, (tickStrategy) =>
        tickStrategy
          .setMajorFormattingFunction((tickPosition) => {
            return ''; //yCooridantes[tickPosition]?.replace("Oximetry", "") || "";
          })
          .setMinorFormattingFunction((tickPosition) => {
            // return tickPosition.toString();
            return '';
          })
          .setMajorTickStyle((tickStyle) =>
            tickStyle.setGridStrokeStyle(emptyLine).setLabelAlignment(1),
          )
          .setMinorTickStyle((tickStyle) =>
            tickStyle.setGridStrokeStyle(emptyLine).setLabelAlignment(1),
          ),
      )
      .setTickStrategy(AxisTickStrategies.Numeric);

    // allEventsChart
    //   .addPolygonSeries()
    //   .add([
    //     { x: 0, y: 1 },
    //     { x: 1000, y: 1 },
    //     { x: 1000, y: 5 },
    //     { x: 0, y: 5 },
    //   ])
    //   .setFillStyle(new SolidFill({ color: ColorHEX("#00f000a0") }))
    //   .setStrokeStyle(strokeStyle);

    const splineSeriesLast = allEventsChart.addLineSeries({
      xAxis: allEventsChartAxis,
      yAxis: axisY,
      dataPattern: {
        pattern: 'ProgressiveX',
        regularProgressiveStep: true,
      },
    });

    // const customTick = axisY.addCustomTick(UIElementBuilders.AxisTick);
    // Object.keys(yCooridantes).forEach((y) => {
    //   axisY
    //     .addCustomTick(UIElementBuilders.AxisTick)
    //     // .setLabelAlignment(-1)
    //     .setValue(y)
    //     .setGridStrokeStyle(emptyLine)
    //     .setTextFormatter((_) => yCooridantes[y].replace("Oximetry", ""));
    // });
    const polygonSeries = allEventsChart.addPolygonSeries();
    const onMouseOver = (e, type) => {
      setHoverElement({
        position: { x: e.pageX, y: e.pageY },
        size: { start: '1', end: '2' },
        isEventDrawing: true,
        customText: type,
        onRight: false,
      });
    };
    Object.keys(yCooridantes).forEach((y1) => {
      const y = parseInt(y1);
      const id = Object.keys(eventSignalIds).find(
        (i) =>
          eventSignalIds[i] && eventSignalIds[i].includes(yCooridantes[y1]),
      );
      if (!id || !events[id]) return;
      events[id].forEach((event) => {
        if (event.Type !== yCooridantes[y1]) return;
        const start = event.startTimeMicro;
        const end = event.endTimeMicro;
        const type = '';
        const range = globalData.chartRanges[id];
        const height = 0.1;

        const pols = polygonSeries
          .setCursorEnabled(false)
          .setHighlightOnHover(false)
          .setHighlightMode(lcjs.HighlightModes.noHighlighting)
          .add([
            { x: start, y: y - 0.4 },
            { x: end, y: y - 0.4 },
            { x: end, y: y + 0.4 },
            { x: start, y: y + 0.4 },
          ])
          .setMouseInteractions(false)
          .setFillStyle(
            new SolidFill({
              color: ColorHEX(
                globalData.eventColors[event.Type] || '#f00000a0',
              ),
            }),
          )
          .setStrokeStyle(strokeStyle);
        pols.onMouseMove((t, e) => {
          onMouseOver(e, event.Type);
        });
        pols.onMouseLeave((t, e) => {
          setHoverElement(null);
        });

        // const t = globalData.lastTextElement;
        // const t2 = lastPolygonFigure;
        // const size = { start, end, h, y };
        // const id = event.id;
      });
    });
    allEventsChart.setAutoCursor((cursor) => {
      cursor
        .setResultTableAutoTextStyle(false)
        .disposeTickMarkerX()
        .disposeTickMarkerY()
        .setTickMarkerXAutoTextStyle(false)
        .setTickMarkerYAutoTextStyle(false)
        .setGridStrokeYStyle(emptyLine);
    });
    axisY.setInterval(0.1, eventsNumber);

    if (positionSignal) {
      axisY
        .addCustomTick(UIElementBuilders.AxisTick)
        .setValue(eventsNumber)
        .setTextFormatter((_) => '');
      axisY.setInterval(0.1, eventsNumber + 1);
      let i = 0;
      let current = getBodyRotationDirection(positionSignal.data[0].y);
      let currentX = 0;
      const y = eventsNumber;
      const l = positionSignal.data.length - 1;
      for (i = 1; i < positionSignal.data.length; i++) {
        let c = getBodyRotationDirection(positionSignal.data[i].y);
        if (c !== current || i === l) {
          const pols = polygonSeries
            .setCursorEnabled(false)
            .setHighlightOnHover(false)
            .setHighlightMode(lcjs.HighlightModes.noHighlighting)
            .add([
              { x: positionSignal.data[currentX].x, y: y - 0.4 },
              { x: positionSignal.data[i].x, y: y - 0.4 },
              { x: positionSignal.data[i].x, y: y + 0.4 },
              { x: positionSignal.data[currentX].x, y: y + 0.4 },
            ])
            .setMouseInteractions(false)
            .setFillStyle(
              new SolidFill({
                color: ColorHEX(bodyRotationColor[current] || '#f00000a0'),
              }),
            )
            .setStrokeStyle(strokeStyle);
          const onMouseOver = (e, type) => {
            setHoverElement({
              position: { x: e.pageX, y: e.pageY },
              size: { start: '1', end: '2' },
              isEventDrawing: true,
              customText: type,
              onRight: false,
            });
          };
          pols.onMouseMove((t, e) => {
            onMouseOver(e, 'Body');
          });
          pols.onMouseLeave((t, e) => {
            setHoverElement(null);
          });

          allEventsChart
            .addUIElement(UIElementBuilders.TextBox, splineSeriesLast.scale)
            .setText(current)
            .setDraggingMode(lcjs.UIDraggingModes.onlyHorizontal)
            .setPosition({
              x:
                (positionSignal.data[currentX].x + positionSignal.data[i].x) /
                2,
              y: y,
              z: 1,
            })
            .setTextFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0, 250) }))
            .setTextFont(
              new FontSettings({
                size: 12,
              }),
            )
            .setBackground((background) =>
              background.setFillStyle(emptyFill).setStrokeStyle(emptyLine),
            );
          current = c;
          currentX = i;
        }
      }
    }

    //
    // .setGridStrokeLength(0)

    // .setTextFormatter((_) => yCooridantes[y])
    // .setMarker(
    //   (tickMarker) => tickMarker
    //   // Style TickMarker.
    //   //.setTextFillStyle(new SolidFill({ color: ColorRGBA(255, 0, 0) }))
    // );
    // .setMarker((marker) => marker.setTextFillStyle(new SolidFill({ color: ColorRGBA(170, 170, 170) })));

    // splineSeriesLast.add(signalsData[0].data);
    // splineSeriesLast.setStrokeStyle(
    //   new SolidLine({
    //     thickness: 1,
    //   })
    // );
    return allEventsChartAxis;
  }, [events, visibleEvents]);

  const windowResize = useCallback(() => {
    redrawCharts = true;
    handleResize();
    if (!document.getElementById('event_selector_button')) return;
    const coords = document
      .getElementById('event_selector_button')
      .getBoundingClientRect();
    const listElement = document.getElementById('event_selector_list');
    listElement.style.top = coords.bottom + 'px';
    listElement.style.left = coords.left - 80 + 'px';
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', windowResize);
    return () => window.removeEventListener('resize', windowResize);
  }, [windowResize]);

  useEffect(() => {
    //if (!interval.start && !getStudyIntervalRunning) getStudyInterval(studySignals);
  }, [getStudyInterval, interval.start]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  const afterSignalLoad = useCallback(
    (data) => {
      setDataLoading(false);
      const smallData = {};
      data.forEach((signal, i) => {
        const signalObject = signalIdsData[i];
        const rate = signal.SampleRate;
        const chartData = {
          i,
          SignalId: signalObject.SignalId,
          data: signal.Points.map((point, i) => ({
            x: (i * 1000) / rate,
            y: point,
          })),
          Type: signalObject.Type,
          name:
            signalObject.Type +
            (signalObject.Specification
              ? ' ' + signalObject.Specification
              : ''),
          Specification: signalObject.Specification,
          rate: signal.SampleRate,
        };
        if (signalObject.Type === 'SPO2' && !minSpo2Value) {
          let min = 80;
          chartData.data.forEach((p) => {
            if (p.y < min && p.y > 75) min = p.y;
          });
          minSpo2Value = Math.floor(min / 10) * 10;
          minSpo2Value = minSpo2Value < 75 ? 75 : minSpo2Value;
          if (zoomBandChart)
            zoomBandChart
              .getDefaultAxisY()
              .setMouseInteractions(true)
              .setInterval(minSpo2Value, 100, true, true);
        }
        //if (chartData.Type === "Pulse") chartData.data = interpolatePoints(chartData.data);

        const signalId = signalObject.SignalId;

        signalsData[i] = chartData; //.sort((a, b) => a.i - b.i);
        globalData.loadedChartsData[signalId] = chartData;
        smallData[signalId] = {
          SignalId: signalId,
          name: globalData.loadedChartsData[signalId].name,
          Type: globalData.loadedChartsData[signalId].Type,
        };
      });
      [lineSteps, chartAreasData] = getLineSteps(globalData.loadedEventsData);
      loadPositionSignal();
      setAllChartData(smallData);
      checkChartRanges(setChartRanges);
    },
    [loadPositionSignal, setChartRanges],
  );

  const loadSignalsData = useCallback(
    (interval) => {
      if (!studySignals.length || dataLoading) return;
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + globalData.token);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      const data = [];
      let l = signalIdsData.length;
      signalIdsData.forEach((signal, i) => {
        const rate = sampleRates[signal.SignalId];
        const storageData = localStorage.getItem(
          'study' + studyId + '||' + signal.SignalId + '||' + rate,
        );
        if (storageData) {
          data[i] = JSON.parse(storageData);
          l--;
          if (l === 0) {
            afterSignalLoad(data);
          }
          return;
        }
        let rate2 = 1;
        if (rate2 === rate) {
          rate2 = rate;
        } else {
          signalsToLoad.push({
            rate,
            signalId: signal.SignalId,
            i,
          });
          const storageData = localStorage.getItem(
            'study' + studyId + '||' + signal.SignalId + '||' + rate2,
          );
          if (storageData) {
            data[i] = JSON.parse(storageData);
            l--;
            if (l === 0) {
              afterSignalLoad(data);
            }
            return;
          }
        }

        fetch(
          `${host}/sleepstudy/api/signalassinglesegment?SampleRate=${rate2}&signalId=${signal.SignalId}&startTime=${interval.StartTime}&endTime=${interval.EndTime}`,
          requestOptions,
        )
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            l--;
            try {
              window.localStorage.setItem(
                'study' + studyId + '||' + signal.SignalId + '||' + rate2,
                JSON.stringify(result),
              );
            } catch {}
            data[i] = result;
            if (l === 0) {
              afterSignalLoad(data);
            }
          });
      });
    },
    [dataLoading, afterSignalLoad, studyId],
  );

  const drawLSLabels = useCallback(() => {
    lsWaterMarks.forEach((w) => {
      w.dispose();
    });
    const i1 =
      Math.floor(
        (globalData.dateOriginTime + zoomIntervalGlobal.start) / 30000,
      ) + 1;
    const i2 = Math.floor(
      (globalData.dateOriginTime + zoomIntervalGlobal.end) / 30000,
    );
    const t = globalData.dateOriginTime - 15000;
    Object.keys(chartsList).forEach((signalId) => {
      const chart = chartsList[signalId];
      const axisX = chart.getDefaultAxisX();
      if (!axisX) return;
      const axisY = chart.getDefaultAxisY();
      const height =
        globalData.chartRanges[signalId].max -
        globalData.chartRanges[signalId].min;
      const y = 0.5 * height + globalData.chartRanges[signalId].min;
      try {
        for (var i = i1; i <= i2; i++) {
          const waterMark = chart
            .addUIElement(UIElementBuilders.TextBox, {
              x: axisX,
              y: axisY,
            })
            .setText('LS')
            .setDraggingMode(lcjs.UIDraggingModes.notDraggable)
            .setPosition({ x: i * 30000 - t, y })
            .setBackground((bg) =>
              bg.setFillStyle(emptyFill).setStrokeStyle(emptyLine),
            )
            .setTextFillStyle(
              new SolidFill({ color: ColorRGBA(0, 0, 0, 100) }),
            );
          lsWaterMarks.push(waterMark);
        }
      } catch {}
    });
  }, []);

  const zoomUpdate = useCallback(
    (start, end) => {
      start = Math.floor(start);
      end = Math.floor(end);
      if (start > end) {
        const t = start;
        start = end;
        end = t;
      }
      zoomIntervalGlobal = { start, end };
      drawPulseValues();
      if (positionSignal) {
        const x = Math.floor((end * positionSignal.rate) / 1000);
        imageRotation = getBodyRotation(positionSignal.data[x].y);
        document.getElementById(
          'body',
        ).style = `transform:rotateZ(${imageRotation}deg)`;
      }
      if (!zoomInterval || Math.floor(end - start) - bandSize !== 0) {
        bandSize = Math.floor(end - start);
        const d = (end - start) / 60000;
        minutesNumber =
          d < 1 ? (d <= 0.5 ? 0.5 : 1) : Math.floor((end - start) / 60000);
        if (
          !selectedTimeInterval.label !== 'custom' &&
          !timeIntervalSizes.find((t) => t.value === minutesNumber * 60)
        )
          setSelectedTimeTinterval({
            label: 'custom',
            value: minutesNumber * 60,
          });
        setZoomInterval({ start, end });
        redrawEventstext();

        resizeElements.forEach((el) => {
          const points = el.getDimensions();
          const x = (points[0].x + points[1].x) / 2;
          const dist = (end - start) / 600;
          points[0].x = x - dist;
          points[1].x = x + dist;
          points[2].x = x + dist;
          points[3].x = x - dist;
          el.setDimensions(points);
        });
        //drawCharts();
      }
      visibleEventPolygons.forEach((figure) => {
        figure?.dispose();
      });
      visibleEventPolygons = [];
      visibleEventTextElements.forEach((figure) => {
        figure?.dispose();
      });
      visibleEventTextElements = [];
      Object.keys(globalData.allEventsList).forEach((key) => {
        globalData.allEventsList[key] = [];
      });
      selectedCharts
        .map((i) => signalsData[i])
        .forEach((signalData, singalIndex) => {
          events[signalData.SignalId].forEach((event) => {
            if (
              event.startTimeMicro > zoomIntervalGlobal.end ||
              event.endTimeMicro < zoomIntervalGlobal.start
            )
              return;
            visibleEventPolygons.push(
              globalData.drawEventFunction[signalData.SignalId](
                event,
                signalData.SignalId,
              ),
            );
          });
        });
      searchedEventId = 0;
      drawLSLabels();
    },
    [
      events,
      selectedCharts,
      zoomInterval,
      setZoomInterval,
      redrawEventstext,
      drawPulseValues,
      drawLSLabels,
      selectedTimeInterval,
    ],
  );

  useEffect(() => {
    if (!firstChart) return;
    firstChart.onScaleChange(debounce(zoomUpdate));
  }, [firstChart, zoomUpdate]);

  const nextPage = useCallback(
    (next = true, distance1 = null) => {
      const distance =
        distance1 || zoomIntervalGlobal.end - zoomIntervalGlobal.start;
      let int = {};
      if (next) {
        const max = interval.end.getTime() - interval.start.getTime();
        if (zoomInterval.end + distance > max) {
          int = { start: max - distance, end: max };
        } else
          int = {
            start: zoomIntervalGlobal.start + distance,
            end: zoomIntervalGlobal.end + distance,
          };
      } else {
        if (zoomIntervalGlobal.start - distance < 0) {
          int = { start: 0, end: distance };
        } else {
          int = {
            start: zoomIntervalGlobal.start - distance,
            end: zoomIntervalGlobal.end - distance,
          };
        }
      }
      setZoomInterval(int);
      firstChart.setInterval(int.start, int.end);
    },
    [firstChart, zoomInterval, interval],
  );

  useEffect(() => {
    if (moveInterval.current) clearInterval(moveInterval.current);
    if (!speed) return;
    const time = playMode === 'page' ? 3000 / Math.abs(speedMultiplier) : 30;
    moveInterval.current = setInterval(() => {
      if (!speed) return;
      if (playMode === 'page') {
        nextPage(speed > 0);
      } else {
        const int = firstChart.getInterval();
        firstChart.setInterval(int.start + speed, int.end + speed);
      }
    }, time);
  }, [firstChart, speed, nextPage, playMode, speedMultiplier]);

  const setPlay = (multiplier = 0) => {
    if (speed && !multiplier) {
      setSpeedMultiplier(1);
      setSpeed(0);
    } else {
      if (multiplier) {
        if (multiplier > 0) {
          const m1 = speedMultiplier > 0 ? speedMultiplier : 1;
          const i = speedMultipliers.findIndex((m) => m === m1);
          if (i < speedMultipliers.length - 1) {
            multiplier = speedMultipliers[i + 1];
            setSpeedMultiplier(multiplier);
          } else multiplier = speedMultipliers[i];
        } else {
          const m1 = speedMultiplier < 0 ? speedMultiplier : 1;
          const i = speedMultipliers.findIndex((m) => m === -m1);
          if (i < speedMultipliers.length - 1) {
            multiplier = -speedMultipliers[i + 1];
            setSpeedMultiplier(multiplier);
          } else multiplier = speedMultipliers[i];
        }
      } else {
        multiplier = 1;
      }
      const int = firstChart.getInterval();
      setSpeed((multiplier * (int.end - int.start)) / 200);
    }
  };

  const nextHour = useCallback(
    (next = true, n = 1) => {
      const distance = zoomInterval.end - zoomInterval.start;
      let newDate = new Date(
        interval.start.getTime() +
          zoomInterval.start +
          3600000 * (next ? n : -n),
      );
      newDate.setMinutes(0);
      newDate.setSeconds(0);
      let newTime = newDate.getTime() - interval.start.getTime();
      if (newTime < 0) newTime = 0;
      if (newDate.getTime() + distance > interval.end.getTime())
        newTime = interval.end.getTime() - interval.start.getTime() - distance;
      setZoomInterval({ start: newTime, end: newTime + distance });
      firstChart.setInterval(newTime, newTime + distance);
    },
    [firstChart, interval, zoomInterval],
  );

  const onChange = (value) => {
    if (newEventSize?.customFields) newEventSize.customFields = undefined;
    setSelectedEvent({ ...selectedEvent, newText: value });
  };

  const handleKey = useCallback(
    (e) => {
      switch (e.key) {
        case 'Home':
          nextHour(false, 1000000);
          break;
        case 'End':
          nextHour(true, 1000000);
          break;
        case 'PageUp':
          nextPage(false);
          break;
        case 'PageDown':
          nextPage();
          break;
        case 'ArrowRight':
          nextPage();
          break;
        case 'ArrowLeft':
          nextPage(false);
          break;
        default:
          break;
      }
    },
    [nextPage, nextHour],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKey);
    return () => window.removeEventListener('keydown', handleKey);
  }, [handleKey]);

  const addEvent = useCallback(
    (cancel = false) => {
      eventPressed = null;
      if (cancel) {
        if (!eventEditMode) {
          globalData.lastTextElement.dispose();
          lastPolygonFigure.dispose();
        }
      } else {
        if (selectedEvent) {
          manualEvents[modalOpen].push(lastDrawnElement);
          //if (lastPolygonFigure) lastPolygonFigure.restore();
          let id = selectedEvent.id;
          const newText = selectedEvent.newText || selectedEvent.text;
          const text = eventEditMode ? selectedEvent.text : newText;
          let start = newEventSize.start
            ? newEventSize.start - interval.start.getTime()
            : eventSize.start;
          let end = newEventSize.end
            ? newEventSize.end - interval.start.getTime()
            : eventSize.end;
          const sigId = modalOpen;

          let index = null;
          if (eventEditMode) {
            if (!globalData.allEventsList[text] || !id) return;
            index = globalData.allEventsList[text].findIndex(
              (e) => e.id === id,
            );
            if (index < 0) return;

            const crossedEvent = checkOverlap(
              newText,
              id,
              sigId,
              start,
              end,
              globalData.allEventsList,
            );
            if (crossedEvent) {
              alert(crossedEvent);
              return;
            }

            globalData.allEventsList[text][index] = {
              ...globalData.allEventsList[text][index],
              start,
              end,
              Type: newText,
            };
            const newEvents = { ...events };
            newEvents[sigId] = events[sigId].slice(0);
            const eventIndex = newEvents[sigId].findIndex((e) => e.id === id);
            if (eventIndex >= 0) {
              const newEvent = {
                ...events[sigId][eventIndex],
                StartTime: moment(start + dateOriginTime)
                  .utcOffset(0, true)
                  .format()
                  .replace('Z', ''),
                EndTime: moment(end + dateOriginTime)
                  .utcOffset(0, true)
                  .format()
                  .replace('Z', ''),
                Type: newText,
                type: newText,
                id: id,
                updated: !events[sigId][eventIndex].added,
                startTimeMicro: start,
                endTimeMicro: end,
              };
              if (newEventSize?.customFields)
                newEvent.CustomCharacteristics = {
                  ...newEvent.CustomCharacteristics,
                  ...newEventSize?.customFields,
                };
              newEvents[sigId][eventIndex] = newEvent;
              addHistoryEvent({
                signalId: sigId,
                prev: events[sigId],
                next: newEvents[sigId],
                type: 'event',
                title: 'update event',
              });
              globalData.loadedEventsData[sigId] = newEvents[sigId];
              globalData.eventsUpdated = true;
              redrawCharts = true;
              setEvents(newEvents);
            }
            //globalData.lastTextElement.setText(newT);
            // lastPolygonFigure.dispose();
            // globalData.lastTextElement.dispose();
          } else {
            const newEvents = { ...globalData.loadedEventsData };
            newEvents[sigId] = globalData.loadedEventsData[sigId].slice(0);
            const newEvent = {
              StartTime: moment(start + dateOriginTime)
                .utcOffset(0, true)
                .format()
                .replace('Z', ''),
              EndTime: moment(end + dateOriginTime)
                .utcOffset(0, true)
                .format()
                .replace('Z', ''),
              Type: text,
              type: text,
              id: eventId,
              Status: 'Mutual added',
              added: true,
              startTimeMicro: start,
              endTimeMicro: end,
            };
            if (newEventSize?.customFields)
              newEvent.CustomCharacteristics = {
                ...newEvent.CustomCharacteristics,
                ...newEventSize?.customFields,
              };
            newEvents[sigId].push(newEvent);
            if (!globalData.allEventsList[text])
              globalData.allEventsList[text] = [];
            else {
              start = newEventSize.start
                ? newEventSize.start - interval.start.getTime()
                : tempEvent.start;
              end = newEventSize.end
                ? newEventSize.end - interval.start.getTime()
                : tempEvent.end;
              const crossedEvent = checkOverlap(
                text,
                id,
                sigId,
                start,
                end,
                globalData.allEventsList,
              );
              if (crossedEvent) {
                alert(crossedEvent);
                globalData.lastTextElement.dispose();
                lastPolygonFigure.dispose();
                return;
              }
            }
            addHistoryEvent({
              signalId: sigId,
              prev: events[sigId],
              next: newEvents[sigId],
              type: 'event',
              title: 'add event',
            });
            globalData.loadedEventsData[sigId] = newEvents[sigId];
            globalData.eventsUpdated = true;
            setEvents(newEvents);
            // globalData.allEventsList[text].push({ ...tempEvent, start, end, Type: text, id: eventId, signalId: sigId });
            //index = globalData.allEventsList[text].length - 1;
            id = eventId;
            globalData.lastTextElement.setText(selectedEvent.text);
            lastPolygonFigure.dispose();
            globalData.lastTextElement.dispose();
            globalData.drawEventFunction[sigId](newEvent, sigId);
          }
          eventId++;

          // if (index !== null) {
          //   globalData.allEventsList[text][index]["figure"] = lastPolygonFigure;
          // }
          // redrawSignal(sigId);
        }
      }
      if (window.eventDrawing) addEventActivate();

      newEventSize = {};
      setModalOpen(false);
      setSelectedEvent(null);
    },
    [
      addHistoryEvent,
      eventEditMode,
      eventSize,
      events,
      interval,
      modalOpen,
      selectedEvent,
      redrawSignal,
    ],
  );

  const deleteEvent = useCallback(() => {
    if (!selectedEvent.signalId) return;
    const signalId = selectedEvent.signalId;
    const id = selectedEvent.id;
    const type = selectedEvent.text;
    const newEvents = events[signalId].slice(0);
    const i = newEvents.findIndex((e) => e.id === id);
    newEvents[i] = { ...newEvents[i], removed: true };
    addHistoryEvent({
      signalId: signalId,
      prev: events[signalId],
      next: newEvents,
      type: 'event',
      title: 'delete event',
    });
    redrawCharts = true;
    globalData.loadedEventsData[signalId] = newEvents;
    globalData.eventsUpdated = true;
    setEvents({ ...events, [signalId]: newEvents });
    globalData.allEventsList[type] = globalData.allEventsList[type].filter(
      (e) => e.id !== id,
    );
    newEventSize = {};
    setModalOpen(false);
    setSelectedEvent(null);
    eventPressed = null;
  }, [addHistoryEvent, events, selectedEvent]);

  const switchCharts = useCallback(
    (oldPosition, newPosition, noHistory = false) => {
      if (oldPosition === newPosition) return;
      const el = selectedCharts[oldPosition];
      let charts = [];
      const otherElements = selectedCharts.filter((t, i) => i !== oldPosition);
      if (newPosition < 0) {
        charts = [el].concat(otherElements);
      } else {
        if (newPosition >= selectedCharts.length) {
          charts = otherElements.concat([el]);
        } else {
          otherElements.splice(newPosition, 0, el);
          charts = otherElements;
        }
      }
      if (!noHistory)
        addHistoryEvent({
          type: 'chartOrder',
          prev: selectedCharts,
          next: charts,
          title: 'charts order change',
        });
      updateChartsOrder(charts);
      return charts;
    },
    [selectedCharts, updateChartsOrder, addHistoryEvent],
  );

  const moveSourceChart = useCallback(
    (oldPosition, newPosition, signalId, noHistory = false) => {
      const charts = selectedCharts.slice(0);
      const mainSignalPosition = charts[oldPosition];
      const i = signalIds.findIndex(
        (s) => s.SignalId === globalData.additionalLines[signalId],
      );
      charts[oldPosition] = i;

      charts.splice(newPosition, 0, mainSignalPosition);
      globalData.additionalLines[signalId] = null;
      if (!noHistory)
        addHistoryEvent({
          type: 'chartOrder',
          prev: selectedCharts,
          next: charts,
          title: 'charts order change',
        });
      updateChartsOrder(charts);
      return charts;
    },
    [selectedCharts, updateChartsOrder, addHistoryEvent, signalIds],
  );

  const moveSecondChart = useCallback(
    (signalId, sourceId, newPosition) => {
      const charts = selectedCharts.slice(0);
      const i = signalIds.findIndex((s) => s.SignalId === signalId);
      charts.splice(newPosition, 0, i);
      globalData.additionalLines[sourceId] = null;
      addHistoryEvent({
        type: 'chartOrder',
        prev: selectedCharts,
        next: charts,
        title: 'charts order change',
      });
      updateChartsOrder(charts);
    },
    [addHistoryEvent, selectedCharts, updateChartsOrder, signalIds],
  );

  const addSecondCharts = useCallback(
    (s1, s2, unused = true) => {
      globalData.additionalLines[s1] = s2;
      let charts = selectedCharts.slice(0);
      if (!unused) {
        const i = signalIds.findIndex((s) => s.SignalId === s2);
        charts = selectedCharts.filter((c) => c !== i).slice(0);
      }
      addHistoryEvent({
        type: 'chartOrder',
        prev: selectedCharts,
        next: charts,
        title: 'charts order change',
      });
      updateChartsOrder(charts);
    },
    [selectedCharts, signalIds, updateChartsOrder, addHistoryEvent],
  );

  const addCharts = useCallback(
    (signalId, newIndex, noHistory = false) => {
      const el = signalIds.findIndex((s) => s.SignalId === signalId);
      let charts = [];
      const otherElements = selectedCharts;
      if (newIndex < 0) {
        charts = [el].concat(otherElements);
      } else {
        if (newIndex >= selectedCharts.length) {
          charts = otherElements.concat([el]);
        } else {
          otherElements.splice(newIndex, 0, el);
          charts = otherElements;
        }
      }
      if (!noHistory)
        addHistoryEvent({
          type: 'chartOrder',
          prev: selectedCharts,
          next: charts,
          title: 'charts order change',
        });

      updateChartsOrder(charts);
      return charts;
    },
    [selectedCharts, updateChartsOrder, signalIds, addHistoryEvent],
  );

  const addPolygonEvent = useCallback(
    (sigId, yPos, start, end, text) => {
      const id = eventId;
      const newEvents = { ...globalData.loadedEventsData };
      newEvents[sigId] = globalData.loadedEventsData[sigId]
        ? globalData.loadedEventsData[sigId].slice(0)
        : [];
      const newEvent = {
        StartTime: moment(start + dateOriginTime)
          .utcOffset(0, true)
          .format()
          .replace('Z', ''),
        EndTime: moment(end + dateOriginTime)
          .utcOffset(0, true)
          .format()
          .replace('Z', ''),
        Type: text,
        type: text,
        id,
        Status: 'Mutual added',
        status: 'Mutual added',
        added: true,
        startTimeMicro: start,
        endTimeMicro: end,
        yPos,
      };

      if (!globalData.allEventsList[text]) globalData.allEventsList[text] = [];
      else {
        const crossedEvent = checkOverlap(
          text,
          id,
          sigId,
          start,
          end,
          globalData.allEventsList,
        );
        if (crossedEvent) {
          alert(crossedEvent);
          return;
        }
      }
      if (newEventSize?.customFields)
        newEvent.CustomCharacteristics = {
          ...newEvent.CustomCharacteristics,
          ...newEventSize?.customFields,
        };
      newEvents[sigId].push(newEvent);
      addHistoryEvent({
        signalId: sigId,
        prev: events[sigId],
        next: newEvents[sigId],
        type: 'event',
        title: 'add event',
      });
      //redrawCharts = true;
      globalData.loadedEventsData[sigId] = newEvents[sigId];
      globalData.eventsUpdated = true;
      setEvents(newEvents);
      //globalData.allEventsList[text].push({ ...tempEvent1, yPos, start, end, Type: text, id: eventId, signalId: sigId });
      eventId++;
      addEventActivate();
      globalData.drawEventFunction[sigId](newEvent, sigId);
    },
    [addHistoryEvent, events],
  );

  const addFromDouble = useCallback(
    (signalId, signalIdSource, newIndex, noHistory = false) => {
      globalData.additionalLines[signalIdSource] = null;
      addCharts(signalId, newIndex, noHistory);
    },
    [addCharts],
  );

  const findEvent = useCallback(
    (moveType) => {
      if (searchEvent && globalData.eventsTyped[searchEvent]) {
        let i = selectedSearhEvent ? selectedSearhEvent.index : -1;
        const events = globalData.eventsTyped[searchEvent];

        switch (moveType) {
          case 'next':
            if (i < events.length - 1) i++;
            break;
          case 'last':
            i = events.length - 1;
            break;
          case 'prev':
            i = i > 0 ? i - 1 : 0;
            break;
          case 'first':
            i = 0;
            break;
          default:
            break;
        }

        let distance = zoomInterval.end - zoomInterval.start;
        const start =
          events[i].startTimeMicro > distance / 2
            ? events[i].startTimeMicro - distance / 2
            : 0;
        if (start + distance < events[i].end)
          distance = events[i].endTimeMicro - start + 100;
        searchedEventId = events[i].id;
        //globalData.allEventsList;
        setZoomInterval({ start: start, end: start + distance });
        firstChart.setInterval(start, start + distance);
        setSelectedSearchEvent({ index: i });
      }
    },
    [
      selectedSearhEvent,
      setSelectedSearchEvent,
      firstChart,
      zoomInterval,
      searchEvent,
    ],
  );

  const playItems = useMemo(() => {
    return [
      {
        key: 'page',
        label: (
          <Radio.Group
            onChange={(e) => {
              setPlayMode(e.target.value);
            }}
            value={playMode}
          >
            <Space direction="vertical">
              <Radio value={'page'}>Page mode</Radio>
              <Radio value={'scroll'}>Scroll mode</Radio>
            </Space>
          </Radio.Group>
        ),
      },
    ];
  }, [playMode, setPlayMode]);

  const eventVisibleSelectorClick = useCallback(
    (checked, event) => {
      let e2 = event;
      if (event === 'Cycling' || event === 'Reciprocation')
        e2 = 'Oximetry' + event;
      if (checked) {
        // addHistoryEvent({
        //   type: "eventTypeVisibility",
        //   prev: visibleEvents,
        //   next: visibleEvents.concat([event]),
        //   title: "event type visibility",
        // });
        if (globalData.allEventsList[e2]) {
          globalData.allEventsList[e2].forEach((e) => {
            e.figure.restore();
            e.textFigure.restore();
            e.resizeElementFront?.restore();
            e.resizeElementBack?.restore();
          });
        }
        if (!visibleEvents.includes(event))
          setVisibleEvents(visibleEvents.concat([event]));
      } else {
        const t = visibleEvents.filter((e) => e !== event);
        // addHistoryEvent({
        //   type: "eventTypeVisibility",
        //   prev: visibleEvents,
        //   next: t,
        //   title: "event type visibility",
        // });
        if (visibleEvents.includes(event)) setVisibleEvents(t);
        if (globalData.allEventsList[e2]) {
          globalData.allEventsList[e2].forEach((e) => {
            e.figure.dispose();
            e.textFigure.dispose();
            e.resizeElementFront?.restore();
            e.resizeElementBack?.restore();
          });
        }
      }
    },
    [visibleEvents],
  );

  const eventVisibleItems = useMemo(() => {
    return allEventTypesList.map((e, i) => ({
      key: i,
      label: (
        <span className="event_selector_item">
          <Checkbox
            onChange={(e1) => eventVisibleSelectorClick(e1.target.checked, e)}
            checked={visibleEvents.includes(e)}
          >
            {e}
          </Checkbox>
        </span>
      ),
    }));
  }, [visibleEvents, eventVisibleSelectorClick]);

  const updateInterval = useCallback(
    (value) => {
      if (value) {
        const start = Math.floor(zoomInterval.start);
        firstChart.setInterval(start, start + value * 1000);
        setZoomInterval({
          start: start,
          end: zoomInterval.start + value * 1000,
        });
      }
    },
    [firstChart, zoomInterval],
  );

  const timeIntervalItems = useMemo(() => {
    return [
      {
        key: 'page',
        label: (
          <Radio.Group
            onChange={(e) => {
              setSelectedTimeTinterval(
                timeIntervalSizes.find((t) => t.value === e.target.value),
              );
              updateInterval(e.target.value);
            }}
            value={selectedTimeInterval.value}
          >
            <Space direction="vertical">
              {timeIntervalSizes.map((int, i) => (
                <Radio key={'timeIntervalSizes' + i} value={int.value}>
                  {int.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        ),
      },
    ];
  }, [selectedTimeInterval, setSelectedTimeTinterval, updateInterval]);

  const eventVisibleSelectorClickHandle = useMemo((e) => {
    setVisibleEventTypesSelectorOpen(false);
  }, []);

  const saveData = useCallback(
    (studyData = null) => {
      saveEvents(
        events,
        artifacts,
        studyId,
        patientId,
        studyData,
        setShowReportRunModal,
      );
    },
    [events, artifacts, studyId, patientId],
  );

  useEffect(() => {
    if (globalData.eventsUpdated) {
      saveData(studyData);
      globalData.eventsUpdated = false;
    }
  }, [events, artifacts, studyData]);

  // useEffect(() => {
  //   window.onbeforeunload = (e) => {
  //     if (globalData.eventsUpdated) saveData();
  //     return true;
  //   };
  // }, [saveData]);

  const handleArtifactModal = useCallback(
    (del = false) => {
      if (del) {
        if (globalData.artifactsList[selectedArtifact.id]) {
          globalData.artifactsList[
            selectedArtifact.id
          ].resizeElementFront?.dispose();
          globalData.artifactsList[
            selectedArtifact.id
          ].resizeElementBack?.dispose();
          globalData.artifactsList[selectedArtifact.id].figure.dispose();
        }

        const i = artifacts.findIndex((a) => a.id === selectedArtifact.id);
        const newArtifacts = artifacts.slice(0);
        newArtifacts[i].removed = true;
        globalData.eventsUpdated = true;
        setArtifacts(newArtifacts);
        addHistoryEvent({
          type: 'artifact',
          prev: artifacts,
          next: newArtifacts,
          title: 'artifact delete',
        });
      }
      setSelectedArtifact(null);
    },
    [artifacts, setArtifacts, selectedArtifact, addHistoryEvent],
  );

  const updateChartsRange = useCallback(
    (ranges) => {
      globalData.chartRanges = { ...chartRanges, ...ranges };
      setChartRanges({ ...chartRanges, ...ranges });

      Object.keys(ranges).forEach((signalId) => {
        if (yAxisList[signalId]) {
          yAxisList[signalId].setInterval(
            ranges[signalId].min,
            ranges[signalId].max,
            true,
            true,
          );
        }
        updateEventsHeight(parseInt(signalId), ranges[signalId]);
      });
      // if (Object.keys(ranges).find((id) => [834, 829].includes(parseInt(id)))) {
      //   //drawPulseValues({ ...chartRanges, ...ranges });
      // }
    },
    [chartRanges, updateEventsHeight],
  );

  const setChartGrid = useCallback((signalId, type) => {
    chartsList[signalId]
      .getDefaultAxisX()
      .setTickStrategy(AxisTickStrategies.DateTime, (dateTimeTicks) =>
        dateTimeTicks
          .setDateOrigin(dateOrigin)
          .setGreatTickStyle(emptyTick)
          .setMajorTickStyle((tickStyle) =>
            globalData.gridLines[signalId]
              ? tickStyle
                  .setLabelFont((font) => font.setSize(0))
                  .setTickLength(0)
              : tickStyle
                  .setGridStrokeStyle(emptyLine)
                  .setLabelFont((font) => font.setSize(0))
                  .setTickLength(0),
          )
          .setMinorTickStyle((tickStyle) =>
            globalData.gridLines[signalId]
              ? tickStyle
                  .setLabelFont((font) => font.setSize(0))
                  .setTickLength(0)
              : tickStyle
                  .setGridStrokeStyle(emptyLine)
                  .setLabelFont((font) => font.setSize(0))
                  .setTickLength(0),
          ),
      );

    yAxisList[signalId].setTickStrategy(
      AxisTickStrategies.Numeric,
      (tickStrategy) =>
        tickStrategy
          .setMajorFormattingFunction((tickPosition) => {
            return NoYLabelSignals.includes(type)
              ? ''
              : Math.round(tickPosition).toString();
          })
          .setMinorFormattingFunction((tickPosition) => {
            return NoYLabelSignals.includes(type)
              ? ''
              : Math.round(tickPosition).toString();
          })
          .setMajorTickStyle((tickStyle) =>
            globalData.gridLines[signalId]
              ? tickStyle
              : tickStyle.setGridStrokeStyle(emptyLine),
          )
          .setMinorTickStyle((tickStyle) =>
            globalData.gridLines[signalId]
              ? tickStyle
              : tickStyle.setGridStrokeStyle(emptyLine),
          ),
    );
  }, []);

  const revertAutoScore = useCallback(() => {
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + globalData.token);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch(
      `${host}/sleepstudy/api/reverttoautoscore?key=${studyId}`,
      requestOptions,
    ).then((result) => {
      let l = Object.keys(events).length;
      const data = {};
      let artifacts = [];
      Object.keys(events).forEach((signalId) => {
        const signal = studySignals.find(
          (s) => s.SignalId.toString() === signalId,
        );
        fetch(
          `${host}/sleepstudy/api/signalsleepevents?signalId=${signalId}&startTime=${timeIntervalString.StartTime}&endTime=${timeIntervalString.EndTime}`,
          requestOptions,
        )
          .then((response) => response.json())
          .then((result) => {
            l--;
            const eventData = [];
            result.forEach((currentValue, i3) => {
              if (currentValue.Type.includes('Artifact')) {
                artifacts.push({
                  start:
                    new Date(currentValue.StartTime).getTime() - dateOriginTime,
                  end:
                    new Date(currentValue.EndTime).getTime() - dateOriginTime,
                  signalId: currentValue.SignalId,
                  status: currentValue.IsAutoScored ? 'AutoScored' : 'Added',
                  id: artifactId,
                  ...currentValue,
                });
                artifactId++;
                return;
              }
              const id = eventId;
              eventId++;
              const searchName =
                currentValue.Type === 'Recovery' ||
                currentValue.Type === 'Desaturation'
                  ? (signal.Type === 'Pulse' ? 'Pulse ' : 'Oximetry ') +
                    currentValue.Type
                  : currentValue.Type;
              eventData.push({
                status: currentValue.IsAutoScored ? 'AutoScored' : 'Added',
                type: currentValue.Type,
                customCharacteristics: currentValue.CustomCharacteristics,
                startTimeMicro:
                  new Date(currentValue.StartTime).getTime() - dateOriginTime,
                endTimeMicro:
                  new Date(currentValue.EndTime).getTime() - dateOriginTime,
                customName:
                  signal.SignalId === globalData.pulseSignalId &&
                  (currentValue.Type === 'Recovery' ||
                    currentValue.Type === 'Desaturation') &&
                  currentValue.CustomCharacteristics['DPAR Event Type']
                    ? currentValue.CustomCharacteristics['DPAR Event Type']
                    : undefined,
                ...currentValue,
                searchName,
                id,
              });
            });
            data[signalId] = eventData;
            if (l < 1) {
              globalData.loadedEventsData = data;
              redrawCharts = true;
              globalData.eventsUpdated = true;
              setEvents(data);
              setArtifacts(artifacts);
            }
          });
      });
    });
  }, [events, studyId]);

  const generatePdf = useCallback(async () => {
    setGeneratingPdf(true);
    try {
      if (globalData.eventsSaved) {
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + globalData.token);
        const res = await fetch('/pdf/' + globalData.analysisId, {
          method: 'POST',
          body: JSON.stringify({
            preprocessing: false,
          }),
        });
        const body = await res.json();
      }

      downloadEmployeeData(
        '/pdf/' + globalData.analysisId,
        globalData.guid + '.pdf',
      );
      //document.getElementById('pdf_link').click();
      setTimeout(() => {
        downloadEmployeeData(
          '/pdf/' + globalData.analysisId + '?multi=true',
          globalData.guid + '.multichannel.pdf',
        );
        setGeneratingPdf(false);
      }, 3000);
    } catch {
      setGeneratingPdf(false);
    }
  }, [setGeneratingPdf]);

  const unusedSignalsIds =
    signalIds && unusedSignals.map((i) => signalIds[i].SignalId);

  return (
    <>
      {loading && (
        <>
          <button
            className="btn btn-primary"
            type="button"
            disabled
            style={{ margin: '30px' }}
          >
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>{' '}
            Loading...
          </button>
        </>
      )}
      <div
        id="eventHoverChartContainer"
        style={{ display: 'none' }}
        className="eventHover"
      >
        <div id="eventHoverChart"></div>
        <div id="eventHoverText" className="eventHoverText"></div>
      </div>
      {!loading && (
        <div className={'ui_top ' + (generatingPdf ? 'blocked' : '')}>
          <div className="study_info">
            <div>Patient: {studyData?.PatientName} </div>
            <span
              style={{ fontSize: '13px', position: 'relative', top: '10px' }}
            >
              Study: {new Date(studyData?.SleepStudyDate).toLocaleDateString()}
            </span>
          </div>
          <div style={{ float: 'left' }}>
            <div className="redo_buttons">
              <Tooltip title={redoActive ? titles.redo : ''}>
                <RedoOutlined
                  onClick={() => {
                    if (redoActive) {
                      redrawCharts = true;
                      redoEvent();
                    }
                  }}
                  className={redoActive ? '' : 'disabled'}
                />
              </Tooltip>
              <Tooltip title={undoActive ? titles.undo : ''}>
                <UndoOutlined
                  onClick={() => {
                    if (undoActive) {
                      redrawCharts = true;
                      undoEvent();
                    }
                  }}
                  className={undoActive ? '' : 'disabled'}
                />
              </Tooltip>
            </div>
            <span style={{ display: 'inline-block', width: '180px' }}>
              <Dropdown
                className="play_mode_button"
                menu={{ items: playItems }}
              >
                <Button>
                  {speed
                    ? speedMultiplier !== 1
                      ? speedMultiplier > 0
                        ? `Fast forwarding at ${speedMultiplier}x`
                        : `Rewinding at ${speedMultiplier}x`
                      : 'Playing'
                    : 'Paused'}
                </Button>
              </Dropdown>
            </span>
          </div>
          <div></div>

          <div className="ui_buttons">
            <span className="play_buttons">
              <Tooltip title="Go to previous hour">
                <FastBackwardOutlined onClick={() => nextHour(false)} />
              </Tooltip>
              <Tooltip title="Rewind">
                <BackwardOutlined onClick={() => setPlay(-1)} />
              </Tooltip>
              <Tooltip title="Previous page">
                <VerticalRightOutlined onClick={() => nextPage(false)} />
              </Tooltip>
              {speed ? (
                <Tooltip title="Pause">
                  <PauseOutlined
                    className="active"
                    onClick={() => {
                      setPlay();
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Play">
                  <PlayCircleFilled
                    color="blue"
                    onClick={() => {
                      setPlay();
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip title="Next page">
                <VerticalLeftOutlined onClick={() => nextPage()} />
              </Tooltip>
              <Tooltip title="Fast forward">
                <ForwardOutlined
                  onClick={() => {
                    setPlay(1);
                  }}
                />
              </Tooltip>
              <Tooltip title="Go to next hour">
                <FastForwardFilled onClick={() => nextHour()} />
              </Tooltip>
            </span>

            <Dropdown
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Space style={{ padding: '0 ' }}>
                    <Radio
                      checked={selectedTimeInterval.label === 'custom'}
                      onChange={(e) => {
                        const value = parseInt(
                          document.getElementById('custom_time_interval').value,
                        );
                        setSelectedTimeTinterval({
                          label: 'custom',
                          value: value,
                        });
                        updateInterval(value * 60);
                      }}
                    />{' '}
                    <Input
                      onChange={(e) => {
                        setSelectedTimeTinterval({
                          label: 'custom',
                          value: e.target.value * 60,
                        });
                        updateInterval(e.target.value * 60);
                      }}
                      defaultValue={1}
                      value={selectedTimeInterval.value / 60}
                      id="custom_time_interval"
                      type="number"
                    />
                  </Space>
                </>
              )}
              className="time_interval_mode_button"
              menu={{ items: timeIntervalItems }}
            >
              <Button>
                {minutesNumber < 1
                  ? '30 seconds view'
                  : minutesNumber + ' minutes view'}
              </Button>
            </Dropdown>
            <Button
              onClick={() => {
                revertAutoScore();
                removeHistory();
              }}
              className="revert_button"
            >
              Revert to Auto Score
            </Button>
          </div>

          <div className="bottom_buttons">
            <span className="scoring_buttons">
              {/* <Button id="save_scoring_button" className="disabled" onClick={() => saveData()}>
                Save Scoring
              </Button> 
              <Button
                id="save_scoring_report_button"
                className="disabled"
                onClick={() => saveData(studyData)}
              >
                Run Report
              </Button>*/}
              <span
                className={
                  'btn btn-sm  ' +
                  (generatingPdf ? 'btn-success' : 'btn-danger')
                }
                onClick={(e) => {
                  generatePdf();
                }}
                title="Download PDF"
              >
                <FontAwesomeIcon
                  className={'fa-fw ' + (generatingPdf ? 'fa-spin' : '')}
                  icon={generatingPdf ? faSpinner : faFilePdf}
                />
              </span>
            </span>
            <Button
              className="save_preferences_button"
              onClick={() => {
                saveSignals(
                  events,
                  studyId,
                  viewConfigurationData,
                  studySignals,
                  chartRanges,
                  chartSizes,
                  globalData.chartColors,
                  zoomSignalId,
                  globalData.additionalLines,
                  unusedSignalsIds,
                  visibleEvents,
                );
              }}
            >
              Save Display Preferences
            </Button>
            Search
            <Select
              style={{ width: '180px', margin: '0 20px' }}
              value={
                searchEvent
                  ? {
                      value: searchEvent,
                      label: searchEvent.replace(/([a-z])([A-Z])/g, '$1 $2'),
                    }
                  : null
              }
              listHeight={500}
              virtual={false}
              onChange={(value, option) => {
                setSearchEvent(value);
                setSelectedSearchEvent(null);
              }}
              options={
                globalData.searchOptions ||
                Object.keys(globalData.eventsTyped)
                  .sort()
                  .map((t) => ({
                    label: t,
                    value: t,
                  }))
              }
            />
            {searchEvent && (
              <span style={{ fontSize: '12px' }}>
                {globalData.eventsTyped[searchEvent]?.length || 0} events
              </span>
            )}
            {searchEvent && (
              <span className="searchEventButtons">
                <DoubleLeftOutlined
                  onClick={() => {
                    findEvent('first');
                  }}
                />
                <LeftOutlined
                  onClick={() => {
                    findEvent('prev');
                  }}
                />
                <RightOutlined
                  onClick={() => {
                    findEvent('next');
                  }}
                />
                <DoubleRightOutlined
                  onClick={() => {
                    findEvent('last');
                  }}
                />
              </span>
            )}
            <span>
              <CustomDropdown
                props={{
                  eventVisibleSelectorClick,
                  eventVisibleItems,
                  visibleEvents,
                }}
              />
            </span>
            <span className="add_buttons">
              <Button
                id="add_event_button"
                className=""
                onClick={() => addEventActivate()}
              >
                Add event
              </Button>
              <Button
                id="add_artifact_button"
                className=""
                onClick={() => addArtifactActivate()}
              >
                Add artifact
              </Button>
            </span>
          </div>
          <span className="bodyContainer disabled">
            {!positionSignal && (
              <span id="nodata_image">
                No
                <br /> Position
                <br /> Data
              </span>
            )}
            <img id="body" src={body} alt="body" width={90} />
          </span>
        </div>
      )}
      <div
        onMouseEnter={() => {
          const listElement = document.getElementById('event_selector_list');
          listElement.classList.add('show2');
        }}
        onMouseLeave={() => {
          const listElement = document.getElementById('event_selector_list');
          listElement.classList.remove('show2');
        }}
        id="event_selector_list"
      >
        {eventVisibleItems &&
          eventVisibleItems.map((item) => (
            <div key={item.key}>{item.label}</div>
          ))}
      </div>

      <div className={'chart ' + (generatingPdf ? 'blocked' : '')}>
        <ChartIcons
          signalsData={signalsData}
          setChartSettingsModal={setChartSettingsModal}
          signalPairs={signalPairs}
          loading={loading}
          chartRanges={chartRanges}
          chartColors={globalData.chartColors}
          chartSizes={chartSizes}
          unusedSignalsIds={unusedSignalsIds}
          addCharts={addCharts}
          allChartData={allChartData}
          selectedCharts={selectedCharts}
          rowHeight={rowHeight}
          switchCharts={switchCharts}
          getAutoScale={(s) => getAutoScale(s, zoomIntervalGlobal)}
          moveSourceChart={moveSourceChart}
          moveSecondChart={moveSecondChart}
          addSecondCharts={addSecondCharts}
          signalIds={signalIds}
          addFromDouble={addFromDouble}
          chartsDataUpdated={chartsDataUpdated}
          setChartRanges={(signalId, range) => {
            chartsDataUpdated = chartsDataUpdated + 1;
            updateChartsRange({ [signalId]: range });
            // if (globalData.eventTopCustom[signalId]) {
            //   redrawSignal(signalId, true);
            // }
          }}
          removeSecondSignal={(singalId) => {
            chartsDataUpdated = chartsDataUpdated + 1;
            signalChartsList[singalId].dispose();
          }}
          selectedChartIds={selectedChartIds}
          addHistoryEvent={addHistoryEvent}
          setSelectedCharts={setSelectedCharts}
          setZoomSignal={setZoomSignalId}
          zoomSignalId={zoomSignalId}
          additionalLines={globalData.additionalLines}
          setHistogramColor={setHistogramColor}
          updateChartsOrder={(charts) => {
            addHistoryEvent({
              type: 'chartOrder',
              prev: selectedCharts,
              next: charts,
              title: 'charts order change',
            });
            updateChartsOrder(charts);
          }}
        />

        <div
          onMouseLeave={() => setHoverElement(null)}
          onWheel={(e) => nextPage(e.deltaY > 0)}
          className={generatingPdf ? 'blocked' : ''}
          id="chartContainer"
        ></div>
      </div>
      {eventSize.start && interval.start && (
        <Modal
          title={eventEditMode ? 'Change Event' : 'Add Event'}
          centered
          open={Boolean(modalOpen)}
          onCancel={() => addEvent(true)}
          footer={[
            eventEditMode && (
              <Button
                danger
                key="delete"
                disabled={!selectedEvent}
                onClick={() => deleteEvent()}
              >
                Delete
              </Button>
            ),
            <Button
              type="primary"
              key="ok"
              disabled={!selectedEvent}
              onClick={() => addEvent()}
            >
              Ok
            </Button>,
            <Button key="back" onClick={() => addEvent(true)}>
              Cancel
            </Button>,
          ]}
        >
          <Select
            placeholder="Select an event"
            optionFilterProp="children"
            onChange={onChange}
            bordered={false}
            style={{ backgroundColor: 'transparent' }}
            value={
              selectedEvent?.newText
                ? { label: selectedEvent.newText, value: selectedEvent.newText }
                : selectedEvent?.text
                ? { label: selectedEvent.text, value: selectedEvent.text }
                : null
            }
            dropdownMatchSelectWidth={false}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={
              modalOpen
                ? eventTypes[modalOpen].map((t) => ({ value: t, label: t }))
                : []
            }
          />
          <div>
            <span className="eventDatePickerLabel">Chart top value</span>
            <span>
              <DatePicker
                defaultValue={dayjs(interval.start.getTime() + eventSize.start)}
                key={eventSize.start}
                onChange={(v, s) => {
                  newEventSize.start = v.$d;
                }}
                renderExtraFooter={() => 'extra footer'}
                showTime
              />
            </span>
          </div>
          <div className="">
            <span className="eventDatePickerLabel">Chart bottom value</span>
            <span>
              <DatePicker
                defaultValue={dayjs(interval.start.getTime() + eventSize.end)}
                key={eventSize.end}
                onChange={(v, s) => {
                  newEventSize.end = v.$d;
                }}
                showTime
              />
            </span>
          </div>
          {selectedEvent &&
            customEventFields(
              selectedEvent?.newText || '',
              selectedEvent.customCharacteristics,
              (field, value) => {
                newEventSize.customFields = {
                  ...newEventSize.customFields,
                  [field]: value,
                };
              },
            )}
        </Modal>
      )}
      {selectedArtifact && (
        <Modal
          title="Change Artifact"
          centered
          open={Boolean(selectedArtifact)}
          onOk={() => {}}
          onCancel={() => {}}
          footer={[
            <Button
              key="delete"
              danger
              onClick={() => handleArtifactModal(true)}
            >
              Delete
            </Button>,
            <Button key="back" onClick={() => handleArtifactModal()}>
              Cancel
            </Button>,
          ]}
        ></Modal>
      )}

      <ChartSettingsModal
        chartColors={globalData.chartColors}
        signalPairs={signalPairs}
        chartSettingsModal={chartSettingsModal}
        setChartSettingsModal={setChartSettingsModal}
        additionalLines={globalData.additionalLines}
        selectedCharts={selectedCharts}
        setChartGrid={setChartGrid}
        setChartRanges={updateChartsRange}
        setChartColors={(signalId, color) => {
          globalData.chartColors = {
            ...globalData.chartColors,
            [signalId]: color,
          };
          lineSteps = getLineSteps(globalData.loadedEventsData, true);
          if (signalChartsList[signalId]) {
            if (lineSteps[signalId] && lineSteps[signalId].length > 1) {
              // lineSteps[signalId].sort((a, b) => a.value - b.value);
              signalChartsList[signalId].setStrokeStyle(
                new SolidLine({
                  thickness: 2,
                  fillStyle: new PalettedFill({
                    lookUpProperty: 'x',
                    lut: new LUT({
                      interpolate: false,
                      steps: lineSteps[signalId],
                    }),
                  }),
                }),
              );
            } else {
              signalChartsList[signalId].setStrokeStyle(
                new SolidLine({
                  thickness: 2,
                  fillStyle: new SolidFill({ color: ColorHEX(color) }),
                }),
              );
            }
          }
        }}
        switchCharts={switchCharts}
        addCharts={addCharts}
        chartRanges={chartRanges}
        gridLines={globalData.gridLines}
        chartSizes={chartSizes}
        setChartSizes={(sizes) => {
          setChartSizes(sizes);
          redrawCharts = true;
          chartsDataUpdated++;
        }}
        getAutoScale={(s) => getAutoScale(s, zoomIntervalGlobal)}
        updateChartsOrder={(charts) => {
          addHistoryEvent({
            type: 'chartOrder',
            prev: selectedCharts,
            next: charts,
            title: 'charts order change',
          });
          updateChartsOrder(charts);
        }}
        addHistoryEvent={addHistoryEvent}
      />

      <Modal
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={false}
            onClick={() => setShowReportRunModal(false)}
          >
            Ok
          </Button>,
        ]}
        open={showReportRunModal}
        centered
      >
        Scoring is saved. Wait for the report loading ...
      </Modal>
    </>
  );
}

export default Charts;
