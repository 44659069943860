import React, { Component } from 'react'
import MaskedInput from 'react-maskedinput'
import $ from 'jquery'
import Edit from './edit'
import Alert from './alert'

class Organization extends Component {

	constructor(props){
		super(props)
		this.path = '/organizations'
		this.url = props.location.match.url
		this.state = {
			locked: this.props.organization.id !== 0,
			data: this.props.organization,
			alertMessage: null,
			alertType: 'alert-success',
		}
	}

	componentDidUpdate(prevProps,prevState){
		var organization = this.props.organization
		var pathId = parseInt(this.props.location.match.params.id)
		if ( this.props.organization.id !== pathId ){
			this.url = this.path + '/' + this.props.organization.id
			this.props.location.history.push(this.url)
			this.read()
		}
		if (prevProps.organization !== organization){
			this.setState({
				data: organization,
				locked: organization.id !== 0
			})
		}
	}

	submit(event){
		if (event) event.preventDefault()
		this.state.data.id > 0 ? this.update() : this.create()
	}

	create(){
		var success = this.handleData.bind(this)
		var failure = this.errorHandler.bind(this)
		var data = this.state.data
		if (!data.name){
			this.showAlert("Name is required", 'alert-danger')
			return
		}
		if (!data.email){
			this.showAlert("Email is required", 'alert-danger')
			return
		}
		if (!data.phone){
			this.showAlert("Phone number is required", 'alert-danger')
			return
		}
		$.ajax({
			method: 'POST',
			url: this.path,
			data: {
				name: data.name,
				adress: data.address,
				email: data.email,
				phone: data.phone,
				fax: data.fax,
				npi: data.npi
			}
		}).done(success).fail(failure)
	}

	read(){
		if (this.props.organization.id === 0){
			return this.setState({
				organization: { id: 0 },
				locked: true
			})
		}
		var success = this.handleData.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'GET',
			url: this.url
		}).done(success).fail(failure)
	}

	update(){
		var success = this.handleData.bind(this)
		var failure = this.errorHandler.bind(this)
		var data = this.state.data
		$.ajax({
			method: 'PUT',
			url: this.url,
			data: {
				name: data.name,
				email: data.email,
				phone: data.phone,
				fax: data.fax,
				npi: data.npi
			}
		}).done(success).fail(failure)
	}

	destroy(){
		var success = this.handleData.bind(this)
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'DELETE',
			url: this.url,
		}).done(success).fail(failure)
	}

	changeHandler(event){
 		var data = this.state.data
 		data[event.target.name] = event.target.value
 		this.setState({ data: data })
 	}

	handleData(data){
		this.setState({ 
			data: data,
            locked: true
        })
        this.showAlert('Organization Saved', 'alert-success')
		this.props.getOrganizations()
	}

	errorHandler(error){
		var body = error.responseJSON
		this.showAlert(body.error.message, 'alert-danger')
	}

	showAlert(message, type){
		this.setState({
			alertMessage: message,
			alertType: type,
		})
	}

	unlock(){
		this.setState({ locked: false })
	}

	removeAlertHandler(){
		this.setState({
			alertMessage: null
		})
	}

 	render(){
		var actions = {
			submit: this.submit.bind(this),
			read: this.read.bind(this),
			destroy: this.destroy.bind(this),
			unlock: this.unlock.bind(this)
		}
 		var handleChange = this.changeHandler.bind(this)
 		var locked = this.state.locked
 		var data = this.state.data
 		var removeHandler = this.removeAlertHandler.bind(this)
		return (
			<div className='card'>
				<form onSubmit={ actions.submit } >
					<div className='card-header'>
						<div className='row align-items-center justify-content-between'>
							<div className='col'>
								<span className='h4'>Organization</span>
							</div>
							<div className='col text-right'>
								<Edit actions={actions} data={data} locked={locked}/>
							</div>
						</div>
					</div>
					<div className='card-body'>
						<div className='form-row'>
							<div className='form-group col'>
								<label>* Name</label>
								<input className='form-control' 
									name='name' 
									type='text'
									value={ data.name || '' }
									onChange={handleChange} 
									disabled={locked} />
							</div>
							<div className='form-group col'>
								<label>* Email</label>
								<input className='form-control' 
									name='email' 
									type='email'
									value={ data.email || '' }
									onChange={ handleChange }
									disabled={ locked }/>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col'>
								<label>* Phone Number</label>
								<MaskedInput className='form-control'
									name='phone'
									type='text'
									value={ data.phone || '' }
									mask='(111)-111-1111'
									onChange={ handleChange }
									disabled={ locked } />
							</div>
							<div className='form-group col'>
								<label>Fax Number</label>
								<MaskedInput className='form-control'
									name='fax'
									type='text'
									value={ data.fax || '' }
									mask='(111)-111-1111'
									onChange={ handleChange }
									disabled={ locked } />
							</div>
							<div className='form-group col'>
								<label>NPI</label>
								<input className='form-control' 
									name='npi' 
									type='text'
									value={ data.npi || '' }
									onChange={ handleChange }
									disabled={ locked }/>
							</div>
						</div>
					</div>
                </form>
                <Alert message={this.state.alertMessage} type={this.state.alertType} onExit={removeHandler} />
			</div>
		)
 	}

}

export default Organization