import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import Alert from './alert'

import $ from 'jquery'

class ReportAlertSettings extends Component {

    constructor(props) {
        super(props)
        // Naming convention for all fields must follow this convention:
        // category + tier + rangePlacement
        this.state = {
            organization: {},
            RDIMildAlert: true,
            RDIModerateAlert: true,
            RDISevereAlert: true,
            CTMildAlert: true,
            CTModerateAlert: true,
            CTSevereAlert: true,
            BDMildAlert: true,
            BDSevereAlert: true,
            hasInvalidValues: false,
            alertType: 'alert-success',

            changesMade: false,
            saveConfirmText: '',
            saveVerified: false,
            modalContent: '',

            showPostOP: false,
            showRDIAlert: true,
            showCTAlert: false,
            showBDAlert: true,
            fields: {
                RDIMildLow: {
                    category: 'RDI',
                    tier: 'Mild',
                    value: 5,
                    rangePlacement: 'Low'
                },
                RDIMildHigh: {
                    category: 'RDI',
                    tier: 'Mild',
                    value: 15,
                    rangePlacement: 'High'
                },
                RDIModerateLow: {
                    category: 'RDI',
                    tier: 'Moderate',
                    value: 15,
                    rangePlacement: 'Low'
                },
                RDIModerateHigh: {
                    category: 'RDI',
                    tier: 'Moderate',
                    value: 30,
                    rangePlacement: 'High'
                },
                RDISevereLow: {
                    category: 'RDI',
                    tier: 'Severe',
                    value: 30,
                    rangePlacement: 'Low'
                },
                BDMildLow: {
                    category: 'BD',
                    tier: 'Mild',
                    value: 88,
                    rangePlacement: 'Low'
                },
                BDMildHigh: {
                    category: 'BD',
                    tier: 'Mild',
                    value: 92,
                    rangePlacement: 'High'
                },
                BDSevereHigh: {
                    category: 'BD',
                    tier: 'Severe',
                    value: 88,
                    rangePlacement: 'High'
                },
                CTMildLow: {
                    category: 'CT',
                    tier: 'Mild',
                    value: 0,
                    rangePlacement: 'Low'
                },
                CTMildHigh: {
                    category: 'CT',
                    tier: 'Mild',
                    value: 0,
                    rangePlacement: 'High'
                },
                CTModerateLow: {
                    category: 'CT',
                    tier: 'Moderate',
                    value: 0,
                    rangePlacement: 'Low'
                },
                CTModerateHigh: {
                    category: 'CT',
                    tier: 'Moderate',
                    value: 0,
                    rangePlacement: 'High'
                },
                CTSevereLow: {
                    category: 'CT',
                    tier: 'Severe',
                    value: 0,
                    rangePlacement: 'Low'
                },
                ODI4SevereLow: {
                    category: 'ODI4',
                    tier: 'Severe',
                    value: 28.5,
                    rangePlacement: 'Low'
                },
                CT90SevereLow: {
                    category: 'CT90',
                    tier: 'Severe',
                    value: 7.2,
                    rangePlacement: 'Low'
                },
                MeanSPO2SevereHigh: {
                    category: 'MeanSp02',
                    tier: 'Severe',
                    value: 92.7,
                    rangePlacement: 'Low'
                },
            },
        }
    }

    componentDidMount() {
        this.getOrganization()
    }

    getOrganization() {
        var success = this.handleData.bind(this)
        var failure = this.errorHandler.bind(this)
        $.ajax({
            method: 'GET',
            url: '/organizations/' + this.props.organization.id
        }).done(success).fail(failure)
    }

    handleData(data) {
        let settings = data.reportAlertSettings
        if (typeof settings === 'string'){
            settings = JSON.parse(settings)
        }
        if (!settings) {
            settings = {
                showPostOP: false,
                showRDIAlert: true,
                showCTAlert: false,
                showBDAlert: true,
                fields: this.state.fields
            }
        }
        let modifiedFields = this.state.fields
        let fields = settings.fields
        for (const category in fields) {
            let value = fields[category];
            if (!value) {
                value = 0
            }
            if (modifiedFields[category]) {
                modifiedFields[category].value = parseFloat(value)
            }
        }
        this.setState({
            organization: data,
            fields: modifiedFields,
            showBDAlert: settings.showBDAlert,
            showCTAlert: settings.showCTAlert,
            showPostOP: settings.showPostOP,
            showRDIAlert: settings.showRDIAlert
        })
    }

    errorHandler(error) {
        var body = error.responseJSON
        console.log(body)
    }

    initAlertMessage(message, type) {
        this.setState({
            alertMessage: message,
            alertType: type
        })
    }

    removeAlertHandler() {
        this.setState({
            alertMessage: null
        })
    }

    save() {
        const success = this.saveConfirmed.bind(this)
        let newAlertSettings = {
            showPostOP: this.state.showPostOP,
            showRDIAlert: this.state.showRDIAlert,
            showCTAlert: this.state.showCTAlert,
            showBDAlert: this.state.showBDAlert,
            fields: {}
        }
        for (const field in this.state.fields) {
            const element = this.state.fields[field];
            newAlertSettings.fields[field] = parseFloat(element.value)
        }
        $.ajax({
            method: 'PUT',
            url: '/organizations/' + this.props.organization.id,
            data: {
                reportAlertSettings: JSON.stringify(newAlertSettings)
            }
        }).done(success)
    }

    verifySave(event) {
        if (event.target.value === 'CONFIRM') {
            this.setState({
                saveVerified: true,
                saveConfirmText: event.target.value
            })
        } else {
            this.setState({
                saveVerified: false,
                saveConfirmText: event.target.value
            })
        }
    }

    saveConfirmed() {
        this.setState({
            alertType: 'alert-success',
            alertMessage: 'Save Successful',
            changesMade: false,
            saveConfirmText: ''
        })
    }

    initiateSaveConfirmation() {
        if (this.state.hasInvalidValues) {
            this.setState({
                alertMessage: 'Please correct all settings errors before saving',
                alertType: 'alert-danger'
            })
        } else {
            this.save()
        }
    }

    setAlerts() {
        let newAlertState = {
            RDIMildAlert: true,
            RDIModerateAlert: true,
            RDISevereAlert: true,
            CTMildAlert: true,
            CTModerateAlert: true,
            CTSevereAlert: true,
            BDMildAlert: true,
            BDSevereAlert: true,
            hasInvalidValues: false

        }
        // Loop through all fields in state
        for (const field in this.state.fields) {
            if (Object.hasOwnProperty.call(this.state.fields, field)) {
                const data = this.state.fields[field];
                const alertState = data.category + data.tier + 'Alert'

                let mildLow = data.category + 'MildLow'
                let mildHigh = data.category + 'MildHigh'
                let moderateLow = data.category + 'ModerateLow'
                let moderateHigh = data.category + 'ModerateHigh'
                if (data.category === 'BD') {
                    if (data.rangePlacement === 'High' && data.tier === 'Mild') {
                        const opposingPlacement = data.category + data.tier + 'Low'
                        if (this.state.fields[opposingPlacement].value > data.value) {
                            newAlertState[alertState] = false
                            newAlertState.hasInvalidValues = true
                        }
                    } else if (data.rangePlacement === 'Low') {
                        const highPlacement = data.category + data.tier + 'High'
                        if (this.state.fields[highPlacement].value < data.value) {
                            newAlertState[alertState] = false
                            newAlertState.hasInvalidValues = true
                        }
                    } else if (data.tier === 'Severe') {
                        if (data.value > this.state.fields[mildLow].value ||
                            data.value > this.state.fields[mildHigh].value) {
                            newAlertState[alertState] = false
                            newAlertState.hasInvalidValues = true
                        }
                    }
                } else {
                    if (data.tier === 'Mild') {
                        // If mildLow is greater than mildHigh -- alert
                        if (data.rangePlacement === 'Low') {
                            let opposingPlacement = data.category + data.tier + 'High'
                            if (this.state.fields[opposingPlacement].value < data.value) {
                                newAlertState[alertState] = false
                                newAlertState.hasInvalidValues = true
                            }
                            // If mildHigh is greater than moderateLow -- alert
                        } else {
                            if (this.state.fields[moderateLow].value < data.value) {
                                newAlertState[alertState] = false
                                newAlertState.hasInvalidValues = true
                            }
                        }
                    } else if (data.tier === 'Moderate') {
                        // If moderateLow is greater than moderateHigh -- alert
                        if (data.rangePlacement === 'Low') {
                            if (data.value > this.state.fields[moderateHigh].value) {
                                newAlertState[alertState] = false
                                newAlertState.hasInvalidValues = true
                            }
                        }
                        // If moderateHigh is greater than Severe -- alert
                    } else if (data.tier === 'Severe') {
                        if (this.state.fields[moderateLow]) {
                            if (data.value < this.state.fields[mildLow].value ||
                                data.value < this.state.fields[mildHigh].value ||
                                data.value < this.state.fields[moderateHigh].value ||
                                data.value < this.state.fields[moderateLow].value) {
                                newAlertState[alertState] = false
                                newAlertState.hasInvalidValues = true
                            }
                        } else if (this.state.fields[mildLow]) {
                            if (data.value < this.state.fields[mildLow].value ||
                                data.value < this.state.fields[mildHigh].value) {
                                newAlertState[alertState] = false
                                newAlertState.hasInvalidValues = true
                            }
                        }
                    }
                }

            }
        }
        this.setState(
            newAlertState
        )
    }

    toggleAlertAppearance(event) {
        $('#toggleAlertDropdown').dropdown('toggle')
        this.setState({
            [event.target.id]: !this.state[event.target.id],
            changesMade: true
        })
    }

    changeHandler(event) {
        const changingField = this.state.fields[event.target.name]
        var fields = this.state.fields
        changingField.value = parseFloat(event.target.value)
        if (changingField.value > 100) changingField.value = 100
        if (changingField.value < 0) changingField.value = 0
        if (!changingField.value) changingField.value = 0
        fields[event.target.name] = changingField
        this.setState({
            fields: fields,
            changesMade: true
        }, this.setAlerts.bind(this))
    }

    resetToDefaults() {
        this.setState({
            showPostOP: false,
            showRDIAlert: true,
            showCTAlert: false,
            showBDAlert: true,
            fields: {
                RDIMildLow: {
                    category: 'RDI',
                    tier: 'Mild',
                    value: 5,
                    rangePlacement: 'Low'
                },
                RDIMildHigh: {
                    category: 'RDI',
                    tier: 'Mild',
                    value: 15,
                    rangePlacement: 'High'
                },
                RDIModerateLow: {
                    category: 'RDI',
                    tier: 'Moderate',
                    value: 15,
                    rangePlacement: 'Low'
                },
                RDIModerateHigh: {
                    category: 'RDI',
                    tier: 'Moderate',
                    value: 30,
                    rangePlacement: 'High'
                },
                RDISevereLow: {
                    category: 'RDI',
                    tier: 'Severe',
                    value: 30,
                    rangePlacement: 'Low'
                },
                BDMildLow: {
                    category: 'BD',
                    tier: 'Mild',
                    value: 88,
                    rangePlacement: 'Low'
                },
                BDMildHigh: {
                    category: 'BD',
                    tier: 'Mild',
                    value: 92,
                    rangePlacement: 'High'
                },
                BDSevereHigh: {
                    category: 'BD',
                    tier: 'Severe',
                    value: 88,
                    rangePlacement: 'High'
                },
                CTMildLow: {
                    category: 'CT',
                    tier: 'Mild',
                    value: 0,
                    rangePlacement: 'Low'
                },
                CTMildHigh: {
                    category: 'CT',
                    tier: 'Mild',
                    value: 0,
                    rangePlacement: 'High'
                },
                CTModerateLow: {
                    category: 'CT',
                    tier: 'Moderate',
                    value: 0,
                    rangePlacement: 'Low'
                },
                CTModerateHigh: {
                    category: 'CT',
                    tier: 'Moderate',
                    value: 0,
                    rangePlacement: 'High'
                },
                CTSevereLow: {
                    category: 'CT',
                    tier: 'Severe',
                    value: 0,
                    rangePlacement: 'Low'
                },
                ODI4SevereLow: {
                    category: 'ODI4',
                    tier: 'Severe',
                    value: 28.5,
                    rangePlacement: 'Low'
                },
                CT90SevereLow: {
                    category: 'CT90',
                    tier: 'Severe',
                    value: 7.2,
                    rangePlacement: 'Low'
                },
                MeanSPO2SevereHigh: {
                    category: 'MeanSp02',
                    tier: 'Severe',
                    value: 92.7,
                    rangePlacement: 'Low'
                },
            },
            changesMade: true
        })
    }

    render() {
        let handleChange = this.changeHandler.bind(this)
        let removeHandler = this.removeAlertHandler.bind(this)
        let save = this.initiateSaveConfirmation.bind(this)
        let verifySave = this.verifySave.bind(this)

        let RDILowGray = this.state.fields.RDIMildLow.value === 0 && this.state.fields.RDIMildHigh.value === 0 ? 'lightGray' : ''
        let RDIModerateGray = this.state.fields.RDIModerateLow.value === 0 && this.state.fields.RDIModerateHigh.value === 0 ? 'lightGray' : ''
        let RDISevereGray = this.state.fields.RDISevereLow.value === 0? 'lightGray' : ''
        let CTLowGray = this.state.fields.CTMildLow.value === 0 && this.state.fields.CTMildHigh.value === 0 ? 'lightGray' : ''
        let CTModerateGray = this.state.fields.CTModerateLow.value === 0 && this.state.fields.CTModerateHigh.value === 0 ? 'lightGray' : ''
        let CTSevereGray = this.state.fields.CTSevereLow.value === 0? 'lightGray' : ''
        return (
            <div>
                <div className="modal fade"
                    id="saveConfirm"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="saveConfirmLabel"
                    aria-hidden='false'>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <span>WARNING: Default Alert settings are set to evidence
                                based data and changing this setting may increase risk
                                of missed referrals or missed diagnosis. If you are sure you want to save changed,
                        please type 'CONFIRM' in the field below.</span>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='input-group'>
                                        <label className='ml-4'>Please type 'CONFIRM'</label>
                                        <div className='input-group'>
                                            <input type="text"
                                                className="form-control ml-4 mr-4"
                                                onChange={verifySave}
                                                name='saveConfirm'
                                                value={this.state.saveConfirmText}>
                                            </input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button"
                                    disabled={!this.state.saveVerified}
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={save}>
                                    Save changes
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade"
                    id="reset"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="resetLabel"
                    aria-hidden='false'>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <span>This will reset all custom settings to the default. Are you sure?</span>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                                <button type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={this.resetToDefaults.bind(this)}>
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card mt-2'>
                    
                    <div className='card-body'>
                        <Alert message={this.state.alertMessage} type={this.state.alertType} onExit={removeHandler} />
                        <div>
                            <h5>Report Alert Settings</h5>
                            <div className="dropdown mt-2 mb-2">
                                <button className="btn btn-outline-primary dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    id='toggleAlertDropdown'>
                                    Enable/Disable
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <div className="border border-muted rounded custom-control custom-switch switch-info">
                                        <input type="checkbox" className="ml-2 mr-2 custom-control-input" id='showPostOP'
                                            onChange={this.toggleAlertAppearance.bind(this)}
                                            checked={this.state.showPostOP} />
                                        <label className="custom-control-label" htmlFor="showPostOP">
                                            Post-Op Adverse Event Risk</label>
                                    </div>
                                    <div className="border border-muted rounded custom-control custom-switch switch-info">
                                        <input type="checkbox" className="ml-2 mr-2 custom-control-input" id='showRDIAlert'
                                            onChange={this.toggleAlertAppearance.bind(this)}
                                            checked={this.state.showRDIAlert} />
                                        <label className="custom-control-label" htmlFor="showRDIAlert">
                                            RDI alerts</label>
                                    </div>
                                    <div className="border border-muted rounded custom-control custom-switch switch-info">
                                        <input type="checkbox" className="ml-2 mr-2 custom-control-input" id='showCTAlert'
                                            onChange={this.toggleAlertAppearance.bind(this)}
                                            checked={this.state.showCTAlert} />
                                        <label className="custom-control-label" htmlFor="showCTAlert">
                                            CT alerts</label>
                                    </div>
                                    <div className="border border-muted rounded custom-control custom-switch switch-info">
                                        <input type="checkbox" className="ml-2 mr-2 custom-control-input" id='showBDAlert'
                                            onChange={this.toggleAlertAppearance.bind(this)}
                                            checked={this.state.showBDAlert} />
                                        <label className="custom-control-label" htmlFor="showBDAlert">
                                            BD alerts</label>
                                    </div>
                                </div>
                                <button className='btn btn-outline-secondary ml-2'
                                    data-toggle='modal' data-target='#reset'>
                                    Reset To Default <FontAwesomeIcon icon={faUndoAlt} />
                                </button>
                                <button className='btn btn-outline-success ml-2'
                                    data-toggle='modal' data-target='#saveConfirm'
                                    hidden={!this.state.changesMade}>
                                    Save Changes <FontAwesomeIcon icon={faSave} />
                                </button>

                            </div>


                            {/* RDI Thresholds */}
                            <div className='row border border-muted rounded'>
                                <div className='col mb-2' >
                                    <label className='mt-2'><i className={`fa fa-info-circle`}
                                        data-tip data-for='rdiInfo'>
                                    </i> RDI low alert threshold <i className={`far fa-exclamation-triangle`}
                                        data-tip="The low threshold value must not exceed a higher threshold value."
                                        style={{ color: 'red' }}
                                        hidden={this.state.RDIMildAlert}></i>
                                        <ReactTooltip id='rdiInfo' effect={'solid'} multiline={true}>
                                            <p>"RDI", or respiratory disturbance index, is the average number of “2-4% Desat (RERA)” events and “{'>'}4% Desat”
                                            <br />events per hour of valid recording time (VRT).</p>
                                            <p><b>References</b> for Default Alert Thresholds:</p>
                                            <p>1. American Academy of Sleep Medicine Task Force. Sleep-related breathing disorders in adults:
                                            <br />Recommendations for syndrome definition and measurement techniques in clinical research.
                                            <br />The Report of an American Academy of Sleep Medicine Task Force. Sleep. 1999; 22:667–89.</p>
                                            <p>2. International Classification of Sleep Disorders, Third Edition (ICSD-3)</p>
                                        </ReactTooltip>
                                    </label>

                                    <div className='input-group' >
                                        <span className='input-group-text col'>Between</span>
                                        <input type="number"
                                            style={{ backgroundColor: RDILowGray }}
                                            className="form-control"
                                            onChange={handleChange}
                                            name='RDIMildLow'
                                            value={this.state.fields.RDIMildLow.value}></input>

                                        <span className='input-group-text col'>and</span>
                                        <input type="number"
                                            style={{ backgroundColor: RDILowGray }}
                                            name='RDIMildHigh'
                                            onChange={handleChange}
                                            className="form-control"
                                            value={this.state.fields.RDIMildHigh.value}></input>
                                    </div>
                                </div>
                                <div className='col mb-2'>
                                    <label className='mt-2'>RDI Moderate alert threshold <i className={`far fa-exclamation-triangle`}
                                        data-tip="The low threshold value must not exceed a higher threshold value."
                                        style={{ color: 'red' }}
                                        hidden={this.state.RDIModerateAlert}></i></label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Between</span>

                                        <input type="number"
                                            style={{ backgroundColor: RDIModerateGray }}
                                            className="form-control"
                                            onChange={handleChange}
                                            name='RDIModerateLow'
                                            value={this.state.fields.RDIModerateLow.value}></input>

                                        <span className='input-group-text col'>and</span>
                                        <input type="number"
                                            style={{ backgroundColor: RDIModerateGray }}
                                            name='RDIModerateHigh'
                                            onChange={handleChange}
                                            className="form-control"
                                            value={this.state.fields.RDIModerateHigh.value}></input>
                                    </div>
                                </div>

                                <div className='col mb-2'>
                                    <label className='mt-2'>RDI high alert threshold <i className={`far fa-exclamation-triangle`}
                                        data-tip="The low threshold value must not exceed a higher threshold value."
                                        style={{ color: 'red' }}
                                        hidden={this.state.RDISevereAlert}></i></label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Greater than</span>

                                        <input type="number"
                                        style={{ backgroundColor: RDISevereGray }}
                                            className="form-control"
                                            onChange={handleChange}
                                            name='RDISevereLow'
                                            value={this.state.fields.RDISevereLow.value}></input>
                                    </div>
                                </div>
                            </div>

                            {/* CT Thresholds */}
                            <div className='row border border-muted rounded'>
                                <div className='col mb-2'>
                                    <label className='mt-2'> <i className={`fa fa-info-circle`}
                                        data-tip
                                        data-for='ctInfo'></i> CT% low alert threshold <i className={`far fa-exclamation-triangle`}
                                            data-tip="The low threshold value must not exceed a higher threshold value."
                                            style={{ color: 'red' }}
                                            hidden={this.state.CTMildAlert}></i>
                                        <ReactTooltip id='ctInfo' effect={'solid'} multiline={true}>
                                            <p> "CT", or Cycling Time, is the percentage of VRT where a pattern of ventilatory instability was exhibited.</p>
                                        </ReactTooltip></label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Between</span>
                                        <input type="number"
                                        style={{ backgroundColor: CTLowGray }}
                                            className="form-control"
                                            name='CTMildLow'
                                            onChange={handleChange}
                                            value={this.state.fields.CTMildLow.value}></input>
                                        <span className='input-group-text col'>and</span>
                                        <input type="number"
                                        style={{ backgroundColor: CTLowGray }}
                                            className="form-control"
                                            name='CTMildHigh'
                                            onChange={handleChange}
                                            value={this.state.fields.CTMildHigh.value}></input>
                                    </div>
                                </div>

                                <div className='col mb-2'>
                                    <label className='mt-2'>CT% Moderate alert threshold <i className={`far fa-exclamation-triangle`}
                                        data-tip="The low threshold value must not exceed a higher threshold value."
                                        style={{ color: 'red' }}
                                        hidden={this.state.CTModerateAlert}></i></label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Between</span>
                                        <input type="number"
                                        style={{ backgroundColor: CTModerateGray }}
                                            className="form-control"
                                            name='CTModerateLow'
                                            onChange={handleChange}
                                            value={this.state.fields.CTModerateLow.value}></input>
                                        <span className='input-group-text col'>and</span>
                                        <input type="number"
                                        style={{ backgroundColor: CTModerateGray }}
                                            className="form-control"
                                            name='CTModerateHigh'
                                            onChange={handleChange}
                                            value={this.state.fields.CTModerateHigh.value}></input>
                                    </div>
                                </div>

                                <div className='col mb-2'>
                                    <label className='mt-2'>CT% high alert threshold <i className={`far fa-exclamation-triangle`}
                                        data-tip="The low threshold value must not exceed a higher threshold value."
                                        style={{ color: 'red' }}
                                        hidden={this.state.CTSevereAlert}></i></label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Greater than</span>
                                        <input type="number"
                                        style={{ backgroundColor: CTSevereGray }}
                                            className="form-control"
                                            name='CTSevereLow'
                                            onChange={handleChange}
                                            value={this.state.fields.CTSevereLow.value}></input>
                                    </div>
                                </div>
                            </div>

                            {/* BD Thresholds */}
                            <div className='row border border-muted rounded'>
                                <div className='col mb-2'>
                                    <label className='mt-2'><i className={`fa fa-info-circle`}
                                        data-tip
                                        data-for='bdInfo'>
                                    </i> BL low alert threshold <i className={`far fa-exclamation-triangle`}
                                        data-tip="The low threshold value must not exceed a higher threshold value."
                                        style={{ color: 'red' }}
                                        hidden={this.state.BDMildAlert}></i>
                                        <ReactTooltip id='bdInfo' effect={'solid'} multiline={true}>
                                            <p>"BL", or Lowest Saturation Baseline, is the average of the lowest consecutive SpO2 values observed for 3
                                            <br />or more minutes of VRT. This BL value is independent of desaturations associated with respiratory events.</p>
                                            <p><b>References</b> for Default Alert Thresholds:</p>
                                            <p>1. Doug Elliott, Leanne Aitken, Wendy Chaboyer. ACCCN's Critical Care Nursing. Elsevier. 2007;382. </p>
                                            <p>2. International Classification of Sleep Disorders, Third Edition (ICSD-3) </p>
                                        </ReactTooltip></label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Between</span>

                                        <input type="number"
                                            className="form-control"
                                            name='BDMildHigh'
                                            onChange={handleChange}
                                            value={this.state.fields.BDMildHigh.value}>
                                        </input>

                                        <span className='input-group-text col' >and</span>
                                        <input type="number"
                                            className="form-control"
                                            name='BDMildLow'
                                            onChange={handleChange}
                                            value={this.state.fields.BDMildLow.value}></input>
                                    </div>
                                </div>

                                <div className='col mb-2'>
                                    <label className='mt-2'>BD high alert threshold <i className={`far fa-exclamation-triangle`}
                                        data-tip="The low threshold value must not exceed a higher threshold value."
                                        style={{ color: 'red' }}
                                        hidden={this.state.BDSevereAlert}></i></label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Less than</span>

                                        <input type="number"
                                            className="form-control"
                                            name='BDSevereHigh'
                                            onChange={handleChange}
                                            value={this.state.fields.BDSevereHigh.value}>
                                        </input>
                                    </div>
                                </div>

                            </div>
                            {/* Post Op Thresholds */}
                            <h5 className='mt-2'>Post Op Alert Categories</h5>
                            <div className='row border border-muted rounded'    >
                                <div className='col mb-4'>
                                    <label className='mt-2'><i className={`fa fa-info-circle`}
                                        data-tip
                                        data-for='postOpInfo' /> ODI4
                                        <ReactTooltip id='postOpInfo' place={'right'}>
                                            <p>"Post Op Risk", indicates if the patient is at risk of post operative adverse events.
                                            <br />"ODI4 (Events/hr)": mean number of {'>'}=4% desaturations per hour of VRT.
                                            <br />"CT{'<'}90 (%)": cumulative time SpO2 was below 90% as a percentage of VRT.
                                            <br />"Mean SpO2 %": mean oxygen saturation during VRT.</p>
                                            <p><b>References</b> for Default Alert Thresholds:</p>
                                            <p>1. Chung F, Zhou L, Liao P. Parameters from preoperative overnight oximetry predict postoperative adverse events. </p>
                                            <p>2. Minerva Anestesiol. 80:1084–1095 (2014). </p>
                                        </ReactTooltip>
                                    </label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Greater than</span>
                                        <input type="number"
                                            className="form-control"
                                            name='ODI4SevereLow'
                                            onChange={handleChange}
                                            value={this.state.fields.ODI4SevereLow.value}>
                                        </input>
                                    </div>
                                </div>
                                <div className='col mb-2'>
                                    <label className='mt-2'>CT90</label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Greater than</span>
                                        <input type="number"
                                            className="form-control"
                                            name='CT90SevereLow'
                                            onChange={handleChange}
                                            value={this.state.fields.CT90SevereLow.value}>
                                        </input>
                                    </div>
                                </div>
                                <div className='col mb-2'>
                                    <label className='mt-2'>Mean Sp02</label>
                                    <div className='input-group'>
                                        <span className='input-group-text col'>Greater than</span>
                                        <input type="number"
                                            className="form-control"
                                            name='MeanSPO2SevereHigh'
                                            onChange={handleChange}
                                            value={this.state.fields.MeanSPO2SevereHigh.value}>
                                        </input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReactTooltip effect={'solid'} multiline={true} />
                </div>
            </div >
        )
    }

}

export default ReportAlertSettings